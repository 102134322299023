import React from 'react';
import { useState } from 'react';
import { TbTriangleInvertedFilled } from 'react-icons/tb';
import { FaEquals } from 'react-icons/fa';
import { TbTriangleFilled } from 'react-icons/tb';

const Details = (item) => {

    const [list, setList] = useState([item])
    
    const renderImage = () => {
        if (item.item.percentage > 0) {
            return <TbTriangleFilled className="text-green-400" />;
        } else if (item.item.percentage < 0) {
            return <TbTriangleInvertedFilled className="text-red-400" />;
        } else {
            return <FaEquals className="text-blue-300" />;
        }
    }

    const renderColor = () => {
        if (item.item.percentage > 0) {
            return "bg-green-400 rounded px-2";
        } else if (item.item.percentage < 0) {
            return "bg-red-400 rounded px-2";
        } else {
            return "bg-blue-300 rounded px-2";
        }
    }

    return (
        <div className="py-1">
        <div className="p-2 flex flex-col gap-2 w-full text-center bg-gray-50 text-black border-1 border rounded-lg shadow-lg">
          <div className="flex justify-start items-center gap-10">
            {renderImage()}
            <span className="text-bold">{item.item.scripName}</span>
          </div>
          <div className="flex justify-start">
            <span className={renderColor()}>{item.item.percentage}</span>
          </div>
          <div className="flex justify-start">
            <span className=" bg-gray-50  rounded px-2 py-1">Started on... {item.item.inceptionDate} Allocation {item.item.allocation}</span>
          </div>
        </div>
      </div>
      )    
}

export default Details;