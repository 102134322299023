import List from "../components/Dashboard/List";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const email = useSelector((state) => state.user.email);
  const navigate = useNavigate();
  useEffect(() => {
    if (email === null || email === "") {
      navigate("/")
    }
  })
  return (
    <div>
      <List/>
    </div>
  )
}

export default Dashboard