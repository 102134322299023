import AutoComplete from "./AutoComplete";
import React from "react";
import axios, { all } from "axios";
import { useEffect, useState } from "react";
import { FaRegTrashAlt } from "react-icons/fa";
import { FiDelete, FiEdit } from "react-icons/fi";
import { IoIosCheckboxOutline } from "react-icons/io";
import { IoSearch } from "react-icons/io5";
import { RiCheckboxBlankLine, RiContactsBookLine } from "react-icons/ri";
import { TbTriangle } from "react-icons/tb";
import { TbTriangleInverted } from "react-icons/tb";
import { stockItems } from "./tableItems";
import { calculateAllocation } from "../../calculations";
import { FaMessage } from "react-icons/fa6";
import { useSelector } from "react-redux";
import config from "../../config";

const ShowStocks = ({ toggleStocksModal, updateRecipe, stocks, setStocks, currentBasketId, currentCompanyId, basketName }) => {

    const [inputs, setInputs] = useState({
        stockId: "",
        stockName: "",
        allocationPercent: 0,
        newAllocation: 0,
        comment: "",
        isFixed: true
    });

    const [editOn, setEditOn] = useState(false)
    const [disablePublish, setDisablePublish] = useState(false)
    const [allocationEditOn, setAllocationEditOn] = useState(false)
    const [index, setIndex] = useState(null)
    const [lists, setLists] = useState(stocks)
    const [totalAllocation, setTotalAllocation] = useState(0);
    const [addOnDisabled, setAddOnDisabled] = useState(false)
    const [updateOnDisabled, setUpdateOnDisabled] = useState(false)
    const [saveOnDisabled, setSaveOnDisabled] = useState(false)
    const [pageErroCount, setPageErrorCount] = useState(0);


    const [basketId, setBasketId] = useState(currentBasketId)
    //const [companyId, setCompanyId] = useState(currentCompanyId)
    const companyId = useSelector((state) => state.user.companyId);

    const [stocksList, setStocksList] = useState([]);
    //if (stocks.length>0) setStocksList(stocks)

    const [message, setMessage] = useState(null);

    const [errStock, setErrStock] = useState("")
    const [errquantity, setErrQuantity] = useState("")
    const [errremark, setErrRemark] = useState("")
    const [errisfixed, setErrIsfixed] = useState("")
    const [errWorkflow, setErrWorkflow] = useState("")
    useEffect(() => {
        setStocks(lists);
        updatePublishButton(lists);
    }, [lists, lists.data, stocks])
    const refLists = React.useRef(lists);
    const refBasketId = React.useRef(currentBasketId);
    const refCompanyId = React.useRef(currentCompanyId);
    
    // get Stocks list --
    useEffect(() => {
        const getStocksList = async (e) => {
            setMessage("")
            try {
                /**Stocks look up list */
                const res = await axios.get(config.BL_API_URL + "/stock/v1");
                if (res && res.data)
                    setStocksList(res.data)
                else
                    setMessage("backend message or while fetching stock lookup");

            } catch (message) {

                if (message.response)
                    setMessage(message.response.data);
                else
                    setMessage("Server error...");
            }
        }

        getStocksList();
        setTotalAllocation(parseFloat(calculateAllocation(lists, inputs)).toFixed(2));
        setActionButtonState();
        setSaveOnDisabled(true);
        //setUpdateOnDisabled(false);
    }, [])

    const setActionButtonState = () => {
        if (!editOn) {
            if (inputs.stockName.length < 0 || inputs.allocationPercent <= 0 || inputs.comment.length <= 0) {
                setAddOnDisabled(true);
            }
            else {
                setAddOnDisabled(false);
            }
        } else {
            setPageErrorCount(0);
            if (inputs.newAllocation == null || inputs.comment.length <= 0) {
                setUpdateOnDisabled(true);
            }
            else if (inputs.newAllocation > 0)
                setUpdateOnDisabled(false);
        }
    }

    const handleChange = (e) => {

        const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
        setInputs((prev) => ({ ...prev, [e.target.name]: value }));

        if (e.target.name == "allocationPercent" || e.target.name == "newAllocation") {

            setTotalAllocation(parseFloat(calculateAllocation(lists, inputs)).toFixed(2));
        }

        setActionButtonState();
    };

    function updatePublishButton(lists) {
        setErrWorkflow("");
        let unSavedBasketStock = false;
        let publishButton = document.getElementById("publishButton");
        if ((lists.data == null || lists.data.length <= 0) && lists == null) {
            setDisablePublish(true);
            return;
        }
        if ((lists == null || lists.length <= 0)) {
            setDisablePublish(true);
            return;
        }
        lists.forEach(element => {//if basket stock is not published
            if ((element.basketStockId == "" || element.basketStockId == null) && !saveOnDisabled) {
                setErrWorkflow("Basket needs to be saved first to publish the basket orders!")
                unSavedBasketStock = true;
                return;
            }
        });

        if (!unSavedBasketStock)
            setDisablePublish(false);
        else
            setDisablePublish(true);

    }

    const handleSave = async (e) => {
        e.preventDefault();
        setErrWorkflow("")
        if ((!editOn && !addOnDisabled)) {
            handleAdd(e);
        }
        if ((editOn && !updateOnDisabled)) {
            handleUpdate();
        }

        if (pageErroCount > 0) {
            return;
        }
        setStocks(stocks)
        //console.log('ingredients ', ingredients)
        //toggleStocksModal() //This is to close the modal
        updateRecipe()
        if (stocks != null && stocks.length > 0) {
            stocks.forEach(element => {
                if (element.isModified != null && element.isModified) {
                    element.isModified = false;
                }
            });
        }
        setDisablePublish(false);
        setSaveOnDisabled(true);
        setAddOnDisabled(true);
        setUpdateOnDisabled(true);
        setMessage("Saved successfully!")
    }

    const handleExportButton = async (e) => {
        const axiosConfig = {
            responseType: 'blob',
            headers: {
                Accept: 'Application/csv'
            }
        }
        const url = `${config.BL_API_URL}/basketstock/v1/getBasketAllocationDetailCSV?basketId=${encodeURIComponent(basketId)}`;

        axios.get(url, axiosConfig).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'basketStockAllocationDetails.csv');
            document.body.appendChild(link);
            link.click();
        }).catch((error) => {
            console.log("error", error)
        }
        );
    }

    const handlePublishButton = async (e) => {
        try {
            if (pageErroCount > 0) {
                return;
            }
            //const res = await axios.get(config.BL_API_URL+"/BasketRebalance/v1", { params: {basketId, companyId} });
            //console.log("BasketID " + currentBasketId);
            //console.log("CompanyID " + currentCompanyId);            
            let basketId = currentBasketId;
            let companyId;
            if (companyId == null) {
                companyId = currentCompanyId;
            }

            const res = await axios.get(config.BL_API_URL + "/BasketRebalance/v1", { params: { basketId, companyId } });
            if (res.status != 200) {
                setMessage("executed basket orders with with errors");
            }
            else
                setMessage("Basket published successfully");
        }
        catch (message) {

            if (message.response)
                setMessage(message.response.data);
            else
                setMessage("Server error while publishing basket");
        }

    }

    const handleAdd = async (e) => {
        e.preventDefault();

        setErrWorkflow("")
        setErrStock("")
        setErrQuantity("")
        setErrRemark("")
        setErrIsfixed("")
        var errCount = 0;

        if (inputs.stockName.length < 2 || inputs.stockName.length > 40) {
            setErrStock("* please select stock name from the list! ")
            errCount++;
        }

        if (inputs.comment.length < 10 || inputs.comment.length > 400) {
            setErrRemark("* remarks must be 10 to 400 in length! ")
            errCount++;
        }

        if (!editOn) {
            if (inputs.allocationPercent === null || inputs.allocationPercent < 0 || inputs.allocationPercent > 100) {
                setErrQuantity("* minimum allocation percent 0.1 and maximum 100 across all stocks")
                errCount++;
            }
        }

        if (editOn) {
            if (inputs.newAllocation === null || inputs.newAllocation < 0 || inputs.newAllocation > 100) {
                setErrQuantity("* minimum allocation percent 0.1 and maximum 100 across all stocks")
                errCount++;
            }
        }

        //check if stock already presesent in list

        setPageErrorCount(errCount);

        if (errCount === 0) {
            if (totalAllocation > 100) {
                setErrRemark("* total allocation can't exceed 100%");
                return;
            }

            var newObj;
            inputs.isModified = true;
            if (lists.data != null) {
                newObj = [...lists.data, inputs];
            }
            else
                newObj = [...lists, inputs]

            setLists(newObj)
            setStocks(newObj)
            setInputs({
                basketStockId: "",
                stockId: "",
                stockName: "",
                allocationPercent: 0,
                newAllocation: 0,
                comment: "",
                isFixed: false,
                isModified: false
            })
            setSaveOnDisabled(false);
            setAddOnDisabled(true);
            if (document.getElementById("searchStockName") != null) {
                document.getElementById("searchStockName").focus()
            }

        }
    };

    // move list up
    const moveUp = (index) => {
        var newlist = lists
        var tmpObj = lists[index - 1]
        newlist[index - 1] = lists[index]
        newlist[index] = tmpObj
        setLists([...newlist])
        setStocks([...newlist])
        console.log(newlist)
    }

    const handleClose = () => {
        //handleSave();
        if (!saveOnDisabled) {
            if (window.confirm("Are you sure you want to close without saving the changes?")) {
                toggleStocksModal();
            }
        }
        else {
            toggleStocksModal();
        }

    }

    // move list down
    const moveDown = (index) => {
        var newlist = lists
        var tmpObj = lists[index + 1]
        newlist[index + 1] = lists[index]
        newlist[index] = tmpObj
        setLists([...newlist])
        setStocks([...newlist])
        console.log(newlist)
    }

    const editMode = (index) => {
        /*         if(updateOnDisabled == true || addOnDisabled == false){
                    setErrWorkflow("Please save or update the changes before editing another stock!");
                    return;
                } */
        setIndex(index)
        setEditOn(true)
        /* inputs.allocationPercent = 0;
        inputs.newAllocation = 0; */
        setTotalAllocation(parseFloat(calculateAllocation(lists, inputs)).toFixed(2));
        isNewStock(lists[index].stockName)

        //console.log(lists[index])
        setInputs({
            basketStockId: lists[index].basketStockId,
            stockId: lists[index].stockId,
            stockName: lists[index].stockName,
            allocationPercent: lists[index].allocationPercent,
            newAllocation: lists[index].allocationPercent,
            comment: lists[index].comment,
            isFixed: lists[index].isFixed
        });
        if (lists[index].isModified) {

            setInputs({
                basketStockId: lists[index].basketStockId,
                stockId: lists[index].stockId,
                stockName: lists[index].stockName,
                allocationPercent: lists[index].allocationPercent,
                newAllocation: lists[index].newAllocation,
                comment: lists[index].comment,
                isFixed: lists[index].isFixed
            });
        }

    }
    //lists[index].newAllocation == null  ? lists[index].allocationPercent : lists[index].newAllocation
    //handleAllocation(lists[index].newAllocation, lists[index].allocationPercent, lists[index].stockName)
    const handleAllocation = (newAllocation, allocation, stockName) => {
        if (newAllocation != null)
            return newAllocation;

        lists.forEach(element => {
            if (element.stockName === stockName) {
                return "";
            }
        });
        //return allocation;
    }

    function isNewStock(stockName) {
        refLists.current.forEach(element => {
            if (element.stockName === stockName && element.basketStockId != null) {
                setAllocationEditOn(true);
                return;
            }
        });
    }




    // update stocks after edit
    const handleUpdate = () => {
        setErrStock("")
        setErrQuantity("")
        setErrRemark("")
        setErrIsfixed("")
        var errCount = 0;

        if (inputs.stockName.length < 3 || inputs.stockName.length > 40) {
            setErrStock("* stockName must be 3 to 40 in length! ")
            errCount++;
        }

        if (inputs.comment != null && (inputs.comment.length < 10 || inputs.comment.length > 400)) {
            setErrRemark("* remarks must be 10 to 400 in length! ")
            errCount++;
        }

        if (inputs.allocationPercent === null || inputs.allocationPercent < 0 || inputs.allocationPercent > 100) {
            setErrQuantity("* minimum allocation percent 0.1 and maximum 100 across all stocks")
            errCount++;
        }

        if (inputs.newAllocation === null || inputs.newAllocation < 0 || inputs.newAllocation > 100) {
            setErrQuantity("* minimum allocation percent 0.1 and maximum 100 across all stocks")
            errCount++;
        }

        setPageErrorCount(errCount);

        if (errCount === 0) {
            if (totalAllocation > 100) {
                setErrRemark("* total allocation can't exceed 100%");
                return;
            }
            var newObj;
            if (lists.data == null)
                newObj = [...lists]
            else
                newObj = [...lists.data]

            inputs.isModified = true;
            newObj[index] = inputs
            setLists([...newObj])
            //console.log(newObj)
            setStocks([...newObj])
            setEditOn(false)
            setAllocationEditOn(false)
            setInputs({
                stockId: "",
                stockName: "",
                allocationPercent: "",
                newAllocation: "",
                comment: "",
                isFixed: false,
                isVisible: true,
                isModified: false
            });
            setIndex(null)
            setDisablePublish(true);
            setSaveOnDisabled(false);
        }

    }

    const handleCancelAddOrUpdate = () => {
        setEditOn(false)
        setInputs({
            stockId: "",
            stockName: "",
            allocationPercent: "",
            newAllocation: "",
            comment: "",
            isFixed: false,
            isVisible: true,
            isModified: false
        });
        setIndex(null)
        setDisablePublish(true);
        setSaveOnDisabled(true);
        setAddOnDisabled(true)
    }

    return (

        <div id="authentication-modal" tabIndex="-1" className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full shadow-2xl ">
            <div className="relative p-1 h-[100%] max-w-[1200px]  mx-auto my-auto ">

                <div className="border mx-auto relative mt-16 bg-formbackground rounded-lg shadow-2xl ">

                    <div className=" flex items-center justify-between p-1 md:p-1 border-b rounded-t ">
                        <h3 className="text-l font-semibold text-gray-900 ">
                            Rebalance Basket {basketName} Stocks
                        </h3>
                        {/*  {editOn &&
                            <h3 className="text-xl font-semibold text-gray-900 ">
                                Edit Stock
                            </h3>
                        }
                        {!editOn &&
                            <h3 className="text-xl font-semibold text-gray-900 ">
                               < Add >Stock
                            </h3>                
                        }
                        <br></br> */}
                        {allocationEditOn &&
                            <h3 className="text-l font-semibold text-gray-900 ">
                            </h3>
                        }
                        {!allocationEditOn &&
                            <h3 className="text-l font-semibold text-gray-900 ">
                            </h3>
                        }

                        <button onClick={e => handleClose()} type="button" className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center " data-modal-hide="authentication-modal">
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    <div className="p-1 md:p-1">
                        <div className="space-y-1">
                            <div className="flex space-x-4">
                                <div className="width-1/2">
                                    <label htmlFor="af-account-full-name" className="inline-block text-sm text-gray-800 mt-2.5 ">
                                        <span className={`${errStock ? "text-red-500 " : ""}`}>Stock </span>
                                    </label>
                                </div>
                                <div className="width-2">
                                    <AutoComplete
                                        setInputs={setInputs}
                                        data={stocksList}
                                        inputs={inputs}
                                        editOn={editOn}
                                    />
                                </div>
                                <div className="width-1/2">
                                    <label htmlFor="af-account-full-name" className="inline-block text-sm text-gray-800 mt-1 ">
                                        <span className={`${errquantity ? "text-red-500 " : ""}`}>Allocation%</span>
                                    </label>
                                </div>
                                <div className="width-1/2">
                                    {!editOn &&
                                        <input
                                            onKeyUp={handleChange}
                                            onChange={handleChange} step="0.1" min={0.1} max={100}
                                            onBlur={(e) => {

                                                e.target.value = parseFloat(e.target.value).toFixed(2);
                                            }}
                                            type="number" name="allocationPercent" id="allocationPercent" placeholder="" value={inputs.allocationPercent}
                                            className="py-2 px-3 pe-2 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " />
                                    }
                                    {editOn &&
                                        <input
                                            onKeyUp={handleChange}
                                            onChange={handleChange} min={0.1} max={100}
                                            onBlur={(e) => {

                                                e.target.value = parseFloat(e.target.value).toFixed(2);
                                            }}
                                            type="number" name="newAllocation" id="newAllocation" placeholder="" value={inputs.newAllocation}
                                            className="py-2 px-3 pe-2 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " />

                                    }
                                </div>
                                <div className="flex-1/2">
                                    <label htmlFor="af-account-full-name" className="inline-block text-sm text-gray-800 mt-1 ">
                                        <span className={`${errremark ? "text-red-500 " : ""}`}>Remark </span>
                                    </label>
                                </div>
                                <div className="width-2">
                                    <textarea
                                        onChange={handleChange} maxLength={100}
                                        name="comment" id="comment" placeholder="" rows="1" value={inputs.comment}
                                        className="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " ></textarea>
                                </div>
                                <div className="width-1/2">
                                    <label htmlFor="af-account-full-name" className="inline-block text-sm text-gray-800 mt-1 ">
                                        <span className={`${errisfixed ? "text-red-500 " : ""}`}>No Selling</span>
                                    </label>
                                </div>
                                <div className="width-1/2">
                                    <input
                                        onChange={handleChange}
                                        name="isFixed" id="isFixed" type="checkbox" checked={inputs.isFixed} defaultValue={false}
                                        className="mt-1 p-2 w-6 h-6 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 " />
                                </div>
                                <div className="width-1/2">
                                    {editOn &&
                                        <button disabled={updateOnDisabled} onClick={handleUpdate} className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3.5 py-2.5 text-center disabled:opacity-20">Update</button>
                                    }

                                    {!editOn &&
                                        <button disabled={addOnDisabled} onClick={handleAdd} className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3.5 py-2.5 text-center disabled:opacity-15 ">Add</button>
                                    }
                                </div>
                                <div className="width-1/2">
                                    <button onClick={handleCancelAddOrUpdate} className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3.5 py-2.5 text-center disabled:opacity-20">Cancel</button>

                                </div>
                                <div className="width-1/2">
                                    <button disabled={saveOnDisabled} onClick={handleSave} className=" text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3.5 py-2.5 text-center disabled:opacity-20 ">Save</button>
                                </div>
                                <div className="width-1/2">
                                    <button id="publishButton" disabled={disablePublish} onClick={handlePublishButton} className=" text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3.5 py-2.5 text-center disabled:opacity-20 ">Publish</button>
                                </div>
                                <div className="width-1/2">
                                    <button id="Export" onClick={handleExportButton} className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3.5 py-2.5 text-center disabled:opacity-20 ">Export</button>
                                </div>
                                {/* Add more columns here */}
                                {/* Add more columns here */}
                            </div>



                            <div className="overflow-hidden">
                                <div className="flex flex-col">
                                    <div className="-m-1.5 overflow-x-auto">
                                        <div className="p-1.5 min-w-full inline-block align-middle">
                                            {/* <div className="p-1.5 min-w-full inline-block align-right"> */}
                                            {/* <div className="flex space-x-0 mb-1 "> */}
                                            {/* <div className="flex-1 w-2 text-nowrap">
                                                    <label>Total Allocation: </label><label className={totalAllocation > 100 ? 'bg-red-500' : 'bg-yellow-500'} id="totalAllocation">{totalAllocation}<label > %</label></label>
                                                </div> */}
                                            {/* <div className="flex-2 justify-end">
                                                    <div className="flex space-x-0 ">
                                                        <div className="flex space-x-0 ">
                                                            <div className="flex-1  px-2">

                                                                <button id="Export" onClick={handleExportButton} className=" text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-2.5 text-center disabled:opacity-20 h-8">Export</button>
                                                            </div>
                                                            <div className="flex-1  px-2">

                                                                <button id="publishButton" disabled={disablePublish} onClick={handlePublishButton} className=" text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-2.5 text-center disabled:opacity-20 h-8">Publish</button>
                                                            </div>

                                                            <div className="flex-1 ">
                                                                <button disabled={saveOnDisabled} onClick={handleSave} className=" text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-2.5 text-center disabled:opacity-20 h-8 mr-28">Save</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            {/* </div> */}
                                            <div className="border rounded-lg divide-y divide-gray-200 ">
                                                <table className="min-w-full divide-y divide-gray-300 max-h-[500px] w-full flex flex-col items-center justify-between overflow-y-scroll table-fixed">
                                                    <thead className="bg-gray-200 w-full" >
                                                        {/* <tr className="flex justify-between w-full">
                                                            {stockItems &&
                                                                stockItems.slice(0, 5).map((item, index) => (
                                                                    <th key={index} scope="col" className="px-6 py-2 text-start text-xs font-medium text-gray-500 uppercase">{item.name}</th>
                                                                ))}
                                                        </tr> */}
                                                        <tr className="justify-start w-full" style={{ display: 'table', tableLayout: 'fixed' }}>
                                                            <th scope="col" className="px-6 py-1 text-start text-xs font-medium text-gray-500 uppercase w-[5px]">#</th>
                                                            <th scope="col" className="px-6 py-1 text-start text-xs font-medium   text-gray-500 uppercase w-[350px]">Scrip Name</th>
                                                            <th scope="col" className="px-6 py-1 text-start text-xs font-medium text-gray-500 uppercase w-[150px]">Allocation%</th>
                                                            <th scope="col" className="px-6 py-1 text-start text-xs font-medium text-gray-500 uppercase whitespace-nowrap w-[150px]">New Allocation%</th>
                                                            <th scope="col" className="px-6 py-1 text-start text-xs font-medium text-gray-500 uppercase w-[150px]">Remark</th>
                                                            <th scope="col" className="px-6 py-1 text-end text-xs font-medium whitespace-nowrap text-gray-500 uppercase w-[0px]">No Selling</th>
                                                            <th scope="col" className="px-6 py-1 text-end text-xs font-medium text-gray-500 uppercase ">Action</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody className="divide-y divide-gray-200 w-full overflow-y-scroll">
                                                        {lists && lists.length > 0 &&
                                                            lists.map((list, rowNumber) => (
                                                                <tr key={list.stockName} className={`hover:bg-gray-400 flex gap-0 justify-start w-full 
                                                                    ${index === rowNumber ? 'bg-blue-200' : 'hover:bg-gray-400'} `}>
                                                                    <td className="px-6 py-0 text-start whitespace-wrap text-xs overflow-hidden  text-gray-800 w-[5px]">{rowNumber + 1}</td>
                                                                    <td className="px-0 py-0 text-start whitespace-nowrap text-xs  text-gray-800  overflow-hidden w-[350px] uppercase">{list.stockName}</td>
                                                                    <td className="px-6 py-0 text-start whitespace-wrap text-xs overflow-hidden  text-gray-800 w-[150px]" data-id={list.stockName} id={list.stockName} >{list.allocationPercent}</td>
                                                                    <td className="px-6 py-0 text-start whitespace-nowrap text-xs  text-gray-800 w-[150px]">{list.newAllocation}</td>
                                                                    <td className="px-6 py-0 text-start whitespace-nowrap text-xs  text-gray-800 w-[150px]">{list.comment}</td>
                                                                    <td className="px-6 py-0 text-start whitespace-nowrap text-xs text-gray-800 w-[230px]">
                                                                        {list.isFixed ? <IoIosCheckboxOutline /> : <RiCheckboxBlankLine />}
                                                                        {/*<input

                                                                                name="isfixed11" id="isfixed11" type="checkbox" defaultChecked={lists.isFixed ? true: false}
                                                                                className="p-2 w-4 h-4 border border-gray-300 rounded bg-gray-50" />
                                                                */}
                                                                    </td>
                                                                    <td onClick={e => editMode(rowNumber)} className="px-6 py-0 whitespace-nowrap text-lg  text-btnPrimary text-start cursor-pointer w-[50px]" title={"Edit " + list.stockName + " Allocation"}><FiEdit />
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                </table>

                                            </div>
                                            <div className="flex space-x-0 mb-1 ">
                                                <div className="flex-1 w-2 text-nowrap ">
                                                    <p className="text-red-400 mt-2">
                                                        {errWorkflow}
                                                        {errStock}
                                                        {errquantity}
                                                        {errremark}
                                                        {message}
                                                    </p>

                                                </div>
                                                <div className="flex-1 w-2 text-nowrap ">
                                                    <label className="mt-2">Total : </label><label className={totalAllocation > 100 ? 'bg-red-500' : 'bg-yellow-500'} id="totalAllocation">{totalAllocation}<label > %</label></label>
                                                </div>
                                                <div className="flex-1 w-2 text-nowrap "></div>
                                            </div>
                                            {/* error show */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShowStocks