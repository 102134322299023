import React from 'react';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { FcCancel } from "react-icons/fc";
import { FiEdit } from "react-icons/fi";
import { VscSave } from "react-icons/vsc";
import { FcCancel as FcCancel1 } from "react-icons/fc";
import config from '../../config';
/* import {Random, MersenneTwister19937} from 'random-js'; */

const TransactionEdit = () => {
    /* const random = new Random(MersenneTwister19937.autoSeed()); */
    function getRandomNumber() {
        return Math.round(Math.random() * 1000000000);
    }

    const companyId = useSelector((state) => state.user.companyId);

    const [transactionList, setTransactionList] = useState([])
    const [err, setErr] = useState("")
    const [editOn, setEditOn] = useState(false)
    const [orderNumber, setOrderNumber] = useState()
    const [tradePrice, setTradePrice] = useState()
    const [transactionCost, setTransactionCost] = useState()
    const [uniqueOrderNo, setUniqueOrderNo] = useState()
    const [orderStatus, setOrderStatus] = useState()
    const [errTradePrice, setErrTradePrice] = useState("")
    const [errTransactionCost, setErrTransactionCost] = useState("")
    const [errUniqueOrderNo, setErrUniqueOrderNo] = useState("")
    const [errOrderStatus, setErrOrderStatus] = useState("")
    const [errOrderNumber, setErrOrderNumber] = useState("")
    

    useEffect(() => {

        getOpenTransactions();
    }, [])

    const handleEditOn = (id) => {
        setEditOn(true)
        setErr("")
    }
    const handleEditCancel = (id) => {
        setEditOn(false)
        setErr("")
        setErrTradePrice("");
        setErrOrderNumber("");
        setErrTransactionCost("");
        setErrUniqueOrderNo("");
        setErrOrderStatus("");

        setTransactionCost("");
        setTradePrice("");
        setUniqueOrderNo("");
        setOrderStatus("");
        setOrderNumber("");
        //setPrice(list.prevClosePrice)
    }    
    const handleEditSave = async (transaction) => {
        var errCount = 0;

        if (tradePrice == null || tradePrice == '' || parseFloat(tradePrice) < 1) {
            setErrTradePrice("* set trade price")
            errCount++;
        }
        if (transactionCost == null || transactionCost == '' || parseFloat(transactionCost) < 1) {
            setErrTradePrice("* set transaction cost")
            errCount++;
        }
        if (uniqueOrderNo == null || uniqueOrderNo == '' || parseFloat(uniqueOrderNo) < 1) {
            setErrTradePrice("* set unique order no")
            errCount++;
        }
        if (orderStatus == null || orderStatus == '') {
            setErrTradePrice("* set order status")
            errCount++;
        }
        if (errCount === 0) {

            try {
                transaction.tradePrice = tradePrice;
                transaction.transactionCost = transactionCost;
                transaction.uniqueOrderId = uniqueOrderNo;
                transaction.orderStatus = orderStatus;                
                const res = await axios.post(config.BL_API_URL+"/testTransaction/v1/updateTransaction",
                    transaction);
                
                if (res.status ===200 || (res.data != null && res.data.status === "Success")) {
                    setEditOn(false);
                    setErrTradePrice("");
                    getOpenTransactions();
                }
                else
                    setErr("backend error")

            } catch (err) {
                console.log("error 333 ", err)
                setErr("Server error...");
                // if (err?.response?.data) setErr(err.response.data);
            }
        }
    }

    const getOpenTransactions = async (e) => {
        try {
            const res = await axios.get(config.BL_API_URL+"/testTransaction/v1/getOpenTransactions");

            if (res.status === 200 && res.data)
                setTransactionList(res.data) 
            else
                setErr("backend error")

        } catch (err) {
            //console.log("first")
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }
    }

    const onChange = (e) => {
        setOrderStatus(e.target.value)
        setUniqueOrderNo(transactionList[e.target.id].uniqueOrderNo)
        setTradePrice(transactionList[e.target.id].tradePrice)
        setTransactionCost(transactionList[e.target.id].transactionCost)
    }

    return (
        <div className=" my-24 md:px-14 max-w-screen mx-auto ">
            <div className="py-1 px-1 flex justify-center items-center">
            <span className="mb-6 ml-4 text-2xl ">Trade Confirmation Transactions Edit (For test purpose only!)</span>
            </div>
            
            {/* <div className=" w-full ">
            <div className="z-0 relative  w-full  flex justify-center items-start  ">
                <div className="b order  relative  w-full ">

                    <div className="p-2 md:p-5 w-full">
                        <div className=" w-full">
                            <div className="grid sm:grid-cols-12 gap-0 sm:gap-0 w-full"> */}

                                {/* List of Recipe  ---- */}
                                <div className="sm:col-span-15">

                                    {/* <div className="relative overflow-x-auto shadow-md "> */}
                                        <table className="min-w-full divide-y divide-gray-400 w-full flex flex-col items-center justify-between">
                                            <thead className="bg-tblPrimary text-white w-full">
                                                <tr className="flex w-full">
                                                <th  className="px-1 py-1 w-16 text-start font-small uppercase border-r" key="id" scope="col">Id</th>
                                                <th  className="px-1 py-1 w-48 text-start font-small uppercase border-r"  key="batchId" scope="col">Batch Id</th>
                                                <th  className="px-1 py-1 w-24  text-start font-small uppercase border-r " key="clientCode" scope="col">Client Code</th>
                                                <th  className="px-1 py-1 w-16 text-start font-small uppercase border-r " key="orderType" scope="col">Order Type</th>
                                                <th  className="px-1 py-1 w-28  text-start font-small uppercase border-r " key="tradeDate" scope="col">Trade Date</th>
                                                <th  className="px-1 py-1 w-36  text-start font-small uppercase border-r " key="scripName" scope="col">Scrip Name</th>
                                                <th  className="px-1 py-1 w-28 text-start font-small uppercase border-r " key="quantity" scope="col">Quantity</th>
                                                <th  className="px-1 py-1 w-24  text-start font-small uppercase border-r " key="rate" scope="col">Rate</th>
                                                <th  className="px-1 py-1 w-24  text-start font-small uppercase border-r " key="netRate" scope="col">Net Rate</th>
                                                <th  className="px-1 py-1 w-28  text-start font-small uppercase border-r "key="orderNumber" scope="col">Order Number</th>
                                                <th  className="px-1 py-1 w-28  text-start font-small uppercase border-r " key="tradePrice" scope="col">Trade Price</th>                                                
                                                <th  className="px-1 py-1 w-28  text-start font-small uppercase border-r " key="transactionCost" scope="col">Tran. Cost</th>
                                                <th  className="px-1 py-1 w-24  text-start font-small uppercase border-r " key="uniqueOrderId" scope="col">Unique Order Id</th>
                                                <th  className="px-1 py-1 w-16  text-start font-small uppercase border-r " key="orderStatus" scope="col">Order Status</th>
                                                <th  className="px-1 py-1 w-48  text-start font-small uppercase border-r " key="orderStatus" scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200 w-full">
                                            {transactionList &&
                                                transactionList.map((transaction, index) => (
                                                <tr key={index} className={`flex items-center w-full
                                                ${ index % 2 === 0 ? '' : 'bg-blue-100' }                                                
                                            `}>
                                                    <td  scope="row" className="px-1 w-16 font-small uppercase border-r whitespace-nowrap  text-gray-800  ">{transaction.id}</td>
                                                    <td  scope="row" className="px-1 w-48 text-justify  font-small  uppercase border-r whitespace-nowrap  text-gray-800  " >{transaction.batchId}</td>
                                                    <td  scope="row" className="px-1 w-24  font-small uppercase border-r whitespace-nowrap  text-gray-800  ">{transaction.clientCode}</td>
                                                    <td  scope="row" className="px-1 w-16 py-1 font-small uppercase border-r whitespace-nowrap  text-gray-800  ">{transaction.orderType}</td>
                                                    <td  scope="row" className="px-1 w-28  font-small uppercase border-r whitespace-nowrap  text-gray-800  ">{transaction.tradeDate}</td>
                                                    <td  scope="row" className="px-1 w-36 font-small uppercase border-r whitespace-wrap  text-gray-800  ">{transaction.scriptName}</td>
                                                    <td  scope="row" className="px-1 w-28  font-small uppercase border-r whitespace-nowrap  text-gray-800  ">{transaction.quantity}</td>
                                                    <td  scope="row" className="px-1 w-24  font-small uppercase border-r whitespace-nowrap  text-gray-800  ">{transaction.rate}</td>
                                                    <td  scope="row" className="px-1 w-24  font-small uppercase border-r whitespace-nowrap  text-gray-800  ">{transaction.netRate}</td>
                                                    <td  scope="row" className="px-1 w-28  font-small uppercase border-r whitespace-nowrap  text-gray-800  ">
                                                    {!editOn && transaction.orderNumber }
                                                    {editOn &&
                                                    <input
                                                        onChange={e => setOrderNumber(parseInt(e.target.value))} value={transaction.orderNumber}
                                                        type="number" name="orderNumber" id="orderNumber" placeholder=""
                                                        className="border-1 w-24 border-gray-400  " />
                                                    }
                                                    </td>
                                                    <td  scope="row" className="px-1 w-28  font-small uppercase border-r whitespace-nowrap  text-gray-800  ">
                                                    {!editOn && transaction.tradePrice }
                                                    {editOn &&
                                                    <input
                                                        onChange={e => setTradePrice(parseInt(e.target.value))} value={transaction.tradePrice}
                                                        type="number" name="tradePrice" id="tradePrice" placeholder=""
                                                        className="border-1 w-24 border-gray-400  " />
                                                    }
                                                    </td>
                                                    <td  scope="row" className="px-1 w-28 font-small uppercase border-r whitespace-nowrap  text-gray-800  ">    
                                                    {!editOn && transaction.transactionCost }
                                                    {editOn &&
                                                    <input
                                                        onChange={e => setTransactionCost(parseInt(e.target.value))} value={transaction.transactionCost}
                                                        type="number" name="transactionCost" id="transactionCost" placeholder=""
                                                        className="border-1 w-24 border-gray-400  " />
                                                    }
                                                    </td>
                                                    <td id={transaction.uniqueOrderNo = getRandomNumber()}  scope="row" className="px-1 w-24  font-small uppercase border-r whitespace-nowrap  text-gray-800  ">
                                                        {transaction.uniqueOrderNo}
                                                        
                                                        
                                                    </td>
                                                    <td scope="row" className="px-1 w-16 font-small uppercase border-r whitespace-nowrap  text-gray-800  ">
                                                        {!editOn && transaction.orderStatus}
                                                        {editOn &&  
                                                        <input data={index} key={index}
                                                            onChange={e => onChange(e)} value={transaction.orderStatus}
                                                            type="text" name="orderStatus" id={index} placeholder=""
                                                            className="border-1 w-16 border-gray-400  " />
                                                        }
                                                    </td>
                                                    <td  scope="row" className="px-1 w-48 font-small uppercase border-r whitespace-nowrap  text-gray-800  ">
                                                    {!editOn &&
                                                        <span onClick={e => handleEditOn(transaction.id)}  type="button" className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-400 hover:text-blue-600 cursor-pointer  ">
                                                            <FiEdit className="text-base" />Edit
                                                        </span>
                                                    }
                                                    {editOn &&
                                                        <span onClick={e => handleEditSave(transaction)} type="button" className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-400 hover:text-blue-600 cursor-pointer  ">
                                                            <VscSave className="text-blue-600 text-base" />Save
                                                        </span>
                                                    }

                                                    {editOn &&
                                                        <span onClick={e => handleEditCancel(transaction.id)} type="button" className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-red-400 hover:text-red-600 cursor-pointer  ">
                                                            <FcCancel className="ml-2 text-red-600 text-base" />Cancel
                                                        </span>
                                                    }
                                                    <br />
                                                    <span className="text-red-400">{err} {errTradePrice} {errTransactionCost} {errTransactionCost} {errOrderStatus} {errUniqueOrderNo}  </span>
                                                    </td>
                                                </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            /* </div> */
                       /*  </div>
                    </div>
                </div>
            </div>
        </div>
        </div> */
    );
}

export default TransactionEdit;