import React from 'react';
import axios from "axios";
import { useEffect, useState } from "react";
import config from "../../config";
import { BsFiletypeCsv } from "react-icons/bs";
import { useSelector } from "react-redux";
import { set } from 'date-fns';
import { Link } from 'react-router-dom';
import { TbTableExport } from "react-icons/tb";
const AllocationHistoryReport = () => {

    const companyId = useSelector((state) => state.user.companyId);
    const [baskets, setBaskets] = useState([])
    const [list, setList] = useState([])
    const [err, setErr] = useState("")

    useEffect(() => {
        const getBaskets = async (e) => {
            try {
                const res = await axios.get(config.BL_API_URL + "/basket/v1");

                if (res.data)
                    setBaskets(res.data)
                else
                    setErr("backend error")

            } catch (err) {
                //console.log("first")
                if (err.response)
                    setErr(err.response.data);
                else
                    setErr("Server error...");
            }
        }
        getBaskets();
    }, [])

    const onSelectionChange = async (e) => {

        if (e.target.value === "-") {
            return;
        }
        try {
            let basketId = Number(e.target.value);
            const res = await axios.get(config.BL_API_URL + "/basketstock/v1/getBasketAllocationHistory", { params: { basketId } });

            if (res.data){
                
                setList(res.data)
            }
            else
                setErr("backend error")

        } catch (err) {
            //console.log("first")
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }
        //setSelectedBatchId(e.target.value);
    }
    function formatDate(value) {
        if (value == null || value === undefined || value === "") {
            return;
        }
        return new Intl.DateTimeFormat('en-IN').format(value);
    }

    return (
        <div>
            <div className="my-14 md:px-14 p-2 max-w-screen-lg mx-auto ">
                <div className="flex flex-col">
                    <div className="-m-1.5 overflow-x-auto">

                        <div className="border rounded-lg divide-y divide-gray-200 ">
                            <div className='col-span-2'>
                                <div className=" col-span-1">
                                    <div className="py-1 px-4 flex justify-center items-center">
                                        <span className="text-gray-500 text-2xl font-bold">Allocation History</span>
                                    </div>
                                    <div className='row-span-1 flex justify-center content-center items-center gap-x-2 py-2 px-4'>
                                        <div className='col-span-1'>
                                            <label className='px-6 py-2 text-left font-medium' >Basket Name</label>
                                        </div>
                                        <div className='col-span-1'>
                                            <select
                                                onChange={onSelectionChange}
                                                name="basketId" id="basketId"
                                                className="py-0 px-0 pe-0 block w-full focus:outline-none">
                                                <option value="">-select-</option>
                                                {baskets && (
                                                    baskets.map((basket, index) => <option key={index} value={basket.id}>{basket.name}</option>)
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="overflow-hidden">
                                <div className="overflow-hidden">
                                    <table className="min-w-full divide-y divide-gray-200  ">
                                        <thead className="bg-gray-50 ">
                                            <tr>
                                                <th scope="col" className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase">Scrip Name</th>
                                                <th scope="col" className="px-6 py-2 text-start text-xs font-medium text-gray-500 uppercase">Allocation</th>
                                                <th scope="col" className="px-6 py-2 text-xs text-left font-medium text-gray-500 uppercase">Delta</th>
                                                <th scope="col" className="px-6 py-2 text-start text-xs font-medium text-gray-500 uppercase">Rate</th>
                                                <th scope="col" className="px-6 py-2 text-start text-xs font-medium text-gray-500 uppercase">Date (YYY-MM-DD)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list &&
                                                list.map((item, index) => (
                                                    <tr>
                                                        <td className="px-6  whitespace-nowrap text-sm  text-gray-800 ">{item.scripName}</td>
                                                        <td className="px-6  whitespace-nowrap text-sm  text-gray-800 ">{item.currentAllocation}</td>
                                                        <td className="px-6  whitespace-nowrap text-sm  text-gray-800 ">{item.allocationDelta}</td>
                                                        <td className="px-6  whitespace-nowrap text-sm  text-gray-800 ">{item.publishedRate}</td>
                                                        <td className="px-6  whitespace-nowrap text-sm  text-gray-800 ">{(item.publishedDate)}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );

}

export default AllocationHistoryReport;