import MyProfile from "../components/MyProfile";

const Profile = () => {
  return (
    <div className="my-24 md:px-14 p-4 max-w-screen-2xl mx-auto ">
      <MyProfile />
    </div>
  )
}

export default Profile