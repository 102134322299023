import PriceEdit from "./PriceEdit";
import axios from "axios";
import { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { VscSave } from "react-icons/vsc";
import { useSelector } from "react-redux";
import { stockHeaders } from "./tableItems";
import config from "../../config";
import loadingImg from "../../assets/loading.gif"

const StockEdit = () => {

    const email1 = useSelector((state) => state.user.email);
    const [stocks, setStocks] = useState([])
    const [err, setErr] = useState("")
    const [selectedId, setSelectedId] = useState(null)
    const [editOn, seteditOn] = useState(false)
    const [index, setindex] = useState(null)
    const currentDate = new Date();
    const [date, setDate] = useState();
    const [refresh, setRefresh] = useState(false)
    const [isloading, setIsloading] = useState(false)
    const [currentPage, setCurrentPage] = useState(0)
    const [rows, setRows] = useState(5)
    const [totalPages, setTotalPages] = useState(0)

    const [inputs, setInputs] = useState({
        email: email1,
    })
    // get stocks list --
    useEffect(() => {
        getStocks()
    }, [rows])

   

    // get stocks list function
    const getStocks = async (e) => {

        try {
            const res = await axios.get(config.BL_API_URL + "/stock/v1/stockforPriceUpdate")


            if (res.data){

                setStocks(res.data)
                const totalPages = Math.ceil(res.data.length / rows)
                setCurrentPage(1)
                setTotalPages(totalPages)
                console.log(rows, currentPage, totalPages, stocks.length)
            }
            else
                setErr("backend error")

        } catch (err) {
            setErr("Server error...");
            if (err?.response?.data) setErr(err.response.data);
        }
    }

    const refreshBasketStockPrices = async (e) => {

        try {
            setRefresh(true);
            setIsloading(true)
            setErr("");
            setDate()
            const res = await axios.get(config.BL_API_URL + "/basketstock/v1/refreshBasketStockPrices")
            setIsloading(false);
            setDate(currentDate.toLocaleString());
            getStocks()
            if (res.status === 200 && res?.data?.messages?.length <= 1) {
                //getStocks()
            }
            else {
                setErr(res.data.messages)
            }

        } catch (err) {
            setErr("Server error on refresh..." + err);
            if (err?.response?.data) setErr(err.response.data);
        }
    }

    const handlePrev = () => {
        if (currentPage > 1)
            setCurrentPage(currentPage - 1)
    }

    const handleNext = () => {
        if (currentPage < totalPages)
            setCurrentPage(currentPage + 1)
    }

    return (
        <div className=" w-full ">
            <div className="py-0 px-1 flex justify-center items-center">
                <div className="grid sm:grid-cols-12 gap-8 sm:gap-6 w-full">
                    <div className="sm:col-span-6">
                        <div className="relative flex justify-end items-right">
                            <button className={`bg-blue-500 hover:bg-blue-700 text-white  font-bold py-2 px-4 rounded ${isloading ? 'bg-gray-500 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-700 text-white'}`} onClick={refreshBasketStockPrices}>
                                Refresh
                            </button>
                            {isloading &&
                                <img src={loadingImg} alt="Icon" className="w-6 text-left justify-start items-right" />
                            }
                        </div>
                    </div>
                    <div className="sm:col-span-6">
                        <div className="relative flex justify-left items-left">
                            <span className="text-gray-500 text-medium font-bold">{refresh && <>Last refresh Date & Time:</>} {date}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-right ml-auto px-5">
                <span className="mr-0 text-sm font-normal text-gray-500  mb-0 md:mb-0 w-full inline md:inline md:w-auto">
                    Showing <span className="font-semibold text-gray-900 ">{(currentPage - 1) * rows + 1}-{parseInt(((currentPage - 1) * rows) + rows) < stocks.length ? parseInt(((currentPage - 1) * rows) + rows) : stocks.length}</span>
                    &nbsp;of <span className="font-semibold text-gray-900 ">{stocks.length}</span></span>

                <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm h-5">
                    <li>
                        <span onClick={e => handlePrev()} className="flex items-center justify-center px-3 h-5 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 cursor-pointer">Prev</span>
                    </li>
                    <li>
                        <span onClick={e => handleNext()} className="flex items-center justify-center px-3 h-5 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 cursor-pointer">Next</span>
                    </li>
                </ul>
            </div>

            <div className="z-0 relative  w-full  flex justify-center items-start  ">
                <div className="b order  relative  w-full ">
                    <div className="p-2 md:p-5 w-full">
                        <div className=" w-full">
                            <div className="grid sm:grid-cols-12 gap-8 sm:gap-5 w-full h-50 overflow-auto ">
                                {/* List of Recipe  ---- */}
                                <div className="sm:col-span-12">
                                    <div className="relative overflow-x-auto shadow-md ">
                                        <table className="min-w-full divide-y divide-gray-400 w-full flex flex-col items-center justify-between">
                                            <thead className="bg-tblPrimary text-white w-full">
                                                <tr className="flex w-full">
                                                    {stockHeaders.map((stockHeaderItem, index) => (
                                                        <th key={index} scope="col" className="px-6 py-1 w-1/2 text-start font-medium border">{stockHeaderItem.name}</th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200 w-full ">
                                                {stocks &&
                                                    stocks.
                                                    slice(((currentPage - 1) * rows), parseInt(((currentPage - 1) * rows) + rows)).
                                                        map((list, index) => (
                                                            <tr key={index} className={`flex items-center w-full ${index % 2 === 0 ? '' : 'bg-blue-100'}`}>
                                                                <PriceEdit list={list} />
                                                            </tr>
                                                        ))}
                                            </tbody>

                                        </table>
                                    </div>
                                </div>
                                <div className="sm:col-span-12 sm:gap-6 w-full h-20 overflow-auto px-5">
                                    <p className="text-red-400 mt-8">
                                        {err}
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StockEdit