import React from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaTrash } from "react-icons/fa";
import config from '../../config';

const CashLedgerReport = () => {
    const companyId = useSelector((state) => state.user.companyId);
    const role = useSelector((state) => state.user.role);

    const [addAllowed, setAddAllowed] = useState(false)
    const [deleteAllowed, setDeleteAllowed] = useState(false)

    const [cashLedgerList, setCashLedgerList] = useState([])
    const [err, setErr] = useState("")
    const [baskets, setBaskets] = useState([])
    const [categories, setCategories] = useState([])
    const [inputs, setInputs] = useState({
        clientName: "",
        clientCode: "",
        categoryId: 0,
        basketId: 0,
        fromDate: null,
        toDate: null,
    })
    const [subTotal, setSubTotal] = useState(0);

    const [currentDate, setCurrentDate] = useState('')

    const email = useSelector((state) => state.user.email);
    const navigate = useNavigate();
    useEffect(() => {
        if (email === null || email === "") {
            navigate("/")
        }
    })

    useEffect(() => {
        const today = new Date();
        const dateString = today.toISOString().substring(0, 10);
        setCurrentDate(dateString);
        inputs.fromDate = dateString;
        getBaskets();
        getCategories();
        //handleSearch(null);
        getScreenActionRights(role);
    }, [role])

    const getScreenActionRights = async (roleId) => {
        try {
            const encodedScreenName = encodeURIComponent('Cash Register');
            const queryParams = {
                params: {
                    roleId: roleId,
                    screenName: encodedScreenName
                }
            };
            const res = await axios.get(config.BL_API_URL + `/ScreenActionRoleMapController/v1/getScreenActionRights?roleId=${queryParams.params.roleId}&screenName=${queryParams.params.screenName}`);
            if (res.data)
                if (res.data.includes('Add'))
                    setAddAllowed(true)

            if (res.data.includes('Delete'))
                setDeleteAllowed(true)
            else
                setErr("backend error")

        } catch (err) {
            //console.log("first")
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }
    }
    const getCategories = async (e) => {
        try {
            const res = await axios.get(config.BL_API_URL + "/CashLedger/v1/getCashTransactionCategories");

            if (res.data)
                setCategories(res.data)
            else
                setErr("backend error")

        } catch (err) {
            //console.log("first")
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }
    }

    const getBaskets = async (e) => {
        try {
            const res = await axios.get(config.BL_API_URL + "/basket/v1");

            if (res.data)
                setBaskets(res.data)
            else
                setErr("backend error")

        } catch (err) {
            //console.log("first")
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }
    }

    const handleClearSearch = async (e) => {
        let clientName = document.getElementById("client-Name");
        let clientCode = document.getElementById("client-Code");
        let fromDate = document.getElementById("fromDate");
        let toDate = document.getElementById("toDate");
        let basket = document.getElementById("basketId");
        let category = document.getElementById("categoryId");
        clientName.value = ""
        clientCode.value = ""
        fromDate.value = ""
        toDate.value = ""
        basket.value = ""
        category.value = ""
        setInputs({
            clientName: "",
            clientCode: "",
            categoryId: 0,
            basketId: 0,
            fromDate: null,
            toDate: null,
        });
        setCashLedgerList([]);
    }

    const handleSearch = async (e) => {
        try {
            setErr("");
            if (inputs.clientCode === "" || inputs.clientCode === undefined || inputs.clientCode === null
                || inputs.basketId === "" || inputs.basketId === undefined || inputs.basketId === null ||
                inputs.basketId === 0) {
                setErr("Please select basket and client code");
                return;
            }


            const res = await axios.post(config.BL_API_URL + "/CashLedger/v1/getCashLedgerReport", inputs);

            if (res.data) {

                const total = res.data.reduce((acc, transaction) => {
                    if (transaction.transactionType === 'Payin') {
                        return acc + transaction.amount;
                    } else if (transaction.transactionType === 'Payout') {
                        return acc - transaction.amount;
                    } else {
                        return acc;
                    }
                }, 0)

                setSubTotal(total.toFixed(2));
                setCashLedgerList(res.data);
                setErr("");
            }
            else {
                setErr("backend error")
                setSubTotal(0);
            }

        } catch (err) {
            setSubTotal(0);
            //console.log("first")
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }
    }

    const deleteCashLedger = async (id) => {
        try {
            let ledgerId = Number(id);
            const res = await axios.get(config.BL_API_URL + "/CashLedger/v1/deleteById", { params: { ledgerId } });

            if (res.status === 200)
                handleSearch();
            else
                setErr("backend error")

        } catch (err) {
            //console.log("first")
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }
    }

    function formatNumber(value) {
        return new Intl.NumberFormat('en-IN').format(value);
    }

    function formatDate(value) {
        if (value == null || value == undefined || value == "") {
            return;
        }
        return new Intl.DateTimeFormat('en-IN').format(value);
    }
    const handleChange = (e) => {
        setInputs(prev => ({ ...prev, [e.target.name]: e.target.value }))
    }


    return (
        <div className="my-12 md:px-14 p-4 max-w-screen-2xl mx-auto ">
            <div className="flex flex-col">
                <div className="-m-1.5 overflow-x-auto">

                    <div className="border rounded-lg divide-y divide-gray-200 ">
                        <div className='col-span-10'>
                            <div className=" col-span-1">
                                <div className="py-3 px-4 flex justify-center items-center">
                                    <span className="text-gray-500 text-2xl font-bold">Cash Ledger</span>
                                </div>
                                <div className='col-span-10 flex  gap-x-1 px-1 py-1 overflow-ellipsis'>
                                    <div className="grid grid-cols-9 gap-2 justify-start border-0 border-red-500">
                                        <div className="col-span-1 gap-0 text-xs ">
                                            <fieldset className='border-2 text-xs border-gray-200 p-0'>
                                                <legend className='m-1 p-0'>Basket Name</legend>
                                                <select
                                                    onChange={handleChange}
                                                    name="basketId" id="basketId"
                                                    className="py-0 px-0 pe-0 block w-full focus:outline-none">
                                                    <option value="">-select-</option>
                                                    {baskets && (
                                                        baskets.map((basket, index) => <option key={index} value={basket.id}>{basket.name}</option>)
                                                    )}
                                                </select>
                                            </fieldset>
                                        </div>
                                        <div className="col-span-1 gap-5 text-xs  ">
                                            <fieldset className='border-2 text-xs border-gray-200 p-0'>
                                                <legend className='m-1 p-0'>Client Name</legend>
                                                <div className='relative'>
                                                    <input id="client-Name" name="clientName" onChange={handleChange} placeholder='Search by client name' className="py-0 px-0 pe-0 block focus:outline-none" />
                                                    {/* <span className='absolute right-0 top-0 bottom-10 my-5 mt-2 mr-2 cursor-pointer'>x</span> */}
                                                </div>
                                            </fieldset>
                                        </div>
                                        <div className="col-span-1 gap-5 text-xs  ">
                                            <fieldset className='border-2 text-xs border-gray-200 p-0'>
                                                <legend className='m-1 p-0'>Client Code</legend>
                                                <input id="client-Code" w-full name="clientCode" onChange={handleChange} placeholder='Search by client code' className=" py-0 px-0 pe-0 block focus:outline-none" />
                                            </fieldset>
                                        </div>
                                        <div className="col-span-1 gap-5 text-xs ">
                                            <fieldset className='border-2 text-xs border-gray-200 p-0'>
                                                <legend className='m-1 p-0'>From Date</legend>
                                                <input
                                                    onChange={handleChange}
                                                    type="date" name="fromDate" id="fromDate" placeholder="from date"
                                                    className="py-0 px-0 pe-0 w-full block  focus:outline-none " value={currentDate}
                                                />
                                            </fieldset>
                                        </div>
                                        <div className="col-span-1 gap-5 text-xs ">
                                            <fieldset className='border-2 text-xs border-gray-200 p-0'>
                                                <legend className='m-1 p-0'>To Date</legend>
                                                <input
                                                    onChange={handleChange}
                                                    type="date" name="toDate" id="toDate" placeholder="to date"
                                                    className="py-0 px-0 pe-0 w-full block   focus:outline-none" />
                                            </fieldset>
                                        </div>
                                        <div className="col-span-1 gap-5 text-center text-xs " >
                                            <button type="button" onClick={e => handleSearch()} className="py-2 px-4 inline-flex right-0 gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-btnSecondary text-white hover:bg-btnPrimary disabled:opacity-50 disabled:pointer-events-none " data-hs-overlay="#hs-basic-modal">Search</button>
                                        </div>
                                        <div className="col-span-1 gap-5 text-center text-xs " >
                                            <button type="button" onClick={e => handleClearSearch()} className="py-2 px-4 inline-flex right-0 gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-btnSecondary text-white hover:bg-btnPrimary disabled:opacity-50 disabled:pointer-events-none " data-hs-overlay="#hs-basic-modal">Clear Search</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="overflow-hidden">
                            <div className="overflow-hidden">
                                <table className="min-w-full divide-y divide-gray-200  ">
                                    <thead className="bg-gray-200 ">
                                        <tr>
                                            <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase font-bold">Basket Name</th>
                                            <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase font-bold">Client Name</th>
                                            {/* <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase">Client Code</th> */}
                                            <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase font-bold" >Date</th>
                                            <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase font-bold">Category</th>
                                            <th scope="col" className="px-6 py-3 text-xs text-right font-medium text-gray-500 uppercase font-bold">Debit</th>
                                            <th scope="col" className="px-6 py-3 text-xs text-right font-medium text-gray-500 uppercase font-bold">Credit</th>
                                            <th scope="col" className="px-6 py-3 text-xs text-right font-medium text-gray-500 uppercase font-bold">Running Balance</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cashLedgerList &&

                                            cashLedgerList.map((cashLedger, index) => (
                                                <tr key={index} className="odd:bg-white even:bg-gray-100">
                                                    <td className="px-6 py-2 whitespace-nowrap text-sm  text-gray-800 ">{cashLedger[0]}</td>
                                                    <td className="px-6 py-2 whitespace-nowrap text-sm  text-gray-800 ">{cashLedger[1]}</td>
                                                    {/* <td className="px-6 py-4 whitespace-nowrap text-sm  text-gray-800 ">{cashLedger.clientCode}</td> */}
                                                    <td className="px-6 py-2 whitespace-nowrap text-sm  text-gray-800 ">{cashLedger[4]}</td>
                                                    <td className="px-6 py-2 whitespace-nowrap text-sm  text-gray-800 ">{cashLedger[8]}</td>
                                                    <td className="px-6 py-2 whitespace-nowrap text-sm text-right  text-gray-800 ">{formatNumber(cashLedger[6])}</td>
                                                    <td className="px-6 py-2 whitespace-nowrap text-sm text-right  text-gray-800 ">{formatNumber(cashLedger[5])}</td>
                                                    <td className="px-6 py-2 whitespace-nowrap text-sm text-right  text-gray-800 ">{formatNumber(cashLedger[7])}</td>

                                                </tr>
                                            ))}
                                        {(cashLedgerList === null || cashLedgerList.length <= 0) &&
                                            <tr><td colSpan={9} className='text-center text-red-500 '>No records to display!</td></tr>
                                        }
                                        <tr><td colSpan={9} className='text-center text-red-500 '>{ err}</td></tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default CashLedgerReport;