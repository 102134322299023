import React from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FiTrash, FiUserPlus } from "react-icons/fi";
import { VscSave } from "react-icons/vsc";
import { FcCancel } from "react-icons/fc";
import { FaTrash } from "react-icons/fa";
import config from '../../config';
import { FiEdit } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import AutoComplete from "../Dashboard/AutoComplete";

const MenuScreenActionMapping = () => {
    const companyId = useSelector((state) => state.user.companyId);
    const name = useSelector((state) => state.user.name);
    const role = useSelector((state) => state.user.role);

    const [actions, setActions] = useState([])
    const [roleList, setRoleList] = useState([])
    const [err, setErr] = useState("")
    const [addOn, setAddOn] = useState(false)
    const [editingRow, setEditingRow] = useState(null)
    const [screenActionMaps, setScreenActionMaps] = useState([])
    const [childMenuList, setChildMenuList] = useState([])
    const navigate = useNavigate();

    const [inputs, setInputs] = useState({
        actionId: -1,
        screenId: -1
    })
    const [searchCriterion, setSearchCriterion] = useState({
        clientName: "",
        clientCode: "",
        basketId: null,
        enabled: null,
        companyId: null
    })

    useEffect(() => {
        if (name === null || name === "") {
            navigate("/")
        }

        getActions();
        getChildMenus();
        //getRoles();
        handleSearch(companyId)

        //handleSearch(companyId)
    }, [companyId])

    const handleSearch = async (e) => {
        try {
            if (e === null || e === undefined) {
                searchCriterion.companyId = 1
            }
            else
                searchCriterion.companyId = e;
            setScreenActionMaps(null)
            let roleId = Number(role);
            //const res = await axios.get(config.BL_API_URL + "/company/v1/getBasketSubscribers", { params: { companyId } });
            const res = await axios.get(config.BL_API_URL + "/ScreenActionMapController/v1/getList");

            if (res.status === 200 && res.data)
                setScreenActionMaps(res.data)
            else
                setErr("backend error")

        } catch (err) {
            setScreenActionMaps(null)
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error..." + err);
        }
    }

    const handleAdd = () => {
        setAddOn(!addOn);
    }

    const handleCancel = () => {
        setAddOn(false);
    }
    const handleSave = async (e) => {
        try {

            const res = await axios.post(config.BL_API_URL + "/ScreenActionMapController/v1/addScreenActionMap", inputs);
            if (res.status === 200 && res.data.status === "success") {
                setAddOn(false);
                handleSearch(companyId);
            }
            else {
                setErr(res.data.messages[0]);
            }

        } catch (err) {
            //console.log("first")
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }
    }

    const getChildMenus = async (e) => {
        setErr("");
        try {

            const res = await axios.get(config.BL_API_URL + "/menu/v1/getAllChildMenus");
            if (res.status === 200 && res.data)
                setChildMenuList(res.data)
            else
                setErr("backend error")
        } catch (err) {
            //console.log("first")
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }
    }

    const handleChange = async (e) => {
        setInputs(prev => ({ ...prev, [e.target.name]: Number(e.target.value) }))
        if (e.target.name === "actionId") {
            setInputs(prev => ({ ...prev, 'actionId': Number(e.target.value) }))
        }
    }







    const handleDelete = async (e) => {
        //const value = e.target.value === "0" ? false : true;

        //e.preventDefault();
        let menuRoleMap = screenActionMaps.find(menuRoleMap => menuRoleMap.id === e);

        console.log(menuRoleMap)
        try {
            const res = await axios.post(config.BL_API_URL + "/MenuRoleMap/v1/deleteMenuRoleMap", menuRoleMap);

            if (res.status === 200) {
                //setEditingRow(null);
                handleSearch(companyId);
                //getBasketSubscribers(companyId);
            }
            else
                setErr("backend error")

        } catch (err) {
            //console.log("first")
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error..." + err);
        }
    }

    /* const handleDelete = async (e) => {
        //setEditingRow(e);
    } */

    const handleSearchCriterionChange = async (e) => {
        /*         if (e.target.name === "enabled") {
                    const value = e.target.value === "0" ? false : true;
                    setSearchCriterion(prev => ({ ...prev, [e.target.name]: value }))
                }
                else {
                    setSearchCriterion(prev => ({ ...prev, [e.target.name]: e.target.value, [e.target.id]: e.target.value }))
                } */
        setSearchCriterion(prev => ({ ...prev, [e.target.name]: e.target.value }))
    }

    const getActions = async (e) => {
        try {
            const res = await axios.get(config.BL_API_URL + "/ScreenAction/v1/getScreenActions");

            if (res.data)
                setActions(res.data)
            else
                setErr("backend error")

        } catch (err) {
            //console.log("first")
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }
    }

    const getRoles = async (e) => {
        try {

            const res = await axios.get(config.BL_API_URL + "/role/v1/getRolesForRoleId", { params: { roleId: Number(role) } });

            if (res.data) {
                setRoleList(res.data);
            }
            else {
                setErr("backend error")
            }

        } catch (err) {
            //console.log("first")
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }
    }

    return (
        <div className="my-10 md:px-14 p-4 max-w-screen-lg mx-auto ">
            <div className="flex flex-col">
                <div className="-m-1.5 overflow-x-auto">

                    <div className="border rounded-lg divide-y divide-gray-200 ">
                        <div className="py-3 px-4 flex justify-center items-center">
                            <span className="text-gray-500 text-2xl font-bold">Menu Screen-Action Mapping</span>
                        </div>

                        <div className="overflow-hidden">
                            <div className="overflow-hidden">
                                <table className="min-w-full divide-y divide-gray-200  ">
                                    <thead className="bg-gray-50 ">
                                        <tr >
                                            <th scope="col" className="px-2 py-1 text-xs text-start font-medium text-gray-500 uppercase ">Screen (Menu) Name</th>
                                            <th scope="col" className="px-2 py-1 text-start text-xs font-medium text-gray-500 uppercase ">Screen Action Name</th>
                                            {/* <th scope="col" className="px-2 py-1 text-start text-xs font-medium text-gray-500 uppercase ">Role</th> */}
                                            <th scope="col" className="px-2 py-1 text-start text-xs font-medium text-gray-500 uppercase">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="px-2 py-1 whitespace-nowrap text-sm  text-gray-800 ">
                                                {addOn &&
                                                    <select
                                                        onChange={handleChange}
                                                        name="screenId"
                                                        className="py-2 px-3 pe-9 block min-w-full w-full sm:w-auto border-gray-200 shadow-sm -mt-px -ms-px rounded-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none ">
                                                        <option value="-">- Select -</option>
                                                        {childMenuList && (
                                                            childMenuList.map((childMenu, index) => <option key={childMenu.id} value={childMenu.id}>{childMenu.parent.name + ' -> ' + childMenu.name}</option>)
                                                        )}
                                                    </select>
                                                }
                                            </td>

                                            <td className="px-2 py-1 whitespace-nowrap text-sm  text-gray-800 ">
                                                {addOn &&
                                                    <select
                                                        onChange={handleChange}
                                                        name="actionId"
                                                        className="py-2 px-3 pe-9 block min-w-full w-full sm:w-auto border-gray-200 shadow-sm -mt-px -ms-px rounded-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none ">
                                                        <option value="-">- Select -</option>
                                                        {actions && (
                                                            actions.map((action, index) => <option key={index} value={action.id}>{action.name}</option>)
                                                        )}
                                                    </select>
                                                }

                                            </td>
                                            {/*  <td className="px-2 py-1 whitespace-nowrap text-sm  text-gray-800 ">
                                                {addOn &&
                                                    <select
                                                        onChange={handleChange}
                                                        name="roleName" id="roleId"
                                                        className="py-2 px-3 pe-9 block min-w-full w-full sm:w-auto border-gray-200 shadow-sm -mt-px -ms-px rounded-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none ">
                                                        <option value="-">- Select -</option>
                                                        {roleList && (
                                                            roleList.map((role, index) => <option key={role.id} value={role.id}>{role.name}</option>)
                                                        )}
                                                    </select>
                                                }
                                            </td> */}

                                            <td className="px-2 py-1 whitespace-nowrap text-sm  text-gray-800 ">
                                                {!addOn &&
                                                    < span onClick={e => handleAdd()} type="button"
                                                        className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-400 hover:text-blue-600 cursor-pointer  ">
                                                        <FiUserPlus className="text-base" />New Mapping Entry
                                                    </span>
                                                }
                                                {addOn &&
                                                    <>
                                                        <span onClick={e => handleCancel()} aria-disabled={addOn} type="button" className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-400 hover:text-blue-600 cursor-pointer  ">
                                                            <FaTrash className="text-base" />Cancel
                                                        </span>

                                                        <span onClick={e => handleSave()} type="button" className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-400 hover:text-blue-600 cursor-pointer  ">
                                                            <VscSave className="text-blue-600 text-base" />Save
                                                        </span>
                                                    </>
                                                }

                                            </td>
                                        </tr>
                                        {screenActionMaps &&
                                            screenActionMaps.map((screenAction, index) => (
                                                <tr key={screenAction.id} className="odd:bg-gray-100 even:bg-white hover:bg-gray-200">
                                                    <td className="px-2 py-1 whitespace-nowrap text-sm  text-gray-800 w-[350px] ">{screenAction?.menu?.parent?.name ?? 'Root'} -- {screenAction?.menu?.name ?? 'empty'}</td>
                                                    <td className="px-2 py-1 whitespace-nowrap text-sm  text-gray-800 ">{screenAction?.screenAction?.name ?? ''}</td>
                                                    {/* <td className="px-2 py-1 whitespace-nowrap text-sm  text-gray-800 ">{menuRoleMap?.menu?.parent?.name ?? 'Root'}</td> */}
                                                    <td className="px-2 py-1 whitespace-nowrap text-sm  text-gray-800 ">

                                                        <span onClick={e => handleDelete(screenAction.id)} type="button" className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-400 hover:text-blue-600 cursor-pointer  ">
                                                            <FiTrash className="text-base" />Delete
                                                        </span>

                                                    </td>
                                                </tr>
                                            ))}
                                        <tr>
                                            <td className="justify-center colspan-4">
                                                <p className="text-red-400 mt-8 justify-center">
                                                    {err}
                                                </p>
                                            </td>

                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default MenuScreenActionMapping;