import React from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FiUserPlus } from "react-icons/fi";
import { VscSave } from "react-icons/vsc";
import { FcCancel } from "react-icons/fc";
import { FaTrash } from "react-icons/fa";
import config from '../../config';
import { FiEdit } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import AutoComplete from "../Dashboard/AutoComplete";

const UserRoleMapping = () => {
    const companyId = useSelector((state) => state.user.companyId);
    const name = useSelector((state) => state.user.name);
    const role = useSelector((state) => state.user.role);

    const [parentMenus, setParentMenus] = useState([])
    const [roleList, setRoleList] = useState([])
    const [err, setErr] = useState("")
    const [addOn, setAddOn] = useState(false)
    const [editingRow, setEditingRow] = useState(null)
    const [menuRoleMaps, setMenuRoleMaps] = useState([])
    const [childMenuList, setChildMenuList] = useState([])
    const navigate = useNavigate();

    const [inputs, setInputs] = useState({
        parentId: -1,
        menuId: -1,
        roleId: -1
    })
    const [searchCriterion, setSearchCriterion] = useState({
        clientName: "",
        clientCode: "",
        basketId: null,
        enabled: null,
        companyId: null
    })

    useEffect(() => {
        if (name === null || name === "") {
            navigate("/")
        }

        //getBasketSubscribers(companyId);
        getParentMenus();
        getRoles();
        handleSearch(companyId)

        //handleSearch(companyId)
    }, [companyId])

    const handleSearch = async (e) => {
        try {
            if (e === null || e === undefined) {
                searchCriterion.companyId = 1
            }
            else
                searchCriterion.companyId = e;
            setMenuRoleMaps(null)
            //const res = await axios.get(config.BL_API_URL + "/company/v1/getBasketSubscribers", { params: { companyId } });
            const res = await axios.get(config.BL_API_URL + "/AppUser/v1/getUserRoleMap");

            //console.log(res.data)

            if (res.status === 200 && res.data)
                setMenuRoleMaps(res.data)
            else
                setErr("backend error")

        } catch (err) {
            setMenuRoleMaps(null)
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }
    }

    const handleAdd = () => {
        setAddOn(!addOn);
    }

    const handleCancel = () => {
        setAddOn(false);
    }
   

    const getChildMenus = async (e) => {
        setErr("");
        try {

            const res = await axios.post(config.BL_API_URL + "/menu/v1/getChildMenus", { parentId: Number(e) });
            if (res.status === 200 && res.data)
                setChildMenuList(res.data)
            else
                setErr("backend error")
        } catch (err) {
            //console.log("first")
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }
    }

    const handleChange = async (e) => {
        setErr("");
        if (e.target.name === "parentName") {
            getChildMenus(e.target.value);
        }
console.log(e.target.id)
console.log(role)
console.log(e.target.value)
console.log(e.target.value === 1 && role !== 1)
        if(Number(e.target.value) === 1 && Number(role) !== 1){
            setErr("Only Admin can make another user Admin!")
            return;
        }

        setInputs(prev => ({ ...prev, [e.target.name]: e.target.value, [e.target.id]: Number(e.target.value) }))

        let editingAppUser = menuRoleMaps.find(appUser => appUser.id === editingRow);
        editingAppUser.role.id = Number(e.target.value);

        try {
            const res = await axios.post(config.BL_API_URL + "/AppUser/v1/updateUserRoleMap", editingAppUser);

            if (res.status === 200) {
                setEditingRow(null);
                handleSearch(companyId);
            }
            else
                setErr("backend error")

        } catch (err) {
            //console.log("first")
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }
    }

    const handleEdit = async (e) => {
        setEditingRow(e);
    }

    const handleEditSave = async (e) => {
        setEditingRow(null)
    }

    const handleEditSaveCancel = async (e) => {
        setErr("");
        setEditingRow(null)
    }

    const handleSearchCriterionChange = async (e) => {
        /*         if (e.target.name === "enabled") {
                    const value = e.target.value === "0" ? false : true;
                    setSearchCriterion(prev => ({ ...prev, [e.target.name]: value }))
                }
                else {
                    setSearchCriterion(prev => ({ ...prev, [e.target.name]: e.target.value, [e.target.id]: e.target.value }))
                } */
        setSearchCriterion(prev => ({ ...prev, [e.target.name]: e.target.value }))
    }

    const getParentMenus = async (e) => {
        try {
            const res = await axios.get(config.BL_API_URL + "/menu/v1/getParentMenus");

            if (res.data)
                setParentMenus(res.data)
            else
                setErr("backend error")

        } catch (err) {
            //console.log("first")
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }
    }

    const getRoles = async (e) => {
        try {

            const res = await axios.get(config.BL_API_URL + "/role/v1/getRoles");

            if (res.data) {
                setRoleList(res.data);
            }
            else {
                setErr("backend error")
            }

        } catch (err) {
            //console.log("first")
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }
    }

    return (
        <div className="my-10 md:px-14 p-4 max-w-screen-xl mx-auto ">
            <div className="flex flex-col">
                <div className="-m-1.5 overflow-x-auto">

                    <div className="border rounded-lg divide-y divide-gray-200 ">
                        <div className="py-3 px-4 flex justify-center items-center">
                            <span className="text-gray-500 text-2xl font-bold">User Role Mapping</span>
                        </div>

                        <div className="overflow-hidden">
                            <div className="overflow-hidden">
                                <table className="min-w-full divide-y divide-gray-200  ">
                                    <thead className="bg-gray-50 ">
                                        <tr>
                                            <th scope="col" className="px-2 py-1 text-start text-xs font-medium text-gray-500 uppercase ">User Name</th>
                                            <th scope="col" className="px-2 py-1 text-start text-xs font-medium text-gray-500 uppercase ">User Login ID</th>
                                            <th scope="col" className="px-2 py-1 text-start text-xs font-medium text-gray-500 uppercase ">Role Name</th>
                                            <th scope="col" className="px-2 py-1 text-start text-xs font-medium text-gray-500 uppercase">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        
                                        {menuRoleMaps &&
                                            menuRoleMaps.map((userRoleMap) => (
                                                <tr key={userRoleMap.id}>
                                                    <td className="px-2 py-1 whitespace-nowrap text-sm  text-gray-800 ">{userRoleMap.name}</td>
                                                    <td className="px-2 py-1 whitespace-nowrap text-sm  text-gray-800 ">{userRoleMap.email}</td>
                                                    {editingRow !== userRoleMap.id &&
                                                        <td className="px-2 py-1 whitespace-nowrap text-sm  text-gray-800 w-[250px] ">{userRoleMap.role.name}</td>
                                                    }
                                                    {editingRow === userRoleMap.id &&
                                                        <select
                                                            onChange={handleChange}
                                                            value={userRoleMap.role.id}
                                                            name="roleName" id="roleId"
                                                            className="py-2 px-3 pe-9 block min-w-full w-full sm:w-auto border-gray-200 shadow-sm -mt-px -ms-px rounded-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none ">
                                                            <option value="-">- Select -</option>
                                                            {roleList && (
                                                                roleList.map((role, index) => <option key={role.id} value={role.id}>{role.name}</option>)
                                                            )}
                                                        </select>
                                                    }

                                                    <td className="px-2 py-1 whitespace-nowrap text-sm  text-gray-800 ">
                                                        {editingRow !== userRoleMap.id &&
                                                            <span onClick={e => handleEdit(userRoleMap.id)} type="button" className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-400 hover:text-blue-600 cursor-pointer  ">
                                                                <FiEdit className="text-base" />Edit
                                                            </span>
                                                        }
                                                        {editingRow === userRoleMap.id &&
                                                            <>{/* <span onClick={() => handleEditSave()} type="button" className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-400 hover:text-blue-600 cursor-pointer  ">
                                                                <VscSave className="text-blue-600 text-base" />Save
                                                            </span> */}
                                                                <span onClick={() => handleEditSaveCancel()} type="button" className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-400 hover:text-blue-600 cursor-pointer  ">
                                                                    <FcCancel className="ml-2 text-red-600 text-base" />Cancel
                                                                </span></>


                                                        }
                                                    </td>
                                                </tr>
                                            ))}
                                        <tr>
                                            <td className="justify-center colspan-4">
                                                <p className="text-red-400 mt-8 justify-center">
                                                    {err}
                                                </p>
                                            </td>

                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default UserRoleMapping;