import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    name: "",
    email: "",
    companyId: "",
  },
  reducers: {
    update: (state, action) => {
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.companyId = action.payload.companyId;
      state.companyName = action.payload.companyName;
      state.role = action.payload.role;
    },
    remove: (state) => {
      state.name = "";
      state.email = "";
      state.companyId = "";
      state.companyName = "";
      state.role = "";
    }
  },
});

export const { update, remove } = userSlice.actions;

export default userSlice.reducer;