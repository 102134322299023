export const basketItems = [
    { name: "Recipe Name" },
    { name: "Display Name" },
    { name: "Available From" },
    { name: "Category" },
    { name: "Min Quantity" },
    { name: "Files" },
    { name: "Created By" },
    { name: "Who_Can Cook?" },
    { name: "Action" },
    { name: "Description" },


]

export const stockItems = [
    { name: "Stock" },
    { name: "Allocation%" },
    { name: "New Allocation%" },
    { name: "Is Fixed" },
    { name: "Action" },
    { name: "Remark" },
]
