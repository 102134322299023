import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { tableHeader } from "./MessageLogTableHeaders";
import config from './../config';

const InternalMessageLog = () => {

    const email1 = useSelector((state) => state.user.email);
    const navigate = useNavigate();
    const [messages, setMessages] = useState([])
    const [err, setErr] = useState("");
    /* useEffect(() => {
      if (email1 === null || email1 === "") {
        navigate("/")
      }
    }) */
    useEffect(() => {
        getInternalMessagesLog()
    }, [])

    const getInternalMessagesLog = async (e) => {

        try {
            const res = await axios.get(config.BL_API_URL+"/messageLogs/v1/getlist")
            console.log(res.data.length)

            if (res.data)
                setMessages(res.data)
            else
                setErr("backend error")

        } catch (err) {
            console.log("error 333 ", err)
            //if (err.response.status === 500)
            setErr("Server error...");
            //else
            //    setErr(err.response.data);
            if (err?.response?.data) setErr(err.response.data);
        }
    }

    return (
        <div className=" my-24 md:px-14 max-w-screen-2xl mx-auto ">
            <div className="py-1 px-1 flex justify-center items-center">
            <span className="mb-6 ml-4 text-2xl">Internal Messages Log</span>
      </div>
            
            <div className="mx-0">
                <div className="sm:col-span-12">
                    <div className="relative overflow-x-auto shadow-md ">
                        <table className="min-w-full divide-y divide-gray-400 w-full flex flex-col items-center justify-between">
                            <thead className="bg-tblPrimary text-white w-full">
                                <tr className="flex w-full">
                                    <th scope="col" className="px-6 py-1 w-1/6 text-start font-medium border">Basket Name</th>
                                    <th scope="col" className="px-6 py-1 w-1/6 text-start font-medium border">Client Code</th>
                                    <th scope="col" className="px-6 py-1 w-1/5 text-start font-medium border">Message Type</th>
                                    <th scope="col" className="px-6 py-1 w-1/2 text-start font-medium border">Message</th>
                                    <th scope="col" className="px-6 py-1 w-1/3 text-start font-medium border">Date</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 w-full">
                                {messages &&
                                    messages.map((item, index) => (
                                        <tr key={item.messageId} className={`flex items-center w-full
                                                            ${index % 2 === 0 ? '' : 'bg-blue-100'}
                                                        `}>
                                            <td className="px-6 py-1 w-1/6   text-gray-800 border-separate  ">{item.basketName}</td>
                                            <td className="px-6 py-1 w-1/6  text-gray-800  border-separate">{item.clientCode}</td>
                                            <td className="px-6 py-1 w-1/5  text-gray-800  border-separate">{item.messageType}</td>
                                            <td className="px-6 py-1 w-1/2  text-gray-800 border-separate">{item.message}</td>
                                            <td className="px-6 py-1 w-1/3  text-gray-800  border-separate">{item.updatedOn}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

            {/* {err} */}
        </div>
    )
}

export default InternalMessageLog