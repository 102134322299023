import React, { useState } from "react";
import { BiSolidRightArrow } from "react-icons/bi";
import { BiSolidDownArrow } from "react-icons/bi";
import { useSelector } from "react-redux";
import axios from "axios";
import { useEffect } from "react";
import Details from "./Details";
import config from "../../config";

const BasketStockPerformance = ({ togglePerformanceReport, basketId }) => {

  const companyId = useSelector((state) => state.user.companyId);
  const [err, setErr] = useState("")
  const [basketStockList, setBasketStockList] = useState([])

  useEffect(() => {
  console.log("basketId", basketId);
    const getBasketPerformanceData = async (e) => {
      try {
        const res = await axios.get(config.BL_API_URL+"/basketstock/v1/getBasketStockPerformance", { params: { basketId } });

        if (res.data) {
          setBasketStockList(res.data)
         
        }
        else
          setErr("backend error")

      } catch (err) {
        console.log("first")
        if (err.response)
          setErr(err.response.data);
        else
          setErr("Server error...");
      }
    }

    getBasketPerformanceData(basketId);
  }, [basketId])



  const closeButtonHandle = () => {
    togglePerformanceReport()
  }



  return (

    <div id="authentication-modal" tabIndex="-1" className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full shadow-2xl ">
      <div className="relative p-4 h-[90%] w-full min-w-[550px] flex justify-center items-start  ">

        <div className="border sm:max-w-2xl max-w-[550px] mx-auto relative mt-16 bg-formbackground rounded-lg shadow-2xl ">

          <div className=" flex items-center justify-between p-3 md:p-3 border-b rounded-t ">

            <h3 className="text-xl font-semibold text-gray-900 ">
             Basket Performance Report
              
            </h3>
            <button onClick={e => closeButtonHandle()} type="button" className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center " data-modal-hide="authentication-modal">
              <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
            </div>
          <div className="p-2 md:p-5">
            <div className=" my-0 md:px-14 max-w-screen-2xl mx-auto ">
              <div className="h-[calc(90vh_-_220px)] overflow-y-scroll scroll-hidden">
              
              {basketStockList &&
                  basketStockList.map((item) => {
                    
                    return <Details className="py-0" item={item}/> // Added curly braces and return statement
                  })}
              {!basketStockList ||
                  basketStockList.length == 0 &&
              
              <div className="py-1 px-1 flex justify-center items-center">
              <span className="mb-6 ml-4 text-2xl">NO data to display.</span>
              </div>
              }
              </div>
            </div>
          </div>
           
        </div>
        
      </div>
    
    </div>
  )
}
export default BasketStockPerformance;