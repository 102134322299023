import Login from "./Login";
import LostPassword from "./LostPassword";
import React, { useEffect, useRef, useState } from "react";
import Register from "./Register";
import logo from "../assets/TWB.svg";
import usernotsign from "../assets/user-2.jpg";
import usersignin from "../assets/usersignin.png";
import { DiGoogleAnalytics } from "react-icons/di";
import { FaRegUser } from "react-icons/fa";
import { FaUsers } from "react-icons/fa";
import { FaBars, FaRegBookmark, FaXmark } from "react-icons/fa6";
import { FiUserPlus } from "react-icons/fi";
import { FiEdit } from "react-icons/fi";
import { IoTriangleSharp } from "react-icons/io5";
import { IoPrintOutline } from "react-icons/io5";
import { MdLogin } from "react-icons/md";
import { MdDisplaySettings } from "react-icons/md";
import { PiFunnelBold, PiSignOutBold } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { remove } from "../redux/userSlice";
import { navItems } from "./navItems";
import { IoAlertCircleSharp } from "react-icons/io5";
import { IoLogoFirebase } from "react-icons/io5";
import { IoCashOutline } from "react-icons/io5";
import { GrTransaction } from "react-icons/gr";
import { MdJoinInner } from "react-icons/md";
import { TbTableExport } from "react-icons/tb";
import { FaUserPlus } from "react-icons/fa6";
import { MdOutlineShoppingCartCheckout } from "react-icons/md";
import { MdCorporateFare } from "react-icons/md";
import { AiFillControl } from "react-icons/ai";
import { VscServerProcess } from "react-icons/vsc";
import { GiWoodPile } from "react-icons/gi";
import { LuCalculator } from "react-icons/lu";
import { RiFileHistoryLine } from "react-icons/ri";
import { TbListDetails } from "react-icons/tb";
import { MdPendingActions } from "react-icons/md";
import { MdOutlineToday } from "react-icons/md";
import config from '../config';
import axios from "axios";

const Navbar = () => {

    let menuRef = useRef();
    let reportRef = useRef();
    let adminRef = useRef();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const name = useSelector((state) => state.user.name);
    const companyName = useSelector((state) => state.user.companyName);
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [isUserMenuOpen, setIsUserMenuOpen] = useState(false)
    const [loginModel, setLoginModel] = useState(false)
    const [registerModel, setRegisterModel] = useState(false)
    const [lostpasswordModel, setlostpasswordModel] = useState(false)
    const [isReportOpen, setIsReportOpen] = useState(false)
    const [isMasterOpen, setIsMasterOpen] = useState(false)
    const [isAdminOpen, setIsAdminOpen] = useState(false)
    const [allowedMenuList, setAllowedMenuList] = useState([])

    const [user, setUser] = useState(false)
    const role = useSelector((state) => state.user.role);

    const reportsLinks = [
        { name: "Pending Actions", route: "/pendingactions", icon: <MdPendingActions className="mr-2 text-[16px] cursor-pointer" /> },
        { name: "Traded Report", route: "/traded", icon: <IoAlertCircleSharp className="mr-2 text-[16px] cursor-pointer" /> },
        { name: "Trade Details", route: "/tradeDetails", icon: <TbListDetails className="mr-2 text-[16px] cursor-pointer" /> },
        { name: "Internal Message Log", route: "/internalMessageLog", icon: <IoLogoFirebase className="mr-2 text-[16px] cursor-pointer" /> },
        { name: "Analytics", route: "/analytics", icon: <DiGoogleAnalytics className="mr-2 text-[16px] cursor-pointer" /> },
        { name: "Subscribers", route: "/BasketSubscriberReport", icon: <FaUsers className="mr-2 text-[16px] cursor-pointer" /> },
        { name: "As-On Holding", route: "/AsOnHoldingReport", icon: <MdOutlineToday className="mr-2 text-[16px] cursor-pointer" /> },
        { name: "Allocation Calculator", route: "/AllocationCalculator", icon: <LuCalculator className="mr-2 text-[16px] cursor-pointer" /> },
        { name: "Allocation History", route: "/AllocationHistory", icon: <RiFileHistoryLine className="mr-2 text-[16px] cursor-pointer" /> },
        { name: "Cash Ledger", route: "/CashLedgerReport", icon: <IoCashOutline className="mr-2 text-[16px] cursor-pointer" /> },
    ];

    const backOfficeLinks = [
        { route: "/stockedit", icon: <FiEdit className="mr-2 text-[16px] cursor-pointer" />, label: "Stock Edit" },
        { route: "/cashRegister", icon: <IoCashOutline className="mr-2 text-[16px] cursor-pointer" />, label: "Cash Register" },
        { route: "/corporateActions", icon: <MdCorporateFare size={20} className="mr-2 text-[16px] cursor-pointer" />, label: "Corporate Actions" },
        { route: "/clientList", icon: <FaUserPlus className="mr-2 text-[16px] cursor-pointer" />, label: "Add New Client" },
        { route: "/basketSubscription", icon: <MdJoinInner className="mr-2 text-[16px] cursor-pointer" />, label: "Basket Subscription" },
        { route: "/orders", icon: <TbTableExport className="mr-2 text-[16px] cursor-pointer" />, label: "Export Client Orders" },
        { route: "/generateClientOrders", icon: <MdOutlineShoppingCartCheckout size={20} className="mr-2 text-[16px] cursor-pointer" />, label: "Generate Client Orders" },
        { route: "/orderManagement", icon: <TbTableExport className="mr-2 text-[16px] cursor-pointer" />, label: "Order Management" },
        { route: "/ControlPanel", icon: <AiFillControl size={20} className="mr-2 text-[16px] cursor-pointer" />, label: "Control Panel" },
        { route: "/eodProcess", icon: <VscServerProcess className="mr-2 text-[16px] cursor-pointer" />, label: "EOD EOM Process" },
        { route: "/clientHoldingImport", icon: <GiWoodPile size={20} className="mr-2 text-[16px] cursor-pointer" />, label: "Client Holding Import" },
        { route: "/ImportTrades", icon: <GrTransaction className="mr-2 text-[16px] cursor-pointer" />, label: "Import Trades" },
        { route: "/ImportIndexData", icon: <GrTransaction className="mr-2 text-[16px] cursor-pointer" />, label: "Import Index Data" }
    ];
    const adminLinks = [
        { route: "/screens", icon: <IoAlertCircleSharp className="mr-2 text-[16px]" />, label: "Screen" },
        { route: "/screenActions", icon: <MdPendingActions className="mr-2 text-[16px]" />, label: "Screen Actions" },
        { route: "/roles", icon: <TbListDetails className="mr-2 text-[16px]" />, label: "Roles" },
        { route: "/menuScreenActionMapping", icon: <IoLogoFirebase className="mr-2 text-[16px]" />, label: "Menu Screen Action Mapping" },
        { route: "/roleMenuMapping", icon: <IoLogoFirebase className="mr-2 text-[16px]" />, label: "Role Menu Mapping" },
        { route: "/userRoleMapping", icon: <IoLogoFirebase className="mr-2 text-[16px]" />, label: "User Role Mapping" },
        { route: "/screenActionRoleMapping", icon: <IoLogoFirebase className="mr-2 text-[16px]" />, label: "ScreenAction Role Mapping" },
        
    ];

    // out side menu click - menu hide
    useEffect(() => {
        if (name === null || name === "" || name === undefined) {
            setAllowedMenuList([])
            navigate("/")
        }

        let handle = (e) => {
            // console.log("Click!")
            if (menuRef != null && menuRef.current != null && !menuRef.current.contains(e.target)) {
                closeMenu()
            }
            if (reportRef != null && reportRef.current != null && !reportRef.current.contains(e.target)) {
                closeMenu()
            }
        }
        document.addEventListener("click", handle, true)
        getRoleSpecificMenu(role);

    }, [role])

    const closeMenu = () => {
        setIsUserMenuOpen(false)
        setIsMenuOpen(false)
        setIsReportOpen(false)
        setIsMasterOpen(false)
        setIsAdminOpen(false)
    }

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen)
    }

    const toggleUserMenu = async () => {
        setIsUserMenuOpen(!isUserMenuOpen)
    }

    const toggleMasterMenu = () => {
        setIsMasterOpen(!isMasterOpen)
    }

    const toggleReportMenu = () => {
        setIsReportOpen(!isReportOpen)
    }

    const toggleAdminMenu = () => {
        setIsAdminOpen(!isAdminOpen)
    }

    const toggleLoginModal = () => {
        setIsUserMenuOpen(false)
        setIsMenuOpen(false)
        setLoginModel(!loginModel)
        setRegisterModel(false)
        setlostpasswordModel(false)
    }

    const toggleRegisterModal = () => {
        setIsUserMenuOpen(false)
        setIsMenuOpen(false)
        setRegisterModel(!registerModel)
        setLoginModel(false)
        setlostpasswordModel(false)
    }

    const toggleLostPasswordModal = () => {
        setIsUserMenuOpen(false)
        setIsMenuOpen(false)
        setRegisterModel(false)
        setLoginModel(false)
        setlostpasswordModel(!lostpasswordModel)
    }

    const logOut = () => {
        setIsUserMenuOpen(false)
        setIsMenuOpen(false)
        localStorage.removeItem("user")
        dispatch(remove());
        console.log("logout")
        navigate("/")
    }

    const getRoleSpecificMenu = async (roleId) => {
        try {
            const res = await axios.get(config.BL_API_URL + "/MenuRoleMap/v1/getMenuByUserRole", { params: { roleId } });
            if (res.data) {

                setAllowedMenuList(res.data);
            }
            else {
                //setErr("backend error")
            }
        } catch (err) {
/*             if (err.response)
                //setErr(err.response.data);
            else
                //setErr("Server error...");
 */        }
    }

    // const closeMenu = (menulink) => {
    //     setIsUserMenuOpen(false)
    //     setIsMenuOpen(false)
    // }

    const isMenuAllowed = (menu, allowedMenuList) => {
        return allowedMenuList.some(allowedMenu => allowedMenu.toLowerCase() === menu.toLowerCase());
    };

    return (
        <>
            <nav className="bg-white md:px-14 p-2 max-w-screen-2xl border-b mx-auto text-primary fixed top-0 right-0 left-0">
                <div className="text-lg container mx-auto flex justify-between items-center font-medium">
                    {/* Logo */}
                    <div className="flex space-x-14 items-center">
                        <Link to="/" className="text-xl font-semibold flex items-center space-x-3 text-primary">
                            <img src={logo} alt="" className="w-10 inline-block items-center cursor-pointer rounded-lg rounded-br-[10px]" />
                        </Link>
                    </div>
                    {name &&
                        <div className="flex space-x-14 items-center">


                        </div>
                    }
                    {/* language & signup */}
                    <div className="space-x-8 hidden md:flex items-center">

                        {/* nav items */}
                        <ul className="md:flex text-base space-x-16 hidden">
                            {
                                navItems.slice(0, 1).map(({ link, path }) => {

                                    let isDisabled = !allowedMenuList.some(allowedMenu => allowedMenu.toLowerCase() === link.toLowerCase());
                                    /* if (isDisabled) {
                                        navigate("/")
                                        return null;
                                    } */
                                    return (
                                        <Link key={link} to={path} className={`block hover:text-gray-400 ${isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'} uppercase`}>

                                            {link}
                                        </Link>
                                    );
                                })
                            }


                            {name && (
                                <>
                                    {isMenuAllowed('dashboard', allowedMenuList) ? (
                                        <Link to="/dashboard" className="block hover:text-gray-400 cursor-pointer uppercase">
                                            Dashboard
                                        </Link>
                                    ) : (
                                        <span className="block text-gray-400 cursor-not-allowed uppercase">
                                            Dashboard
                                        </span>
                                    )}
                                    {/* Uncomment and use the same logic for other links if needed */}
                                    {/* {isMenuAllowed('reports', allowedMenuList) ? (
                                  <Link to="/reports" className="block hover:text-gray-400 cursor-pointer uppercase">
                                    Report
                                  </Link>
                                ) : (
                                  <span className="block text-gray-400 cursor-not-allowed uppercase">
                                    Report
                                  </span>
                                )} */}
                                </>
                            )}
                        </ul>
                        <>
                            {name &&
                                <>
                                    {isMenuAllowed('backoffice', allowedMenuList) ? (
                                        <div className="relative">
                                            <span onClick={toggleMasterMenu} className="block hover:text-gray-400 cursor-pointer text-base uppercase">Backoffice</span>

                                            <div ref={reportRef} className={` w-[250px] text-sm space-y-4  px-4 mt-12 pb-5 bg-primary ${isMasterOpen ? "block absolute top-0 right-10 shadow-xl" : "hidden"}`}>

                                                <IoTriangleSharp className="right-2 -top-2 text-primary absolute " />

                                                {name &&

                                                    backOfficeLinks.map((link, index) => {
                                                        const isDisabled = !allowedMenuList.some(allowedMenuList => allowedMenuList.toLowerCase() === link.label.toLowerCase());
                                                        return (
                                                            <React.Fragment key={link.label}>
                                                                <Link
                                                                    onClick={isDisabled ? (e) => e.preventDefault() : closeMenu}
                                                                    to={link.route}
                                                                    className={`flex items-center text-blue-300 hover:text-gray-500 mb-2 cursor-pointer uppercase ${isDisabled ? 'text-gray-500 cursor-not-allowed' : 'cursor-pointer'}`}
                                                                >
                                                                    {link.icon}
                                                                    {link.label}
                                                                </Link>
                                                                {(index === 5 || index === 9 || index === 12) && <hr className="my-2 border-gray-200" />}
                                                            </React.Fragment>
                                                        );
                                                    })


                                                }
                                            </div>
                                        </div>
                                    ) : (
                                        <span className="block text-gray-400 cursor-not-allowed uppercase">
                                            Backoffice
                                        </span>
                                    )}
                                </>
                            }
                        </>

                        {name &&
                            <>
                                {isMenuAllowed('report', allowedMenuList) ? (
                                    <div className="relative">
                                        <span onClick={toggleReportMenu} className="block hover:text-gray-400 cursor-pointer text-base uppercase">Report</span>

                                        <div ref={adminRef} className={` w-[180px] text-sm space-y-4  px-4 mt-12 pb-5 bg-primary ${isReportOpen ? "block absolute top-0 right-10  shadow-xl" : "hidden"}`}>

                                            <IoTriangleSharp className="right-2 -top-2 text-primary absolute " />

                                            {name &&
                                                reportsLinks.map((link, index) => {
                                                    const isDisabled = !allowedMenuList.some(allowedMenuList => allowedMenuList.toLowerCase() === link.name.toLowerCase());
                                                    return (
                                                        <React.Fragment key={link.name}>
                                                            <Link
                                                                onClick={isDisabled ? (e) => e.preventDefault() : closeMenu}
                                                                to={link.route}
                                                                className={`flex items-center text-blue-300 hover:text-gray-500 mb-2 cursor-pointer uppercase ${isDisabled ? 'text-gray-500 cursor-not-allowed' : 'cursor-pointer'}`}
                                                            >
                                                                {link.icon}
                                                                {link.name}
                                                            </Link>
                                                            {(index === 2 || index === 3 || index === 4 || index === 6) && <hr className="my-2 border-gray-200" />}
                                                        </React.Fragment>
                                                    );
                                                })

                                            }
                                        </div>
                                    </div>
                                ) : (
                                    <span className="block text-gray-400 cursor-not-allowed uppercase">
                                        Report
                                    </span>
                                )}
                            </>
                        }

                        {name &&
                            <>
                                {isMenuAllowed('admin', allowedMenuList) ? (
                                    <div className="relative">
                                        <span onClick={toggleAdminMenu} className="block hover:text-gray-400 cursor-pointer text-base uppercase">Admin</span>

                                        <div ref={adminRef} className={` w-[180px] text-sm space-y-4  px-4 mt-12 pb-5 bg-primary ${isAdminOpen ? "block absolute top-0 right-10 shadow-xl" : "hidden"}`}>

                                            <IoTriangleSharp className="right-2 -top-2 text-primary absolute " />

                                            {name &&
                                                adminLinks.map((link, index) => {
                                                    const isDisabled = !allowedMenuList.some(allowedMenuList => allowedMenuList.toLowerCase() === link.label.toLowerCase());
                                                    return (
                                                        <React.Fragment key={link.name}>
                                                            <Link
                                                                onClick={isDisabled ? (e) => e.preventDefault() : closeMenu}
                                                                to={link.route}
                                                                className={`flex items-center text-blue-300 hover:text-gray-500 mb-2 cursor-pointer uppercase ${isDisabled ? 'text-gray-500 cursor-not-allowed' : 'cursor-pointer'}`}
                                                            >
                                                                {link.icon}
                                                                {link.label}
                                                            </Link>
                                                            {(index === 2 || index === 3 || index === 4 || index === 6) && <hr className="my-2 border-gray-200" />}
                                                        </React.Fragment>
                                                    );
                                                })

                                            }
                                        </div>
                                    </div>
                                ) : (
                                    <span className="block text-gray-400 cursor-not-allowed uppercase">
                                        Admin
                                    </span>
                                )}
                            </>
                        }

                        {/* Bookmark */}
                        {/* <span className="hidden lg:flex pl-6 items-center hover:text-secondary cursor-pointer"><FaRegBookmark className="mr-2" /></span> */}

                        <span className="ml-4 text-blue-400 text-sm uppercase">
                            {name && <>Welcome</>} {name} {name && <>@</>} {companyName} </span>
                        {/* user menu */}
                        {name === "" ?
                            <img onClick={toggleUserMenu} src={usernotsign} alt="" className="w-10 inline-block items-center rounded-full hover:shadow-md " />
                            :
                            <img onClick={toggleUserMenu} src={usersignin} alt="" className="w-10 inline-block items-center rounded-full hover:shadow-md " />
                        }
                        {/* <div className="relative"> */}
                        <div className={` w-[200px] text-sm space-y-4  px-4 mt-12 pb-5 bg-primary ${isUserMenuOpen ? "block absolute top-0 right-10 shadow-xl" : "hidden"}`}>

                            <IoTriangleSharp className="right-7 -top-2 text-primary absolute " />
                            {!name &&
                                <div>
                                    <span onClick={toggleLoginModal} className="flex items-center text-blue-300 hover:text-gray-300 cursor-pointer mb-2"><MdLogin className="mr-2 text-[16px] cursor-pointer uppercase" />LOGIN</span>
                                    <span onClick={toggleRegisterModal} className="flex items-center text-blue-300 hover:text-gray-300 cursor-pointer"><FiUserPlus className="mr-2 text-[16px] cursor-pointer uppercase" />REGISTER</span>
                                </div>
                            }
                            {name &&
                                <div>
                                    <Link to="/preferences" className="flex items-center text-blue-300 hover:text-gray-300  mb-2 cursor-pointer"><PiFunnelBold className="mr-2 text-[16px] cursor-pointer uppercase" />MY PREFERENCES</Link>
                                    <Link to="/profile" className="flex items-center text-blue-300 hover:text-gray-300  mb-2 cursor-pointer"><FaRegUser className="mr-2 cursor-pointer uppercase" />VIEW PROFILE</Link>
                                    <span onClick={logOut} className="flex items-center text-blue-300 hover:text-gray-300 cursor-pointer"><PiSignOutBold className="mr-2 text-[16px] cursor-pointer uppercase" />SIGN OUT</span>
                                </div>

                            }
                        </div>
                        {/* </div> */}
                    </div>

                    {/* menu btn. only display on mobile */}

                    <div className="md:hidden">
                        <span className="text-blue-400  mr-6 ">{name}</span>
                        <button onClick={toggleMenu} className="text-white text-xl focus:outline-none
                     focus:text-gray-300">
                            {
                                isMenuOpen ? (<FaXmark className="w-6 h-6 text-primary " />) : (<FaBars className="w-6 h-6 text-primary" />)
                            }
                        </button>
                    </div>
                </div>

            </nav>

            {/* mobile view menu items */}
            <div ref={menuRef} className={`space-y-1 px-4 pt-16 pb-4 bg-primary ${isMenuOpen ? "block lg:hidden fixed top-0 right-0 left-0 " : "hidden"}`}  >

                {
                    navItems.slice(0, 1).map(({ link, path }) => <Link key={link} to={path} className="block  text-blue-300 hover:text-gray-300">{link}</Link>)
                }
                {!name &&
                    <div>
                        <span onClick={toggleLoginModal} className="z-100 flex items-center mb-2 text-blue-300 hover:text-gray-300 cursor-pointer"><MdLogin className="mr-2 text-[16px] cursor-pointer" />Login</span>
                        <span onClick={toggleRegisterModal} className="flex items-center text-blue-300 hover:text-gray-300 cursor-pointer"><FiUserPlus className="mr-2 text-[16px] cursor-pointer" />Register</span>
                    </div>
                }
                {name &&
                    <div>
                        <Link to="/dashboard" className="flex items-center text-blue-300 hover:text-gray-300  mb-2 cursor-pointer"><MdDisplaySettings className="mr-2 text-[16px] cursor-pointer" />Dashboard</Link>
                        <Link to="/reports" className="flex items-center text-blue-300 hover:text-gray-300  mb-2 cursor-pointer"><IoPrintOutline className="mr-2 text-[16px] cursor-pointer" />Report</Link>

                        <Link to="/preferences" className="flex items-center text-blue-300 hover:text-gray-300  mb-2 cursor-pointer"><PiFunnelBold className="mr-2 text-[16px] cursor-pointer" />My Preferences</Link>
                        <Link to="/profile" className="flex items-center text-blue-300 hover:text-gray-300  mb-2 cursor-pointer"><FaRegUser className="mr-2 cursor-pointer" />View Profile</Link>
                        <span onClick={logOut} className="flex items-center text-blue-300 hover:text-gray-300 cursor-pointer"><PiSignOutBold className="mr-2 text-[16px] cursor-pointer" />Sign Out</span>
                    </div>

                }
            </div>


            {/*
                <button onClick={toggleLoginModal} data-modal-target="authentication-modal" data-modal-toggle="authentication-modal" className="block mt-20 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">
                    Toggle modal
                </button>
            */}


            {/* Login Model */}
            {loginModel &&

                <Login toggleLoginModal={toggleLoginModal} toggleRegisterModal={toggleRegisterModal} toggleLostPasswordModal={toggleLostPasswordModal} />

            }

            {/* Register Model */}
            {registerModel &&

                <Register toggleRegisterModal={toggleRegisterModal} toggleLoginModal={toggleLoginModal} />

            }

            {/* Register Model */}
            {lostpasswordModel &&

                <LostPassword toggleRegisterModal={toggleRegisterModal} toggleLoginModal={toggleLoginModal} toggleLostPasswordModal={toggleLostPasswordModal} />

            }
        </>
    )
}

export default Navbar