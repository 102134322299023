export const navItems=[
     {link: "Home", path: "/"}, 
     {link: "Dashboard", path: "/dashboard"}, 
     {link: "Masters", path: "/masters"},
    {link: "Reports", path: "/reports"},
    {link: "About", path: "/about"},
    {link: "Contact", path: "/contact"},
   
]

export const masterItems = [
    { link: "transactions", path: "/transactions" },
    { link: "stockEdit", path: "/stockEdit" },
]

export const reportItems = [
    { link: "Recipe", path: "/reports" },
    { link: "Analytics", path: "/analytics" },
    { link: "Subscribers", path: "/BasketSubscriberReport" },
    { link: "messageLogs", path: "/internalMessageLogs" },
]


export const userItems=[
    {link: "Dashboard", path: "/dashboard"},
    {link: "My Preferences", path: "/preferences"},
    {link: "View Profile", path: "/profile"},
    {link: "Sign Out", path: ""},
   
]

export const footerItems=[
    {link: "Privacy Policy", path: "privacy"},
    {link: "Terms Of Use", path: "terms"},
   
]