import axios from "axios";
import { useEffect, useState } from "react";
import { FcCancel } from "react-icons/fc";
import { FiEdit } from "react-icons/fi";
import { VscSave } from "react-icons/vsc";
import config from "../../config";

const PriceEdit = ({ list }) => {
    const [selectedId, setSelectedId] = useState(null)
    const [editOn, setEditOn] = useState(false)
    const [price, setPrice] = useState(list.prevClosePrice)
    const [errprice, setErrPrice] = useState(list.prevClosePrice)
    const [err, setErr] = useState("")

    const handleEditOn = (rowId) => {
        setPrice(rowId.prevClosePrice)
        setEditOn(true)
        setErr("")
    }

    useEffect(() => {
        //const newprice = parseInt(price)
        setPrice(price)
    }, [price])

    const handleEditCancel = (rowId) => {
        setEditOn(false)
        setErr("")
        setPrice(list.prevClosePrice)
    }

    const handleEditSave = async (stock) => {

        setErrPrice("")
        var errCount = 0;

        if (price == null || price == '' || parseFloat(price) < 1) {
            setErrPrice("* price min 1 ")
            errCount++;
        }

        if (errCount === 0) {

            try {
                stock.prevClosePrice = price;
console.log("Before update..." + stock);
                const res = await axios.post(config.BL_API_URL+"/stock/v1/updateStock",
                    stock);
                    setPrice(0.0);
                
                if (res.data != null && res.data.status === "Success") {
                    setEditOn(false);
                    setErrPrice(0.0);
                }
                else
                    setErr("backend error")

            } catch (err) {
                setErr("Server error..." + err);
                setEditOn(false);
                
            }
        }
    }

    return (
        <>
            <td className="px-6  w-1/2 whitespace-wrap  text-gray-800  border">{list.scriptName}</td>
            <td className="px-6  w-1/2 whitespace-nowrap  text-gray-800  border">
                {!editOn && list.prevClosePrice}
                {editOn && 
                    <input
                        onChange={e => setPrice(e.target.value)} value={price}
                        type="number" name="price" id="price" placeholder=""
                        className="border-2  shadow-sm text-bold border-gray-400  " />
                }
            </td>
            <td className="px-6  w-1/2 whitespace-nowrap  text-gray-800 border">
                {!editOn &&
                    <span onClick={e => handleEditOn(list)}  type="button" className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-400 hover:text-blue-600 cursor-pointer  ">
                        <FiEdit className="text-base" />Edit
                    </span>
                }
                {editOn &&
                    <span onClick={e => handleEditSave(list)} type="button" className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-400 hover:text-blue-600 cursor-pointer  ">
                        <VscSave className="text-blue-600 text-base" />Save
                    </span>
                }

                {editOn &&
                    <span onClick={e => handleEditCancel(list.id)} type="button" className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-red-400 hover:text-red-600 cursor-pointer  ">
                        <FcCancel className="ml-2 text-red-600 text-base" />Cancel
                    </span>
                }
                <br /><span className="text-red-400">{err} {setErrPrice}</span>
            </td>
        </>
    )
}

export default PriceEdit
