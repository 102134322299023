import S from "./AddStocks";
import axios from "axios";
import { useEffect, useState } from "react";
import { FaRegTrashAlt } from "react-icons/fa";
import { FaRegFile } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AddStocks from "./AddStocks";
import config from "../../config";

const AddBasket = () => {
    const email = useSelector((state) => state.user.email);
    const navigate = useNavigate();
    useEffect(() => {
        if (email === null || email === "") {
            navigate("/")
        }
    })

    const [productTypes, setProductTypes] = useState([])
    const [err, setErr] = useState("")
    const [file, setFile] = useState(null);
    const [files, setFiles] = useState("")
    const [stocks, setStocks] = useState([])
    const companyId = useSelector((state) => state.user.companyId);

    const [stocksModel, setStocksModel] = useState(false)

    // get category list --
    useEffect(() => {
        const getProductTypes = async (e) => {
            setErr("")
            try {
                const res = await axios.get(config.BL_API_URL+"/basket/v1/getProductTypes")
                //console.log(res.data)

                if (res)
                    setProductTypes(res.data)
                else
                    setErr("backend error")

            } catch (err) {
                console.log("first")
                if (err.response)
                    setErr(err.response.data);
                else
                    setErr("Server error...");
            }
        }
        getProductTypes()
    }, [])

    const [inputs, setInputs] = useState({
        companyId: "",
        basketName: "",
        description: "",
        productType: "",
        productTypeId: "",
        minimumInvestment: "",
        createdby: email,
        productCode: "",
        displayName: "",
        availableFrom: "",
        files: "",
        stocks: stocks
    })

    const [errrecipename, seterrrecipename] = useState("")
    const [errdisplayname, seterrdisplayname] = useState("")
    const [errminimum_quantity, seterrminimum_quantity] = useState("")
    const [erravailablefrom, seterravailablefrom] = useState("")
    const [errProductType, setErrProductType] = useState("")
    const [errProductCode, seterrProductCode] = useState("")
    const [errdescription, seterrdescription] = useState("")

    const handleChange = (e) => {
        setInputs(prev => ({ ...prev, [e.target.name]: e.target.value }))
    }

    const handleAddBasket = async (e) => {
        e.preventDefault()
        // validatation ----
        seterrrecipename("")
        seterrdisplayname("")
        seterrminimum_quantity("")
        seterravailablefrom("")
        setErrProductType("")
        seterrProductCode("")
        seterrdescription("")
        inputs.files = files;
        inputs.stocks = stocks;
        inputs.companyId = companyId;

        var errCount = 0;

        if (inputs.basketName.length < 3 || inputs.basketName.length > 40) {
            seterrrecipename("* basket name must be 3 to 40 in length! ")
            errCount++;
        }

        if (inputs.displayName.length < 3 || inputs.displayName.length > 40) {
            seterrdisplayname("* display name must be 3 to 40 in length! ")
            errCount++;
        }

        if (inputs.minimumInvestment === null || inputs.minimumInvestment < 1) {
            seterrminimum_quantity("* minimum quantity 1 ")
            errCount++;
        }
        if (inputs.availableFrom == null || inputs.availableFrom == "") {
            seterravailablefrom("* enter date! ")
            errCount++;
        }
        
        if (inputs.productType === "-" || inputs.productType === "") {
            setErrProductType("* select product Type! ")
            errCount++;
        }

        if (inputs.productCode.length < 2 || inputs.productCode.length > 5) {
            seterrProductCode("* Product Code, must be 2 to 5 in length! ")
            errCount++;
        }

        if (inputs.description.length < 10 || inputs.description.length > 900) {
            seterrdescription("* description must be 10 to 900 in length! ")
            errCount++;
        }
        // validation end
        console.log(inputs)
        if (errCount === 0) {
            //inputs.stocks=JSON.stringify(stocks)
            console.log(inputs)
            try {
                const res = await axios.post(config.BL_API_URL+"/basket/v1/addBasket", inputs)
                //console.log(res.data)
                if (res.status == 200 || res.data == "Success" || res.data == 200)
                    navigate("/dashboard")
                else
                    setErr("backend error")

            } catch (err) {
                if (err.response)
                    setErr(err.response.data);
                else
                    setErr("Server error...");
                console.log("error")
                //console.log(error.response.data)
            }
        }

    }

    // upload file using api
    const upload = async () => {
        try {
            const formData = new FormData();
            formData.append("file", file);
            const res = await axios.post(config.BL_API_URL+"/basket/v1/upload", formData);
            return res.data;
        } catch (err) {
            console.log(err);
        }
    };

    // handle click on Upload Image 
    const handleClick = async (e) => {
        e.preventDefault();
        let imgUrl = "";
        if (file) imgUrl = await upload();
        imgUrl.replace("@", "a");
        //console.log("img " + imgUrl)
        let flist = files
        if (flist == "")
            flist = imgUrl
        else
            flist += "@" + imgUrl
        setFiles(flist)
        console.log("files ", files)
        // mutation.mutate({ img: imgUrl });        
        setFile(null);
    };

    const handleImageDelete = (index, image) => {

        var filelist = files
        var newlist = []
        newlist = filelist.split("@")
        newlist.splice(index, 1)

        setFiles(newlist.join("@"))

        //console.log(image, index, newlist)
    }

    const toggleStocksModal = () => {
        setStocksModel(!stocksModel)
    }

    return (
        <div>
            <div className="my-24 md:px-14 max-w-screen-2xl mx-auto ">

                <div className="max-w-4xl px-4 sm:px-6 lg:px-8 lg:py-2 mx-auto">

                    <div className="bg-formbackground rounded-xl shadow p-4 sm:p-7 ">
                        <div className="mb-8">
                            <h2 className="text-xl font-bold text-gray-800 ">
                                Add New Basket
                            </h2>
                        </div>

                        <form>
                            <div className="grid sm:grid-cols-12 gap-2 sm:gap-6">

                                {/* Recipe Name ---- */}
                                <div className="sm:col-span-2">
                                    <label htmlFor="af-account-full-name" className="inline-block text-sm text-gray-800 mt-2.5 ">
                                        <span className={`${errrecipename ? "text-red-500 " : ""}`}>Basket Name </span>
                                    </label>
                                </div>
                                <div className="sm:col-span-4">
                                    <div className="sm:flex">
                                        <input
                                            onChange={handleChange}
                                            type="text" name="basketName" id="basketName" placeholder=""
                                            className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " />
                                    </div>
                                </div>
                                {/* Display Name ---- */}
                                <div className="sm:col-span-2">
                                    <label htmlFor="af-account-full-name" className="inline-block text-sm text-gray-800 mt-2.5 ">
                                        <span className={`${errdisplayname ? "text-red-500 " : ""}`}>Display Name</span>
                                    </label>
                                </div>
                                <div className="sm:col-span-4">
                                    <div className="sm:flex">
                                        <input
                                            onChange={handleChange}
                                            type="text" name="displayName" id="displayName" placeholder=""
                                            className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " />
                                    </div>
                                </div>


                                {/* Description ---- */}
                                <div className="sm:col-span-2">
                                    <label htmlFor="af-account-full-name" className="inline-block text-sm text-gray-800 mt-2.5 ">
                                        <span className={`${errminimum_quantity ? "text-red-500 " : ""}`}>Min. Investment</span>
                                    </label>
                                </div>
                                <div className="sm:col-span-4">
                                    <div className="sm:flex">
                                        <input
                                            onChange={handleChange}
                                            type="number" name="minimumInvestment" id="minimumInvestment" placeholder=""
                                            className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " />
                                    </div>
                                </div>
                                {/* Available from ---- */}
                                <div className="sm:col-span-2">
                                    <label htmlFor="af-account-full-name" className="inline-block text-sm text-gray-800 mt-2.5 ">
                                        <span className={`${erravailablefrom ? "text-red-500 " : ""}`}>Available from</span>
                                    </label>
                                </div>
                                <div className="sm:col-span-4">
                                    <div className="sm:flex">
                                        <input
                                            onChange={handleChange}
                                            type="date" name="availableFrom" id="availableFrom" placeholder=""
                                            className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " />
                                    </div>
                                </div>


                                {/* Category ---- */}
                                <div className="sm:col-span-2">
                                    <label htmlFor="productType" className="inline-block text-sm text-gray-800 mt-2.5 ">
                                        <span className={`${errProductType ? "text-red-500 " : ""}`}>Product Type</span>
                                    </label>
                                </div>
                                <div className="sm:col-span-4 min-w-full">

                                    <select
                                        onChange={handleChange}
                                        name="productType" id="productTypeId"
                                        className="py-2 px-3 pe-9 block min-w-full w-full sm:w-auto border-gray-200 shadow-sm -mt-px -ms-px rounded-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none ">
                                        <option value="-">- Select -</option>
                                        {productTypes && (
                                            productTypes.map((cat, index) => <option key={index} value={cat.id}>{cat.name}</option>)
                                        )}
                                    </select>

                                </div>

                                {/* Who can cook ? ---- */}
                                <div className="sm:col-span-2">
                                    <label htmlFor="productCode" className="inline-block text-sm text-gray-800 mt-2.5 ">
                                        <span className={`${errProductCode ? "text-red-500 " : ""}`}> Product Code</span>
                                    </label>
                                </div>
                                <div className="sm:col-span-4">
                                    <div className="sm:flex">
                                        <input
                                            onChange={handleChange}
                                            type="text" name="productCode" id="productCode" placeholder=""
                                            className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none textTransform: uppercase" />
                                    </div>
                                </div>



                                {/* Description ---- */}
                                <div className="sm:col-span-2">
                                    <label htmlFor="af-account-bio" className="inline-block text-sm text-gray-800 mt-2.5">
                                        <span className={`${errdescription ? "text-red-500 " : ""}`}>Description</span>
                                    </label>
                                </div>
                                <div className="sm:col-span-10">
                                    <textarea
                                        onChange={handleChange}
                                        name="description" id="description" placeholder="Enter Description ..." rows="4"
                                        className="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " ></textarea>
                                </div>

                                {/* Files ---- */}
                                <div className="sm:col-span-2">
                                    <label className="inline-block text-sm text-gray-800 mt-2.5 ">
                                        Files
                                    </label>
                                </div>
                                <div className="sm:col-span-8">
                                    <div className="flex items-center gap-5">

                                        <div className="flex gap-x-2">
                                            <div>
                                                <button
                                                    onClick={handleClick}
                                                    type="button" className="mr-2 py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none ">
                                                    <svg className="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" /><polyline points="17 8 12 3 7 8" /><line x1="12" x2="12" y1="3" y2="15" /></svg>
                                                    Upload fact sheets
                                                </button>
                                                <input
                                                    type="file"
                                                    id="file"
                                                    name="file"

                                                    onChange={(e) => setFile(e.target.files[0])}
                                                />


                                            </div>

                                        </div>

                                    </div>
                                    <div className="flex flex-col my-2">
                                        {files &&
                                            files.split("@").map((image, index) => (
                                                <span key={index} className="text-blue-500 flex items-center gap-2">
                                                    <FaRegFile />{image.slice(13)}
                                                    {/*<img src={`http://localhost:3000/upload/${image}`} className="w-[50px] max-h-[50px] border absolute" />*/}
                                                    <p onClick={e => handleImageDelete(index, image)} className=" ml-2 mt-2 font-bold text-red-500 cursor-pointer bg-white"><FaRegTrashAlt /></p>

                                                </span>
                                            ))
                                        }
                                    </div>

                                </div>
{/*                                 <div className="sm:col-span-2">
                                    <span type="button" onClick={toggleStocksModal} className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-gray-600 text-white cursor-pointer hover:bg-gray-700 disabled:opacity-50 disabled:pointer-events-none ">
                                        BasketStocks
                                    </span>
                                </div> */}
                            </div>


                            <div className="flex justify-end gap-x-2 mt-2">

                                <Link to="/dashboard" type="button" className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none ">
                                    Cancel
                                </Link>
                                <button type="button" onClick={handleAddBasket} className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none ">
                                    Save changes
                                </button>
                            </div>
                            <p className="text-red-400 mt-8">
                                {errrecipename}
                                {errdisplayname}
                                {errminimum_quantity}
                                {erravailablefrom}
                                {errProductType}
                                {errProductCode}
                                {errdescription}
                                {err}
                            </p>
                        </form>
                    </div>
                </div>
            </div>


            {/* Ingredients Model */}
            {stocksModel &&

                <AddStocks
                    toggleStocksModal={toggleStocksModal}
                    stocks={stocks}
                    setStocks={setStocks}
                    inputs={inputs} />

            }
        </div>

    )
}

export default AddBasket