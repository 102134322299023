import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import { useSelector } from "react-redux";
import config from '../../config';
import { useNavigate } from "react-router-dom";
import { IoWarningOutline } from "react-icons/io5";

const AddRole = () => {
    const navigate = useNavigate();
    const companyId = useSelector((state) => state.user.companyId);

    const [err, setErr] = useState("")

    const [inputs, setInputs] = useState({
        name: ""
    })

    useEffect(() => {
    }, [])
    

    const handleSave = async (e) => {
        e.preventDefault();
        setErr("");
        var errCount = 0;
        try {
            //const jsonString = JSON.stringify(inputs);
            if (inputs.name === "") {
                setErr("Role Name is required");
                errCount++;
            }

            if (errCount === 0) {

                const res = await axios.post(config.BL_API_URL + "/role/v1/addRole", inputs);


                if (res.status === 200) {
                    navigate("/roles");
                    //window.location.href = "/cashregister";
                }
                else
                    setErr(res.data.message || "backend error");
            }else{
                return;
            }

        } catch (err) {
            //console.log("first")
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }
    }

    const handleChange = (e) => {
        setInputs(prev => ({ ...prev, [e.target.name]: e.target.value }))
        console.log(inputs)
    }

    return (
        <div>
            <div className="my-14 md:px-14 max-w-screen-2xl mx-auto ">
                <div className="max-w-4xl px-4 sm:px-6 lg:px-8 lg:py-2 mx-auto">
                    <div className="bg-formbackground rounded-xl shadow p-4 sm:p-7 ">
                        <div className="mb-8">
                            <h2 className="text-xl font-bold text-gray-800 ">
                                Add New Role
                            </h2>
                        </div>
                        <form>
                            <div className="grid sm:grid-cols-12 gap-2 sm:gap-6">
                                <div className="sm:col-span-2">
                                    <label htmlFor="name" className="inline-block text-sm text-gray-800 mt-2.5 ">
                                        <span >Role Name </span>
                                    </label>
                                </div>
                                <div className="sm:col-span-4">
                                    <div className="sm:flex">
                                        <input
                                            onChange={handleChange}
                                            name="name" id="nameId"
                                            className="py-2 px-3 w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " ></input>
                                    </div>
                                </div>




                            </div>


                            <div className="flex justify-end gap-x-2 mt-2">

                                <Link to="/Roles" type="button" className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none ">
                                    Cancel
                                </Link>
                                <button type="button" onClick={handleSave} className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none ">
                                    Save changes
                                </button>
                            </div>
                            <p className="text-red-400 mt-8">
                                {err}
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default AddRole;