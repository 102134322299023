import React from 'react';
import axios from "axios";
import { useEffect, useState } from "react";
import config from "../../config";
import { useSelector } from "react-redux";
import loadingImg from "../../assets/loading.gif"

const ClientImport = () => {
    const companyId = useSelector((state) => state.user.companyId);
    const [baskets, setBaskets] = useState([]);
    const [err, setErr] = useState("");
    const [errSuccess, setErrSuccess] = useState("");
    const [errCash, setErrCash] = useState("");
    const [errCashSuccess, setErrCashSuccess] = useState("");
    const [errClosedTransactions, setErrClosedTransactions] = useState("")
    const [errClosedTransactionsSuccess, setErrClosedTransactionsSuccess] = useState("")
    const [file, setFile] = useState(null);
    const [cashFile, setCashFile] = useState(null);
    const [closedTransactionsFile, setClosedTransactionsFile] = useState(null);
    const [basketName, setBasketName] = useState("");
    const [inputs, setInputs] = useState({ basketName: "", basketId: "" });
    const [basketId, setBasketId] = useState(0);
    const [isloading, setIsloading] = useState(false)
    const [isCashLoading, setIsCashLoading] = useState(false)
    const [isClosedTransactionLoading, setIsClosedTransactionLoading] = useState(false);

    const getBaskets = async (e) => {

        try {


            //const res = await axios.post(config.BL_API_URL+"/api/baskets/getBaskets", inputs)
            //const res = await axios.get(config.BL_API_URL+"/basket/v1");
            const res = await axios.get(config.BL_API_URL + "/basket/v1/getBasketByCompanyId/" + companyId);
            //console.log(res.data)

            if (res.data)
                setBaskets(res.data)
            else
                setErr("backend error")

        } catch (err) {
            //console.log("first")
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }
    }
    useEffect(() => {
        getBaskets(companyId)
    }, [companyId])

    const upload = async () => {

        setErrSuccess("");
        setErr("");
        setIsloading(true)
        const formData = new FormData();


        formData.append("basketId", Number(basketId));
        formData.append("file", file);

        axios.post(config.BL_API_URL + "/upload", formData).then((res) => {
            setIsloading(false);
            console.log(res.data)
            setErrSuccess(res.data);
        }).catch((err) => {
            setIsloading(false);
            setErr("Error uploading file " + err);
        });



    };
    const cashUpload = async () => {

        setErrCashSuccess("");
        setErrCash("");
        setIsCashLoading(true)
        const formData = new FormData();


        formData.append("basketId", Number(basketId));
        formData.append("file", cashFile);

        axios.post(config.BL_API_URL + "/cashupload", formData).then((res) => {
            setIsCashLoading(false);

            setErrSuccess(res.data);
        }).catch((err) => {
            setIsloading(false);
            setErrCash("Error uploading file " + err);
        });



    };
    const handleCashFileSelection = (e) => {
        const cashFile = e.target.files[0];

        setCashFile(cashFile);
    };

    const handleClosedTransactionsFile = (e) => {
        const closedTransactionsFile = e.target.files[0];
        setClosedTransactionsFile(closedTransactionsFile);
    }

    const handleFileSelection = (e) => {
        const file = e.target.files[0];

        setFile(file);
    };
    const handleChange = (e) => {
        if (e.target.id === 'basketId') {

            setBasketId(e.target.value)
        }
        setInputs(prev => ({ ...prev, [e.target.name]: e.target.value, [e.target.id]: e.target.value }))
    }

    const uploadClosedTransactionsFile = (e) => {
        setErrClosedTransactions("");
        setErrClosedTransactionsSuccess("");
        setIsClosedTransactionLoading(true)
        const formData = new FormData();


        formData.append("basketId", Number(basketId));
        formData.append("file", closedTransactionsFile);

        axios.post(config.BL_API_URL + "/closedTransactionsUpload", formData).then((res) => {
            setIsClosedTransactionLoading(false);

            setErrClosedTransactionsSuccess(res.data);
        }).catch((err) => {
            setIsClosedTransactionLoading(false);
            setErrClosedTransactions("Error uploading file " + err);
        });
    }

    return (
        <div>
            <div className="my-14 md:px-14 p-2 max-w-screen-xl mx-auto ">
                <div className="flex flex-col">
                    <div className="-m-1.5 overflow-x-auto">
                        <div className="border rounded-lg divide-y divide-gray-200 ">
                            <div className='col-span-2'>
                                <div className=" col-span-2">
                                    <div className="py-1 px-4 flex justify-center items-center">
                                        <span className="text-gray-500 text-2xl font-bold">Client Stock Holding & Cash Import</span>
                                    </div>
                                    <div className="overflow-hidden">
                                        <div className="overflow-hidden">
                                            <table className="min-w-full divide-y divide-gray-200 justify-center  ">
                                                <thead className="bg-gray-50 ">
                                                    <tr>
                                                        <th scope="col" className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase">Basket Name</th>
                                                        <th scope="col" className="px-6 py-2 text-start text-xs font-medium text-gray-500 uppercase">Actione</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white divide-y divide-gray-200">
                                                    <tr>
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <select
                                                                onChange={handleChange}
                                                                name="basketName" id="basketId"
                                                                className="py-2 px-3 pe-9 block min-w-full w-full sm:w-auto border-gray-200 shadow-sm -mt-px -ms-px rounded-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none ">
                                                                <option value="-">- Select -</option>
                                                                {baskets && (
                                                                    baskets.map((basket, index) => <option key={basket.id} value={basket.basketId}>{basket.name}</option>)
                                                                )}
                                                            </select>

                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="text-sm text-gray-900">
                                                                <div className='row-span-1 flex justify-left py-1 '>
                                                                    <div className='col-span-1'>
                                                                        <input type="file" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onChange={e => handleFileSelection(e)} />
                                                                    </div>
                                                                </div>

                                                                <div className='row-span-1 flex justify-left py-1'>
                                                                    <div className='col-span-1'>
                                                                        <button className="bg-blue-500 hover:bg-blue-700 text-white  font-bold py-2 px-4 rounded" onClick={e => upload(e)} >Stock Holding Upload
                                                                            <span className="flex gap-2 items-center justify-left">
                                                                                {isloading &&
                                                                                    <img src={loadingImg} alt="Icon" className="w-6 text-left" />
                                                                                }
                                                                            </span>
                                                                        </button>

                                                                        <p className="text-red-400 mt-8 whitespace-pre-wrap">
                                                                            {err}
                                                                        </p>
                                                                        <p className="text-black-400 mt-8 whitespace-pre-wrap" >
                                                                            {errSuccess}
                                                                        </p>


                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            {/* <select
                                                                onChange={handleChange}
                                                                name="basketName" id="basketId"
                                                                className="py-2 px-3 pe-9 block min-w-full w-full sm:w-auto border-gray-200 shadow-sm -mt-px -ms-px rounded-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none ">
                                                                <option value="-">- Select -</option>
                                                                {baskets && (
                                                                    baskets.map((basket, index) => <option key={basket.id} value={basket.basketId}>{basket.name}</option>)
                                                                )}
                                                            </select>
 */}
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="text-sm text-gray-900">
                                                                <div className='row-span-1 flex justify-left py-1 '>
                                                                    <div className='col-span-1'>
                                                                        <input type="file" name="cashFile" id="cashFile" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onChange={e => handleCashFileSelection(e)} />
                                                                    </div>
                                                                </div>

                                                                <div className='row-span-1 flex justify-left py-1'>
                                                                    <div className='col-span-1'>
                                                                        <button className="bg-blue-500 hover:bg-blue-700 text-white  font-bold py-2 px-4 rounded" onClick={e => cashUpload(e)} >Client & Cash Upload
                                                                            <span className="flex gap-2 items-center justify-left">
                                                                                {isCashLoading &&
                                                                                    <img src={loadingImg} alt="Icon" className="w-6 text-left" />
                                                                                }
                                                                            </span>
                                                                        </button>

                                                                        <p className="text-red-400 mt-8">
                                                                            {errCash}
                                                                        </p>
                                                                        <p className="text-black-400 mt-8">
                                                                            {errCashSuccess}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className='row-span-1 flex justify-left py-1 '>
                                                                    <div className='col-span-1'>
                                                                        <input type="file" name="closedTransactionsFile" id="closedTransactionsFile" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onChange={e => handleClosedTransactionsFile(e)} />
                                                                    </div>
                                                                </div>
                                                                <div className='row-span-1 flex justify-left py-1'>
                                                                    <div className='col-span-1'>
                                                                        <button className="bg-blue-500 hover:bg-blue-700 text-white  font-bold py-2 px-4 rounded" onClick={e => uploadClosedTransactionsFile(e)} >Closed Transactions Upload
                                                                            <span className="flex gap-2 items-center justify-left">
                                                                                {isCashLoading &&
                                                                                    <img src={loadingImg} alt="Icon" className="w-6 text-left" />
                                                                                }
                                                                            </span>
                                                                        </button>
                                                                        <p className="text-red-400 mt-8">
                                                                            {errClosedTransactions}
                                                                        </p>
                                                                        <p className="text-black-400 mt-8">
                                                                            {errClosedTransactionsSuccess}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ClientImport;