import React from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaTrash } from "react-icons/fa";
import config from '../../config';

const CorporateActions = () => {
    const companyId = useSelector((state) => state.user.companyId);

    const [cashLedgerList, setCashLedgerList] = useState([])
    const [err, setErr] = useState("")

    const email = useSelector((state) => state.user.email);
    const navigate = useNavigate();
    useEffect(() => {
        if (email === null || email === "") {
            navigate("/")
        }
    })

    useEffect(() => {

        getCashLedger();
    }, [])

    const getCashLedger = async (e) => {
        try {
            const res = await axios.get(config.BL_API_URL + "/corporateAction/v1/getAllCorporateActions");

            if (res.data)
                setCashLedgerList(res.data)
            else
                setErr("backend error")

        } catch (err) {
            //console.log("first")
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }
    }

    const deleteCashLedger = async (id) => {
        try {
            let ledgerId = Number(id);
            const res = await axios.get(config.BL_API_URL + "/CashLedger/v1/deleteById", { params: { ledgerId } });

            if (res.status === 200)
                getCashLedger();
            else
                setErr("backend error")

        } catch (err) {
            //console.log("first")
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }
    }

    function formatNumber(value) {
        return new Intl.NumberFormat('en-IN').format(value);
    }

    function formatDate(value) {
        if (value == null || value == undefined || value == "") {
            return;
        }
        return new Intl.DateTimeFormat('en-IN').format(value);
    }

    return (
        <div className="my-24 md:px-14 p-4 max-w-screen-2xl mx-auto ">
            <div className="flex flex-col">
                <div className="-m-1.5 overflow-x-auto">

                    <div className="border rounded-lg divide-y divide-gray-200 ">
                        <div className='col-span-2'>
                            <div className=" col-span-1">
                                <div className="py-3 px-4 flex justify-center items-center">
                                    <span className="text-gray-500 text-2xl font-bold">Corporate Actions</span>
                                </div>
                                <div className='col-span-1 flex justify-end items-right gap-x-2 px-4 py-3'>
                                    <Link to="/addCorporateAction" type="button" className="py-2 px-4 inline-flex right-0 gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-btnSecondary text-white hover:bg-btnPrimary disabled:opacity-50 disabled:pointer-events-none " data-hs-overlay="#hs-basic-modal">Add Corporate Action</Link>
                                </div>
                            </div>
                        </div>
                        <div className="overflow-hidden">
                            <div className="overflow-hidden">
                                <table className="min-w-full divide-y divide-gray-200  ">
                                    <thead className="bg-gray-50 ">
                                        <tr>
                                            <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase">Scrip Name</th>
                                            <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase">Record Date</th>
                                            <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase">Ex Date</th>
                                            <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase">Corporate Action Type</th>
                                            <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase">Quantity / Ratio</th>
                                            <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase">Comment</th>
                                            <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cashLedgerList &&

                                            cashLedgerList.map((cashLedger) => (
                                                <tr>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm  text-gray-800 ">{cashLedger.scripName}</td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm  text-gray-800 ">{cashLedger.recordDate}</td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm  text-gray-800 ">{cashLedger.exDate}</td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm  text-gray-800 ">{cashLedger.corporateActionType}</td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm  text-gray-800 ">{cashLedger.quantity}</td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm  text-gray-800 ">{cashLedger.comment}</td>                                                    
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm  text-gray-800 "><span onClick={() => {
                                                        deleteCashLedger(cashLedger.ledgerId);
                                                    }} ><Link><FaTrash /></Link></span>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CorporateActions;