import BasketStocksPerformance from "../components/Reports/BasketStocksPerformance";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Reports = () => {

  const email1 = useSelector((state) => state.user.email);
  const navigate = useNavigate();
  /* useEffect(() => {
    if (email1 === null || email1 === "") {
      navigate("/")
    }
  }) */


  const [recipes, setRecipes] = useState([])
  const [err, setErr] = useState("")
  const [inputs, setInputs] = useState({
    email: email1,
  })

  // get recipes list --
/*   useEffect(() => {
    getRecipes()
  }, [])
 */
  // get recipes list function
  const getRecipes = async (e) => {

    try {
      const res = await axios.post("http://localhost:8080/api/recipes/getrecipes", inputs)
      //console.log(res.data)

      if (res.data)
        setRecipes(res.data)
      else
        setErr("backend error")

    } catch (err) {
      console.log("error 333 ", err)
      if (err.response.status === 500)
        setErr("Server error...");
      else
        setErr(err.response.data);
    }
  }

  return (
    <div className=" my-24 md:px-14 max-w-screen-2xl mx-auto ">
      <span className="mb-4">Baket Stock Performance Reports</span>
      <div className="mx-24">
        {recipes.map((recipe) => (
          {/* <BasketStocksPerformance key={recipe.recipe_id} recipe={recipe} /> */}
        ))}
      </div>
      {err}
    </div>
  )
}

export default Reports