import React from 'react';
import axios from "axios";
import { useEffect, useState } from "react";
import config from "../../config";
import { useSelector } from "react-redux";
import loadingImg from "../../assets/loading.gif"

const GenerateClientOrders = (basketId) => {
    const companyId = useSelector((state) => state.user.companyId);
    const [baskets, setBaskets] = useState([]);
    const [err, setErr] = useState("");
    const [isLoading, setIsloading] = useState(false)
    const [isRebalanceLoading, setIsRebalanceLoading] = useState(false);
    const getBaskets = async (e) => {

        try {


            //const res = await axios.post(config.BL_API_URL+"/api/baskets/getBaskets", inputs)
            //const res = await axios.get(config.BL_API_URL+"/basket/v1");
            const res = await axios.get(config.BL_API_URL + "/basket/v1/getBasketByCompanyId/" + companyId);
            //console.log(res.data)

            if (res.data)
                setBaskets(res.data)
            else
                setErr("backend error")

        } catch (err) {
            //console.log("first")
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }
    }

    useEffect(() => {
        getBaskets(companyId)
    }, [companyId])

    const generateClientOrders = async (basketId) => {
        try {
            setIsloading(true)
            
            const res = await axios.post(config.BL_API_URL + "/Processes/v1/generateOrders?basketId=" + basketId);
            //const res = await axios.post(config.BL_API_URL + "/Processes/v1/deployCash?basketId=" + basketId);
            if (res.status === 200) {
                setIsloading(false)
                alert("Client Orders Generated Successfully")
            }
        } catch (err) {
            setIsloading(false)
            console.log(err)
        }
    }
    const generateRebalanceClientOrders = async (basketId) => {
        try {
            setIsRebalanceLoading(true)
            //const res = await axios.post(config.BL_API_URL + "/Processes/v1/clientHoldingRebalance?basketId=" + basketId);
            const res = await axios.post(config.BL_API_URL + "/Processes/v1/deployCash?basketId=" + basketId);
            if (res.status === 200) {
                setIsRebalanceLoading(false)
                alert("Client Orders Generated Successfully")
            }
        } catch (err) {
            setIsRebalanceLoading(false)
            console.log(err)
        }
    }

    const deployCashClientSpecific = async (basketId) => {
        try {
            setIsRebalanceLoading(true)
            //const res = await axios.post(config.BL_API_URL + "/Processes/v1/clientHoldingRebalance?basketId=" + basketId);
            const res = await axios.post(config.BL_API_URL + "/Processes/v1/deployCashClientSpecific?basketId=" + basketId);
            if (res.status === 200) {
                setIsRebalanceLoading(false)
                alert("Client Orders Generated Successfully")
            }
        } catch (err) {
            setIsRebalanceLoading(false)
            console.log(err)
        }
    }

    return (
        <div>
            <div className="my-14 md:px-14 p-2 max-w-screen-xl mx-auto ">
                <div className="flex flex-col">
                    <div className="-m-1.5 overflow-x-auto">
                        <div className="border rounded-lg divide-y divide-gray-200 ">
                            <div className='col-span-2'>
                                <div className=" col-span-2">
                                    <div className="py-1 px-4 flex justify-center items-center">
                                        <span className="text-gray-500 text-2xl font-bold">Generate Client Orders</span>
                                    </div>
                                    <div className="overflow-hidden">
                                        <div className="overflow-hidden">
                                            <table className="min-w-full divide-y divide-gray-200 justify-center  ">
                                                <thead className="bg-gray-50 ">
                                                    <tr>
                                                        <th scope="col" className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase">Basket Name</th>
                                                        <th scope="col" className="px-6 py-2 text-start text-xs font-medium text-gray-500 uppercase">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white divide-y divide-gray-200">
                                                    {baskets &&
                                                        baskets.map((basket) => (
                                                            <tr>
                                                                <td className="px-6 py-4 whitespace-nowrap">
                                                                    <div className="text-sm text-gray-900">{basket.displayName}</div>
                                                                </td>
                                                                <td className="px-6 py-4 whitespace-nowrap">
                                                                    <div className="text-sm text-gray-900">
                                                                        <div className='row-span-1 flex justify-left py-1 '>
                                                                            <div className='col-span-1'>
                                                                                <button className="bg-blue-500 hover:bg-blue-700 text-white  font-bold py-2 px-4 rounded" onClick={e => generateClientOrders(basket.basketId)} >
                                                                                    {isLoading &&
                                                                                        <img src={loadingImg} alt="Icon" className="w-6 text-left justify-start items-right" />
                                                                                    }
                                                                                    <span>9:30 AM</span> </button>
                                                                                <p>Basket Rebalance as per RIA instruction</p>
                                                                            </div>
                                                                        </div>

                                                                        <div className='row-span-1 flex justify-left py-1'>
                                                                            <div className='col-span-1'>
                                                                                <button className="bg-blue-500 hover:bg-blue-700 text-white  font-bold py-2 px-4 rounded" onClick={e => generateRebalanceClientOrders(basket.basketId)} >
                                                                                    {isRebalanceLoading &&
                                                                                        <img src={loadingImg} alt="Icon" className="w-6 text-left justify-start items-right" />
                                                                                    }
                                                                                    2:30 PM
                                                                                </button>
                                                                                <p>Deploy remaining cash, buy orders only!</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='row-span-1 flex justify-left py-1'>
                                                                            <div className='col-span-1'>
                                                                                <button className="bg-blue-500 hover:bg-blue-700 text-white  font-bold py-2 px-4 rounded" onClick={e => deployCashClientSpecific(basket.basketId)} >
                                                                                    {isRebalanceLoading &&
                                                                                        <img src={loadingImg} alt="Icon" className="w-6 text-left justify-start items-right" />
                                                                                    }
                                                                                    2:30 PM
                                                                                </button>
                                                                                <p>Deploy remaining cash for selected clients, buy orders only!</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default GenerateClientOrders;