import StockEdit1 from "../components/StockEdit/StockEdit";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const StockEdit = () => {

    const name = useSelector((state) => state.user.name);
    const navigate = useNavigate();
    const [enabled, setEnabled] = useState(false)

    useEffect(() => {
        if (name === null || name === "") {
            navigate("/")
        }
    })




    return (
        <div className=" my-0 md:px-0 max-w-screen-xl mx-auto h-50 ">
            <div className="py-14 px-1 flex justify-center items-center">
                <span className="text-gray-500 text-xl font-bold">Stock Price Edit For test purpose only!</span>
            </div>
            <div className="my-0 md:px-0 mx-auto ">
                {/* <div className="relative flex flex-col items-center justify-center min-h-screen overflow-hidden"> */}
                {/* <div className="relative flex flex-col items-center justify-center min-h-screen overflow-hidden"> */}
                {/* <label visible="false" class="inline-flex relative items-left mr-0 cursor-pointer">
                    <input
                        type="checkbox"
                        className="sr-only peer"
                        checked={enabled}
                        readOnly
                    />
                    <div
                        onClick={() => {
                            setEnabled(!enabled);
                        }}
                        className="w-11 h-6 bg-gray-200 rounded-full peer  
                        peer-focus:ring-green-300  
                        peer-checked:after:translate-x-full 
                        peer-checked:after:border-white after:content-[''] 
                        after:absolute after:top-0.5 after:left-[2px] after:bg-white 
                        after:border-gray-300 after:border after:rounded-full 
                        after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"
                    />
                    <span className="ml-2 text-sm font-medium text-gray-900">
                        Auto Update Price ON
                    </span>
                </label> */}
                {/* </div> */}
                {/* </div> */}

            </div>
            <StockEdit1 />
        </div>
    )
}

export default StockEdit

