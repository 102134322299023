import BasketSubscriber from "../components/BasketSubscriberReport/BasketSubscriber";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import config from "../config";

const BasketSubscriberReport = () => {

    const email1 = useSelector((state) => state.user.email);
    const navigate = useNavigate();
    useEffect(() => {
        if (email1 === null || email1 === "") {
            navigate("/")
        }
    })




    return (
        <div className=" my-12 md:px-1 max-w-screen-2xl mx-auto ">
            <div className=" my-0 md:px-0 max-w-screen-xl mx-auto ">
                <div className="py-1 px-1 flex justify-center items-center">
                    <span className="mb-0 ml-4 text-2xl">Basket Details</span>
                </div>
            </div>
            <BasketSubscriber />
        </div>
    )
}

export default BasketSubscriberReport

