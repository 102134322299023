import React from 'react';
import axios from "axios";
import { useEffect, useState } from "react";
import config from "../../config";
import { useSelector } from "react-redux";
import loadingImg from "../../assets/loading.gif"

const EodProcess = (basketId) => {
    const companyId = useSelector((state) => state.user.companyId);
    const [err, setErr] = useState("");
    const [isloading, setIsloading] = useState(false)
    const [isAdvisoryWorkflow, setIsAdvisoryWorkflow] = useState(false)
    const [isCarryForwardFailedBatches, setIsCarryForwardFailedBatches] = useState(false)
    const [numOfBrokenWorkflows, setNumOfBrokenWorkflows] = useState(0)
    const [numberOfZeroHoldingClients, setNumberOfZeroHoldingClients] = useState(0);
    const [isMarketSlippages, setIsMarketSlippages] = useState(false);
    const [numberOfNegativeBalanceClients, setNumberOfNegativeBalanceClients] = useState(0);


    useEffect(() => {
        getBrokenWorkflows();
        getZeroHoldingClients();
        getNumberOfNegativeHoldingclients();
    }, [companyId])

    const getBrokenWorkflows = async () => {
        try {
            setNumOfBrokenWorkflows(0);
            const res = await axios.get(config.BL_API_URL + "/eodProcesses/v1/getOpenSystemCharges");
       
            if (res.status === 200) {
                setNumOfBrokenWorkflows(res.data);
            }
        } catch (err) {
            setNumOfBrokenWorkflows(0);
            console.log(err)
        }
    }

    const getZeroHoldingClients = async () => {
        try {
            setNumberOfZeroHoldingClients(0);
            const res = await axios.get(config.BL_API_URL + "/eodProcesses/v1/getNumberOfZeroClients");
       
            if (res.status === 200) {
                setNumberOfZeroHoldingClients(res.data);
            }
        } catch (err) {
            setNumberOfZeroHoldingClients(0);
            console.log(err)
        }
    }

    const applyCorporateActions = async () => {
        try {
            setIsloading(true);
            const res = await axios.post(config.BL_API_URL + "/corporateAction/v1/executeCorporateAction");
            setIsloading(false);
            if (res.status === 200) {
                alert("Corporate Actions Applied Successfully")
            }
        } catch (err) {
            setIsloading(false);
            console.log(err)
        }
    }

    const applyBrokenAdvisoryChargesWorkflow = async () => {
        try {
            setIsAdvisoryWorkflow(true);
            const res = await axios.post(config.BL_API_URL + "/eodProcesses/v1/fixBrokenSystemChargesFlow");
            if (res.status === 200) {
                getBrokenWorkflows()
                setIsAdvisoryWorkflow(false);
            }
        } catch (err) {
            setIsAdvisoryWorkflow(false);
            console.log(err)
        }
    }

    const carryForwardFailedBatches = async () => {
        try {
            setIsCarryForwardFailedBatches(true);
            const res = await axios.post(config.BL_API_URL + "/eodProcesses/v1/carryForwardFailedBatches");
            if (res.status === 200) {
                //getBrokenWorkflows()
                setIsCarryForwardFailedBatches(false)
            }
        } catch (err) {
            setIsCarryForwardFailedBatches(false)
            console.log(err)
        }
    }

    const redemptiveClients = async () => {
        try {
            setIsAdvisoryWorkflow(true);
            const res = await axios.post(config.BL_API_URL + "/eodProcesses/v1/markClientExit");
            if (res.status === 200) {
                getBrokenWorkflows()
                setIsAdvisoryWorkflow(false);
            }
        } catch (err) {
            setIsAdvisoryWorkflow(false);
            console.log(err)
        }
    }

    const getNumberOfNegativeHoldingclients = async () => {
        try {
            setNumberOfNegativeBalanceClients(0);
            const res = await axios.get(config.BL_API_URL + "/eodProcesses/v1/getNumberOfNegativeHoldingclients");
       
            if (res.status === 200) {
                setNumberOfNegativeBalanceClients(res.data);
            }
        } catch (err) {
            setNumberOfNegativeBalanceClients(0);
            console.log(err)
        }
    }

    const fixMarketSlippages = async () => {
        try {

            setIsMarketSlippages(true);
            const res = await axios.post(config.BL_API_URL + "/eodProcesses/v1/fixMarketSlippages");
            if (res.status === 200) {
                getNumberOfNegativeHoldingclients();
                setIsMarketSlippages(false);
            }
        } catch (err) {
            setIsMarketSlippages(false);
            console.log(err)
        }
    }

    return (
        <div className=" my-0 md:px-20 max-w-screen-xl mx-auto h-50 ">
            <div className="py-14 px-1 flex justify-center items-center">
                <span className="text-gray-500 text-2xl font-bold">EOD - EOM Process</span>
            </div>
            <div className="grid grid-cols-2 gap-2">
                <div className="text-right justify-center">Apply Corporate Actions (Dividend) to all baskets</div>
                <div>
                    <button onClick={e => applyCorporateActions()}
                        class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
                        {isloading &&
                            <img src={loadingImg} alt="Icon" className="w-6 text-left justify-start items-right" />
                        }
                        {!isloading &&
                            <svg class="fill-current w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><polyline fill="none" points="3.7 14.3 9.6 19 20.3 5" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" /></svg>
                        }
                        <span>Apply</span>
                    </button>
                </div>
                <div className="text-right justify-center">Apply Fix to <b>{numOfBrokenWorkflows}</b> Broken Advisory Charges workflow</div>
                <div>
                    <button onClick={e => applyBrokenAdvisoryChargesWorkflow()}
                        class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
                        {isAdvisoryWorkflow &&
                            <img src={loadingImg} alt="Icon" className="w-6 text-left justify-start items-right" />
                        }
                        {!isAdvisoryWorkflow &&
                            <svg class="fill-current w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><polyline fill="none" points="3.7 14.3 9.6 19 20.3 5" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" /></svg>
                        }
                        <span>Apply</span>
                    </button>
                </div>
                <div className="text-right justify-center">Regenerate client orders in error.</div>
                <div>
                    <button onClick={e => carryForwardFailedBatches()}
                        class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
                        {isCarryForwardFailedBatches &&
                            <img src={loadingImg} alt="Icon" className="w-6 text-left justify-start items-right" />
                        }
                        {!isCarryForwardFailedBatches &&
                            <svg class="fill-current w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><polyline fill="none" points="3.7 14.3 9.6 19 20.3 5" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" /></svg>
                        }
                        <span>Apply</span>
                    </button>
                </div>
                <div className="text-right justify-center">Full withdrawn clients (<b>{numberOfZeroHoldingClients}</b>), mark exit.</div>
                <div>
                    <button onClick={e => redemptiveClients()}
                        class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
                        {isAdvisoryWorkflow &&
                            <img src={loadingImg} alt="Icon" className="w-6 text-left justify-start items-right" />
                        }
                        {!isAdvisoryWorkflow &&
                            <svg class="fill-current w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><polyline fill="none" points="3.7 14.3 9.6 19 20.3 5" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" /></svg>
                        }
                        <span>Apply</span>
                    </button>
                </div>
                <div className="text-right justify-center">Fix market slippages in (<b>{numberOfNegativeBalanceClients}</b>) number of clients.</div>
                <div>
                    <button onClick={e => fixMarketSlippages()}
                        class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
                        {isMarketSlippages &&
                            <img src={loadingImg} alt="Icon" className="w-6 text-left justify-start items-right" />
                        }
                        {!isMarketSlippages &&
                            <svg class="fill-current w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><polyline fill="none" points="3.7 14.3 9.6 19 20.3 5" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" /></svg>
                        }
                        <span>Apply</span>
                    </button>
                </div>
            </div>
        </div>

    )
}
export default EodProcess;