import Features from "../components/Features";

const Preferences = () => {
  return (
    <div>
    <Features/>
    </div>
  )
}

export default Preferences