import React from 'react';
import axios from "axios";
import { useEffect, useState } from "react";
import config from "../../config";
import { BsFiletypeCsv } from "react-icons/bs";
import { useSelector } from "react-redux";
import { set } from 'date-fns';
import { Link } from 'react-router-dom';
import { TbTableExport } from "react-icons/tb";
const ClientOrders = () => {

    const companyId = useSelector((state) => state.user.companyId);
    const [batchIds, setBatchIds] = useState([])
    const [list, setList] = useState([])
    const [filteredList, setFilteredList] = useState([])
    const [filteredFistRow, setFilteredFistRow] = useState([])
    const [selectedBatchId, setSelectedBatchId] = useState("")
    const [selectedStatus, setSelectedStatus] = useState("")

    useEffect(() => {
        const getOpenBatchIds = async (companyId) => {
            try {
                const res = await axios.get(config.BL_API_URL + "/client/v1/getOpenOrders");

                if (res.status === 200) {
                    setList(res.data);
                    const batchIds = [...new Set(res.data.map(item => item.batchId))];
                    const uniqueData = batchIds.filter((value, index, self) => self.indexOf(value) === index);
                    setBatchIds(uniqueData)
                }

            } catch (err) {
                console.log(err)
            }

        }
        setSelectedBatchId("");
        setFilteredList([]);
        setFilteredFistRow([]);
        setBatchIds([]);
        setList([]);
        getOpenBatchIds(companyId);
    }, [])

    const onSelectionChange = (e) => {

        if (e.target.value === "-") {
            return;
        }
        /*0 - open orders, 1 - rejected orders, 2 - cancelled orders, 
        * 3 - confirmed orders, 4 - partial confirmed orders, 5 - pending to confirm orders
        */
        setSelectedBatchId(e.target.value);

        if (e.target.value != "all") {
            let filteredData;
            if (e.target.value == "0") {
                filteredData = list.filter(item => item.status == "" || item.status == null || item.status == "null");
            }
            else if (e.target.value == "1") {
                filteredData = list.filter(item => item.status == "rejected");
            }
            else if (e.target.value == "2") {
                filteredData = list.filter(item => item.status == "cancel");
                console.log(filteredData);                      }
            else if(e.target.value == "3") {
                filteredData = list.filter(item => item.status == "confirm");
      
            }
            else if(e.target.value == "4") {
                filteredData = list.filter(item => item.status == "partial");
            }
            else if(e.target.value == "5") {
                filteredData = list.filter(item => item.status == "success");
            }
            else{
                setSelectedBatchId(e.target.value)
                filteredData = list.filter(item => item.batchId == e.target.value);
            }
            setFilteredList(filteredData);
            setFilteredFistRow(filteredData.slice(0, 1));
        }
        else {
            setSelectedBatchId("all")
            setFilteredList(list);
            setFilteredFistRow(list.slice(0, 1));
        }
    }

    const openReport = (batchId) => {
        try {
            const axiosConfig = {
                responseType: 'blob',
                headers: {
                    Accept: 'Application/csv'
                }
            }
            const url = `${config.BL_API_URL}/csvReport/v1/getClientOrderExport?batchId=${encodeURIComponent(batchId)}`;

            axios.get(url, axiosConfig).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${batchId}.csv`);
                document.body.appendChild(link);
                link.click();
            });

        } catch (err) {
            console.log(err)
        }
    }

    return (
        <div>
            <div className="my-14 md:px-14 p-2 max-w-screen-xl mx-auto ">
                <div className="flex flex-col">
                    <div className="-m-1.5 overflow-x-auto">

                        <div className="border rounded-lg divide-y divide-gray-200 ">
                            <div className='col-span-2'>
                                <div className=" col-span-1">
                                    <div className="py-1 px-4 flex justify-center items-center">
                                        <span className="text-gray-500 text-2xl font-bold">Export Client Orders</span>
                                    </div>
                                    <div className='row-span-1 flex justify-center content-center items-center gap-x-2 py-2 px-4'>
                                        <div className='col-span-1'>
                                            <label className='px-6 py-2 text-left font-medium' >Batch Id</label>
                                        </div>
                                        <div className='col-span-1'>
                                            <select onChange={onSelectionChange} id="batchId" name="batchId" defaultValue="-" default className='border border-gray-300 rounded-md' >
                                                <option value="-">----Select Batch Id----</option>
                                                <option value="all">----All Orders----</option>
                                                <option value="0">----All Open Orders----</option>
                                                <option value="1">----All Rejected Orders----</option>
                                                <option value="2">----All Cancelled Orders----</option>
                                                <option value="3">----All Confirmed Orders----</option>
                                                <option value="4">----All Partial Confirmed Orders----</option>
                                                <option value="5">----All Pending to Confirm Orders----</option>
                                                {batchIds &&
                                                    batchIds.map((item, index) => (

                                                        <option key={index} value={item}>{item}</option>
                                                    ))

                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="overflow-hidden">
                                <div className="overflow-hidden">
                                    <table className="min-w-full divide-y divide-gray-200  ">
                                        <thead className="bg-gray-50 ">
                                            <tr>
                                                <th scope="col" className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase">Basket Name</th>
                                                <th scope="col" className="px-6 py-2 text-start text-xs font-medium text-gray-500 uppercase">Client Code</th>
                                                <th scope="col" className="px-6 py-2 text-xs text-left font-medium text-gray-500 uppercase">Script Name</th>
                                                <th scope="col" className="px-6 py-2 text-start text-xs font-medium text-gray-500 uppercase">Quantity</th>
                                                <th scope="col" className="px-6 py-2 text-start text-xs font-medium text-gray-500 uppercase">Side</th>
                                                <th scope="col" className="px-6 py-2 text-start text-xs font-medium text-gray-500 uppercase">Status</th>
                                                <th scope="col" className="px-6 py-2 text-start text-xs font-medium text-gray-500 uppercase">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectedBatchId && filteredFistRow &&
                                                filteredFistRow.map((item, index) => (
                                                    <tr>
                                                        <td className="px-6  whitespace-nowrap text-sm  text-gray-800 ">{item.basketName}</td>
                                                        <td className="px-6  whitespace-nowrap text-sm  text-gray-800 ">{item.clientCode}</td>
                                                        <td className="px-6  whitespace-nowrap text-sm  text-gray-800 "></td>
                                                        <td className="px-6  whitespace-nowrap text-sm  text-gray-800 "></td>
                                                        <td className="px-6  whitespace-nowrap text-sm  text-gray-800 "></td>
                                                        <td className="px-6  whitespace-nowrap text-sm  text-gray-800 "></td>
                                                        <td className="px-6  whitespace-nowrap text-sm  text-gray-800 "><Link><span onClick={e => openReport(selectedBatchId)}><BsFiletypeCsv size={30} /></span></Link></td>
                                                    </tr>
                                                ))
                                            }
                                            {selectedBatchId && filteredList &&
                                                filteredList.map((item, index) => (
                                                    <tr>
                                                        <td className="px-6 py-0 whitespace-nowrap text-sm  text-gray-800 "></td>
                                                        <td className="px-6 py-0 whitespace-nowrap text-sm  text-gray-800 "></td>
                                                        <td className="px-6 py-0 whitespace-nowrap text-sm  text-gray-800 ">{item.scripName}</td>
                                                        <td className="px-6 py-0 whitespace-nowrap text-sm  text-gray-800 ">{item.quantity}</td>
                                                        <td className="px-6 py-0 whitespace-nowrap text-sm  text-gray-800 ">{item.orderType}</td>
                                                        <td className="px-6 py-0 whitespace-nowrap text-sm  text-gray-800 ">{item.status}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );

}

export default ClientOrders;