import AutoComplete from "./AutoComplete";
import React from "react";
import axios, { all } from "axios";
import { useEffect, useState } from "react";
import { FaRegTrashAlt } from "react-icons/fa";
import { FiDelete, FiEdit } from "react-icons/fi";
import { IoIosCheckboxOutline } from "react-icons/io";
import { IoSearch } from "react-icons/io5";
import { RiCheckboxBlankLine } from "react-icons/ri";
import { TbTriangle } from "react-icons/tb";
import { TbTriangleInverted } from "react-icons/tb";
import { stockItems } from "./tableItems";
import {calculateAllocation} from "../../calculations";
import { FaMessage } from "react-icons/fa6";
import config from "../../config";

const AddStocks = ({ toggleStocksModal, stocks, setStocks, currentBasketId, currentCompanyId }) => {

    const [inputs, setInputs] = useState({
        stockId: "",
        stockName: "",
        allocationPercent: "",
        newAllocation: "",
        comment: "",
        isFixed: true
    });

    const [editOn, setEditOn] = useState(false)
    const [disablePublish, setDisablePublish] = useState(false)
    const [allocationEditOn, setAllocationEditOn] = useState(false)
    const [index, setIndex] = useState(null)
    const [lists, setLists] = useState(stocks)
    
    const [basketId, setBasketId] = useState(currentBasketId)
    const [companyId, setCompanyId] = useState(currentCompanyId)
    const [totalAllocation, setTotalAllocation] = useState(0);
    
    const [stocksList, setStocksList] = useState([]);
    //if (stocks.length>0) setStocksList(stocks)

    const [err, setErr] = useState(null);

    const [errStock, setErrStock] = useState("")
    const [errquantity, setErrQuantity] = useState("")
    const [errremark, setErrRemark] = useState("")
    const [errisfixed, setErrIsfixed] = useState("")
    const [errWorkflow, setErrWorkflow] = useState("")
    useEffect(() => {
        setStocks(lists);
        updatePublishButton(lists);
    }, [lists,lists.data, stocks])
    const refLists = React.useRef(lists);
    const refBasketId = React.useRef(currentBasketId);
    const refCompanyId = React.useRef(companyId);

    // get Stocks list --
    useEffect(() => {
        const getStocksList = async (e) => {
            setErr("")
            try {
                //const res = await axios.get(config.BL_API_URL+"/basketstock/v1/getbasketstockbyid", {params : {basketId}})
                const res = await axios.get(config.BL_API_URL+"/stock/v1");
                if (res && res.data)
                    setStocksList(res.data)
                else
                    setErr("backend error while fetching stock lookup")


            } catch (err) {
                console.log("first")
                if (err.response)
                    setErr(err.response.data);
                else
                    setErr("Server error...");
            }
        }
        getStocksList();         
        setTotalAllocation(calculateAllocation(lists, inputs));
    }, [])

    const handleChange = (e) => {
        const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;

        setInputs((prev) => ({ ...prev, [e.target.name]: value }));

/*         if (e.target.name == "allocationPercent") {
            setInputs((prev) => ({ ...prev, "allocationPercent": e.target.value.slice(0, 3) })); //allocationPercent = e.target.value.slice(0, 3);
            //console.log(inputs.allocationPercent)
        } */

        if (e.target.name == "allocationPercent" || e.target.name == "newAllocation")
            setTotalAllocation(calculateAllocation(lists, inputs));
    };

    function updatePublishButton(lists) {
        setErrWorkflow("");
        let unSavedBasketStock = false;
        let publishButton = document.getElementById("publishButton");
        if((lists.data == null || lists.data.length <= 0) && lists == null)
        {
            setDisablePublish(true);
            return;
        }
        if((lists == null || lists.length <= 0))
        {
            setDisablePublish(true);
            return;
        }
        lists.forEach(element => {//if basket stock is not published
            if(element.basketStockId == "" || element.basketStockId == null)
            {
                setErrWorkflow("Basket needs to be saved first to publish the basket orders!")
                unSavedBasketStock = true;
                //return;
            }
        });
        
        if(!unSavedBasketStock)
            setDisablePublish(false);
        else
            setDisablePublish(true);

    } 

    const handleSave = async (e) => {
       /*  try {
            const res = await axios.post(config.BL_API_URL+"/Processes/v1/generateOrders");
            
            if(res.status != 200)
            {
                setErr("executed client orders with with errors");
            }
            setErr(res.data.messages);
        }
        catch (err) {
            
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server errorw while generating client orders.");
        } */
    }

    const handlePublishButton = async (e) => {
        try {
            const res = await axios.get(config.BL_API_URL+"/BasketRebalance/v1", { params: {basketId, companyId} });
            if(res.status != 200)
            {
                setErr("executed basket orders with with errors");
            }
            else{
                setErr(res.data.messages);
                updatePublishButton(lists);

            }
        }
        catch (err) {
            
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error while publishing basket");
        }

    }

    const handleAddButton = async (e) => {
        e.preventDefault();

        setErrWorkflow("")
        setErrStock("")
        setErrQuantity("")
        setErrRemark("")
        setErrIsfixed("")
        var errCount = 0;

        if (inputs.stockName.length < 3 || inputs.stockName.length > 40) {
            setErrStock("* please select stock name from the list! ")
            errCount++;
        }

        if (inputs.comment.length < 10 || inputs.comment.length > 400) {
            setErrRemark("* remarks must be 10 to 400 in length! ")
            errCount++;
        }

        if(!editOn)
        {
            if (inputs.allocationPercent === null || inputs.allocationPercent < 0 || inputs.allocationPercent > 100 ) {
                setErrQuantity("* allocation multiples of 0.1 and maximum 100 across all stocks")
                errCount++;
            }
        }

        if(editOn)
        {
            if (inputs.newAllocation === null || inputs.newAllocation < 0 || inputs.newAllocation > 100 ) {
                setErrQuantity("* allocation multiples of percent 0.1 and maximum 100 across all stocks")
                errCount++;
            }
        }

        //check if stock already presesent in list

        if (errCount === 0) {
            if(totalAllocation > 100){
                setErrRemark("* total allocation can't exceed 100%");
                return;
            }
    
            var newObj;
            if(lists.data != null)
                newObj = [...lists.data, inputs]
            else
                newObj = [...lists, inputs]

            setLists(newObj)
            setStocks(newObj)
            setInputs({
                basketStockId: "",
                stockId: "",
                stockName: "",
                allocationPercent: "",
                newAllocation: "",
                comment: "",
                isFixed: false
            })           
        }
    };    
   

    // move list up
    const moveUp = (index) => {
        var newlist = lists
        var tmpObj = lists[index - 1]
        newlist[index - 1] = lists[index]
        newlist[index] = tmpObj
        setLists([...newlist])
        setStocks([...newlist])
        console.log(newlist)
    }

    // move list down
    const moveDown = (index) => {
        var newlist = lists
        var tmpObj = lists[index + 1]
        newlist[index + 1] = lists[index]
        newlist[index] = tmpObj
        setLists([...newlist])
        setStocks([...newlist])
        console.log(newlist)
    }

    const editMode = (index) => {
        setIndex(index)
        setEditOn(true)
        inputs.allocationPercent = "";
        setTotalAllocation(calculateAllocation(lists, inputs));
        isNewStock(lists[index].stockName)
        
        //console.log(lists[index])
        setInputs({
            basketStockId: lists[index].basketStockId,
            stockId: lists[index].stockId,
            stockName: lists[index].stockName,
            allocationPercent: lists[index].allocationPercent,
            newAllocation: lists[index].newAllocation,
            comment: lists[index].comment,
            isFixed: lists[index].isFixed
        });

    }
//lists[index].newAllocation == null  ? lists[index].allocationPercent : lists[index].newAllocation
//handleAllocation(lists[index].newAllocation, lists[index].allocationPercent, lists[index].stockName)
    const handleAllocation = (newAllocation, allocation, stockName) => {
        if(newAllocation != null)
            return newAllocation;

        lists.forEach(element => {
            if(element.stockName === stockName){
                return "";
            }
        });
        //return allocation;
    }

    function isNewStock(stockName) {
        refLists.current.forEach(element => {
            if(element.stockName === stockName && element.basketStockId != null){
                setAllocationEditOn(true);
                return;
            }
        });
    }


    

    // update stocks after edit
    const handleUpdateButton = () => {
        setErrStock("")
        setErrQuantity("")
        setErrRemark("")
        setErrIsfixed("")
        var errCount = 0;

        if (inputs.stockName.length < 3 || inputs.stockName.length > 40) {
            setErrStock("* stockName must be 3 to 40 in length! ")
            errCount++;
        }

        if (inputs.comment != null && (inputs.comment.length < 10 || inputs.comment.length > 400)) {
            setErrRemark("* remarks must be 10 to 400 in length! ")
            errCount++;
        }

        if (inputs.allocationPercent === null || inputs.allocationPercent < 0 || inputs.allocationPercent > 100 ) {
            setErrQuantity("* allocation percent multiples of 0.1 and maximum 100 across all stocks")
            errCount++;
        }   
        
        if (inputs.newAllocation === null || inputs.newAllocation < 0 || inputs.newAllocation > 100 ) {
            setErrQuantity("* allocation percent multiples of 0.1 and maximum 100 across all stocks")
            errCount++;
        }  

        if (errCount === 0) {
            if(totalAllocation > 100){
                setErrRemark("* total allocation can't exceed 100%");
                return;
            }
            var newObj;
            if(lists.data == null)
                newObj = [...lists]
            else
                newObj = [...lists.data]

            newObj[index] = inputs
            setLists([...newObj])
            //console.log(newObj)
            setStocks([...newObj])
            setEditOn(false)
            setAllocationEditOn(false)
            setInputs({
                stockId:"",
                stockName: "",
                allocationPercent: "",
                newAllocation : "",
                comment: "",
                isFixed: false,
                isVisible: true
            });
            setIndex(null)
        }

    }

    return (
        
        <div id="authentication-modal" tabIndex="-1" className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full shadow-2xl ">
            <div className="relative p-4 h-[90%] w-full min-w-[350px] flex justify-center items-start  ">

                <div className="border sm:max-w-2xl max-w-[350px] mx-auto relative mt-16 bg-formbackground rounded-lg shadow-2xl ">

                    <div className=" flex items-center justify-between p-3 md:p-3 border-b rounded-t ">
                        {editOn &&
                            <h3 className="text-xl font-semibold text-gray-900 ">
                                Edit Stock
                            </h3>
                        }
                        {!editOn &&
                            <h3 className="text-xl font-semibold text-gray-900 ">
                                Add Stock
                            </h3>                
                        }
                        <br></br>
                        {allocationEditOn &&
                            <h3 className="text-xl font-semibold text-gray-900 ">
                                </h3>
                        }
                        {!allocationEditOn &&
                            <h3 className="text-xl font-semibold text-gray-900 ">
                                </h3>
                        }

                        <button onClick={toggleStocksModal} type="button" className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center " data-modal-hide="authentication-modal">
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    <div className="p-4 md:p-5">
                        <div className="space-y-4">
                            <div className="grid sm:grid-cols-12 gap-2 sm:gap-6">

                                {/* stocks ---- */}
                                <div className="sm:col-span-2">
                                    <label htmlFor="af-account-full-name" className="inline-block text-sm text-gray-800 mt-2.5 ">
                                        <span className={`${errStock ? "text-red-500 " : ""}`}>Stock </span>
                                    </label>
                                </div>
                                <div className="sm:col-span-4">
                                    <div className="sm:flex">
                                        <AutoComplete
                                            setInputs={setInputs}
                                            data={stocksList}
                                            inputs={inputs}
                                            editOn={editOn}
                                        />

                                        {/*
                                            <input
                                            onChange={handleChange}
                                            type="text" name="stockName" id="stockName" placeholder=""
                                            className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " />
                                        */}
                                    </div>
                                </div>

                                {/* Quantity ---- */}
                                <div className="sm:col-span-2">
                                    <label htmlFor="af-account-full-name" className="inline-block text-sm text-gray-800 mt-2.5 ">
                                        <span className={`${errquantity ? "text-red-500 " : ""}`}>Allocation%</span>
                                    </label>
                                </div>
                                <div className="sm:col-span-4">
                                    <div className="sm:flex">
                                        {!allocationEditOn && 
                                            <input
                                            onKeyUp={handleChange}
                                            onChange={handleChange} step="0.1" min={0.1} max={100}
                                            type="number" name="allocationPercent" id="allocationPercent" placeholder="" value={inputs.allocationPercent}
                                            className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " />
                                        }
                                        {allocationEditOn &&
                                            <input
                                            onKeyUp={handleChange}
                                            onChange={handleChange} step="0.1" min={0.1} max={100}
                                            type="number" name="newAllocation" id="newAllocation" placeholder="" value={inputs.newAllocation}
                                            className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " />

                                        }
                                    </div>
                                </div>

                                {/* Remark ---- */}
                                <div className="sm:col-span-2">
                                    <label htmlFor="af-account-full-name" className="inline-block text-sm text-gray-800 mt-2.5 ">
                                        <span className={`${errremark ? "text-red-500 " : ""}`}>Remark </span>
                                    </label>
                                </div>
                                <div className="sm:col-span-4">
                                    <div className="sm:flex">
                                        <textarea
                                            onChange={handleChange} maxLength={100}
                                            name="comment" id="comment" placeholder="" rows="1" value={inputs.comment}
                                            className="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " ></textarea>

                                    </div>
                                </div>
                                {/* Is Fixed ---- */}
                                {/*                                 <div className="sm:col-span-6  flex gap-2 justify-between items-center"> */}
                                <div className="sm:col-span-2">
                                    <label htmlFor="af-account-full-name" className="inline-block text-sm text-gray-800 mt-2.5 ">
                                        <span className={`${errisfixed ? "text-red-500 " : ""}`}>Is Fixed</span>
                                    </label>
                                </div>
                                <div className="sm:col-span-1">
                                    <input
                                        onChange={handleChange}
                                        name="isFixed" id="isFixed" type="checkbox" checked={inputs.isFixed} defaultValue={false}
                                        className="mt-1 p-2 w-8 h-8 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 " />

                                </div>

                                <div className="sm:col-span-3">
                                    {editOn &&
                                        <button onClick={handleUpdateButton} className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ">Update</button>
                                    }

                                    {!editOn &&
                                        <button onClick={handleAddButton} className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ">Add</button>
                                    }
                                </div>
                                <div className="sm:col-span-4"></div>
                                <div className="sm:col-span-6">
                                    <button onClick={handlePublishButton} disabled={disablePublish} id="publishButton" className="float-right text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2.5 text-center disabled:opacity-20">Publish (Push Basket Orders)</button>
                                </div>
                                <div className="sm:col-span-2">
                                    <button onClick={handleSave} id="publishButton" className="float-right text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center disabled:opacity-50">Save</button>
                                </div>
                            </div>



                            <div className="overflow-hidden">
                                <div className="flex flex-col">
                                    <div className="-m-1.5 overflow-x-auto">
                                        <div className="p-1.5 min-w-full inline-block align-middle">
                                            <div className="p-1.5 min-w-full inline-block align-middle">
                                            
                                            
                                            <label>Total Allocation: </label><label id="totalAllocation">{totalAllocation}<label> %</label></label>
                                            </div>
                                            <div className="border rounded-lg divide-y divide-gray-200 ">
                                                <table className="min-w-full divide-y divide-gray-200 max-h-[160px] w-full flex flex-col items-center justify-between overflow-y-scroll ">
                                                    <thead className="bg-gray-50 w-full">
                                                        <tr className="flex justify-between w-full">
                                                            {stockItems &&
                                                                stockItems.slice(0, 5).map((item, index) => (
                                                                    <th key={index} scope="col" className="px-6 py-2 text-start text-xs font-medium text-gray-500 uppercase">{item.name}</th>
                                                                ))}
                                                        </tr>
                                                    </thead>

                                                    <tbody className="divide-y divide-gray-200 w-full overflow-y-scroll">
                                                        { lists && lists.length  > 0 &&
                                                            lists.map((list, index) => (
                                                                <tr key={list.stockName} className="hover:bg-gray-100 flex items-center gap-5 justify-between w-full">
                                                                    <td className="px-6 py-2 whitespace-nowrap text-sm  text-gray-800 w-[140px] ">{list.stockName}</td>
                                                                    <td data-id={list.stockName} id={list.stockName} className="px-6 py-2 whitespace-nowrap text-sm  text-gray-800 w-[90px]">{list.allocationPercent}</td>
                                                                    <td className="px-6 py-2 whitespace-nowrap text-sm  text-gray-800 w-[90px]">{list.newAllocation}</td>
                                                                    
                                                                    <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-800 ">
                                                                        {list.isFixed ? <IoIosCheckboxOutline /> : <RiCheckboxBlankLine />}
                                                                        {/*<input

                                                                                name="isfixed11" id="isfixed11" type="checkbox" defaultChecked={lists.isFixed ? true: false}
                                                                                className="p-2 w-4 h-4 border border-gray-300 rounded bg-gray-50" />
                                                                */}
                                                                    </td>
                                                                    <td onClick={e => editMode(index)} className="px-6 py-2 whitespace-nowrap text-sm  text-btnPrimary w-[90px] text-right cursor-pointer"><FiEdit />
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                </table>

                                            </div>
                                            {/* error show */}
                                            <p className="text-red-400 mt-8">
                                                {errWorkflow}
                                                {errStock}
                                                {errquantity}
                                                {errremark}
                                                {err}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddStocks