import React from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaTrash } from "react-icons/fa";
import config from '../../config';

const Screens = () => {
    const companyId = useSelector((state) => state.user.companyId);

    const [menuList, setMenuList] = useState([])
    const [err, setErr] = useState("")
    const [baskets, setBaskets] = useState([])
    const [categories, setCategories] = useState([])
    const [inputs, setInputs] = useState({
        clientName: "",
        clientCode: "",
        categoryId: 0,
        basketId: 0,
        fromDate: null,
        toDate: null,
    })

    const email = useSelector((state) => state.user.email);
    const navigate = useNavigate();
    useEffect(() => {
        if (email === null || email === "") {
            navigate("/")
        }
    })

    useEffect(() => {
        const today = new Date();
        const dateString = today.toISOString().substring(0, 10);
        inputs.fromDate = dateString;
        handleSearch(null);
        //getCashLedger();
    }, [])


    const handleClearSearch = async (e) => {
        let clientName = document.getElementById("client-Name");
        let clientCode = document.getElementById("client-Code");
        let fromDate = document.getElementById("fromDate");
        let toDate = document.getElementById("toDate");
        let basket = document.getElementById("basketId");
        let category = document.getElementById("categoryId");
        clientName.value = ""
        clientCode.value = ""
        fromDate.value = ""
        toDate.value = ""
        basket.value = ""
        category.value = ""
        setInputs({
            clientName: "",
            clientCode: "",
            categoryId: 0,
            basketId: 0,
            fromDate: null,
            toDate: null,
        });
        setMenuList([]);
    }

    const handleSearch = async (e) => {
        try {

            const res = await axios.get(config.BL_API_URL + "/menu/v1/getMenus");

            if (res.data) {
                setMenuList(res.data);
            }
            else {
                setErr("backend error")
            }

        } catch (err) {
            //console.log("first")
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }
    }

    const deleteMenu = async (id) => {
        console.log("clicked" + id)
        try {
            let id1 = Number(id);
            console.log(id1)
            const requestBody = { id: id1 };
            const res = await axios.post(config.BL_API_URL + "/menu/v1/deleteMenu", requestBody);

            if (res.status === 200)
                handleSearch();
            else
                setErr("backend error")

        } catch (err) {
            //console.log("first")
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }
    }

    function formatNumber(value) {
        return new Intl.NumberFormat('en-IN').format(value);
    }

    function formatDate(value) {
        if (value == null || value == undefined || value == "") {
            return;
        }
        return new Intl.DateTimeFormat('en-IN').format(value);
    }
    const handleChange = (e) => {
        setInputs(prev => ({ ...prev, [e.target.name]: e.target.value }))
    }


    return (
        <div className="my-12 md:px-14 p-4 max-w-screen-md mx-auto ">
            <div className="flex flex-col">
                <div className="-m-1.5 overflow-x-auto">

                    <div className="border rounded-lg divide-y divide-gray-200 ">
                        <div className='col-span-2'>
                            <div className=" col-span-1">
                                <div className="py-3 px-4 flex justify-center items-center">
                                    <span className="text-gray-500 text-2xl font-bold">Menu Master</span>
                                </div>
                                <div className=' flex  gap-x-1 px-1 py-1 overflow-ellipsis'>
                                    <div className="grid  gap-2 justify-right border-0 border-red-500">

                                        <div className=" gap-0 justify-right text-xs " >
                                            <Link to="/AddScreen" type="button" className="py-2 px-2 inline-flex right-0 gap-x-2 text-sm text-nowrap font-semibold rounded-lg border border-transparent bg-btnSecondary text-white hover:bg-btnPrimary disabled:opacity-50 disabled:pointer-events-none " data-hs-overlay="#hs-basic-modal">Add Menu / Screen</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="overflow-hidden col-span-1">
                            <div className="overflow-hidden">
                                <table className="min-w-full divide-y divide-gray-200  ">
                                    <thead className="bg-gray-50 ">
                                        <tr>
                                            <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase">Parent Name</th>
                                            <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase">Menu Name</th>

                                            <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {menuList &&

                                            menuList.map((menu) => (
                                                <tr>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm  text-gray-800 ">{menu?.parent?.name ?? 'Root'}</td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm  text-gray-800 ">{menu.name}</td>

                                                    <td className="px-6 py-4 whitespace-nowrap text-sm  text-gray-800 "><span onClick={() => {
                                                        deleteMenu(menu.id);
                                                    }} ><Link><FaTrash /></Link></span>
                                                    </td>
                                                </tr>
                                            ))}
                                        {(menuList === null || menuList.length <= 0) &&
                                            <tr><td colSpan={9} className='text-center text-red-500 '>No records to display!</td></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Screens;