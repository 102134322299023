import axios from "axios";
import { useState } from "react";
import CryptoJS from "crypto-js";
import config from './../config.js';

const Register = ({ toggleRegisterModal, toggleLoginModal }) => {
    const [inputs, setInputs] = useState({
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        confirmpassword: "",
        dateofbirth: ""
    })

    const [err, setErr] = useState("")
    const [errfirstname, seterrfirstname] = useState("")
    const [errlastname, seterrlastname] = useState("")
    const [erremail, seterremail] = useState("")
    const [errpassword, seterrpassword] = useState("")
    const [errconfirmpassword, seterrconfirmpassword] = useState("")
    const [errdateofbirth, seterrdateofbirth] = useState("")

    const handleChange = (e) => {
        setInputs(prev => ({ ...prev, [e.target.name]: e.target.value }))
        validationInputs()
    }

    const handleRegister = async (e) => {
        e.preventDefault()
        // validatation 
        seterrfirstname("")
        seterrlastname("")
        seterrpassword("")
        seterrdateofbirth("")
        seterrconfirmpassword("")
        var errCount = 0;

        if (inputs.firstname.length < 3 || inputs.firstname.length > 40) {
            seterrfirstname("must be 3 to 40 in length! ")
            errCount++;
        }
        if (inputs.lastname.length < 3 || inputs.lastname.length > 40) {
            seterrlastname("must be 3 to 40 in length! ")
            errCount++;
        }
        if (inputs.dateofbirth == null || inputs.dateofbirth == "") {
            seterrdateofbirth("enter date! ")
            errCount++;
        }
        if (validateEmail(inputs.email) || inputs.firstname.length > 90)
            seterremail("")
        else {
            seterremail("email is not valid! ")
            errCount++;
        }
        if (inputs.password.length < 8 || inputs.password.length > 40) {
            seterrpassword("must be 8 to 40 in length!")
            errCount++;
        }
        if (inputs.password !== inputs.confirmpassword) {
            seterrconfirmpassword("password not match!")
            errCount++;
        }
        console.log(inputs)
        if (errCount === 0) {
            try {
                const passdata = CryptoJS.AES.encrypt(
                    JSON.stringify(inputs.password),
                    "XkhZG4fW2t2W"
                ).toString();
                inputs.password = passdata;
                await axios.post(config.API_URL+"/api/auth/register", inputs)
                console.log("Process Register")
                toggleLoginModal()
            } catch (err) {
                if (err.response)
                    setErr(err.response.data);
                else
                    setErr("Server error...");
                console.log("error")
                //console.log(error.response.data)
            }
        }

    }
    // validates all fields
    const validationInputs = () => {

    }

    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    return (
        <div id="authentication-modal" tabIndex="-1" className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full shadow-2xl ">
            <div className="relative p-4 h-[90%] w-full  flex justify-center items-start  ">

                <div className="border w-[350px] relative mt-14 bg-white rounded-lg shadow-2xl ">

                    <div className=" flex items-center  justify-between p-4 md:p-5 border-b rounded-t ">
                        <h3 className="text-xl font-semibold text-gray-900 ">
                            Register to platform
                        </h3>
                        <button onClick={toggleRegisterModal} type="button" className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center " data-modal-hide="authentication-modal">
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    <div className="p-4 md:p-5">
                        <form className="space-y-4" action="#">
                            <div>
                                <label htmlFor="firstname" className="block mb-2 text-sm font-medium text-gray-900 ">First name <span className="text-red-300">{errfirstname}</span></label>
                                <input
                                    onChange={handleChange}
                                    type="text" name="firstname" id="firstname"
                                    className={`bg-gray-50 border  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 
                                    ${errfirstname ? 'border-red-300' : 'border-gray-300'}  
                                    `}
                                    placeholder="first name" required />
                            </div>
                            <div>
                                <label htmlFor="lastname" className="block mb-2 text-sm font-medium text-gray-900 ">Last name <span className="text-red-300">{errlastname}</span></label>
                                <input
                                    onChange={handleChange}
                                    type="text" name="lastname" id="lastname"
                                    className={`bg-gray-50 border  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 
                                    ${errlastname ? 'border-red-300' : 'border-gray-300'}  
                                    `}
                                    placeholder="last name" required />
                            </div>
                            <div>
                                <label htmlFor="dateofbirth" className="block mb-2 text-sm font-medium text-gray-900 ">Date of birth <span className="text-red-300">{errdateofbirth}</span></label>
                                <input
                                    onChange={handleChange}
                                    type="date" name="dateofbirth" id="dateofbirth"
                                    className={`bg-gray-50 border  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 
                                ${errdateofbirth ? 'border-red-300' : 'border-gray-300'}  
                                `}
                                    placeholder="" required />
                            </div>
                            <div>
                                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 ">Your email <span className="text-red-300">{erremail}</span></label>
                                <input
                                    onChange={handleChange}
                                    type="email" name="email" id="email"
                                    className={`bg-gray-50 border  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 
                                    ${erremail ? 'border-red-300' : 'border-gray-300'}  
                                    `}
                                    placeholder="name@company.com" required />
                            </div>
                            <div>
                                <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 ">Your password <span className="text-red-400">{errpassword}</span></label>
                                <input
                                    onChange={handleChange}
                                    type="password" name="password" id="password" placeholder="••••••••"
                                    className={`bg-gray-50 border  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 
                                    ${errpassword ? 'border-red-300' : 'border-gray-300'}  
                                    `}
                                    required />
                            </div>

                            <div>
                                <label htmlFor="confirmpassword" className="block mb-2 text-sm font-medium text-gray-900 ">Confirm password <span className="text-red-400">{errconfirmpassword}</span></label>
                                <input
                                    onChange={handleChange}
                                    type="password" name="confirmpassword" id="confirmpassword" placeholder="••••••••"
                                    className={`bg-gray-50 border  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 
                                    ${errconfirmpassword ? 'border-red-300' : 'border-gray-300'}  
                                    `}
                                    required />
                            </div>


                            <button onClick={handleRegister} className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ">Register account</button>
                            <p className="text-red-400 mt-2">{err && err}</p>
                            <div className="text-sm font-medium text-gray-500 dark:text-gray-300">
                                Already registered? <span onClick={toggleLoginModal} className="text-blue-700 hover:underline dark:text-blue-500 cursor-pointer">Sign in now</span>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Register