import axios from "axios";
import React from 'react';
import config from '../../config';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ControlPanel = () => {

    const name = useSelector((state) => state.user.name);
    const navigate = useNavigate();
    const [isInsertOrder, setIsInsertOrder] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [isOrderUpdateStatusAllowed, setIsOrderUpdateStatusAllowed] = useState(false);
    const [err, setErr] = useState("")
    const [orderStatusLastUpdatedOn, setOrderStatusLastUpdatedOn] = useState(null);
    const [insertLastUpdatedOn, setInsertLastUpdatedOn] = useState(null);
    const [cashTransactionLastUpdatedOn, setCashTransactionLastUpdatedOn] = useState(null);

    useEffect(() => {
        if (name === null || name === "") {
            navigate("/")
        }
    })
    useEffect(() => {
        getAppControlPanelState();
    }, [])

    const getAppControlPanelState = async () => {
        try {
            const res = await axios.get(config.BL_API_URL + "/app/v1/getControlPanelState");
            if (res.status === 200) {
                setIsInsertOrder(res.data.enableInsertOrders);
                setIsChecked(res.data.enableCashTransaction);
                setIsOrderUpdateStatusAllowed(res.data.enableInsertOrdersStatusUpdate);
                setOrderStatusLastUpdatedOn(res.data.orderStatusLastUpdatedOn);
                setInsertLastUpdatedOn(res.data.insertLastUpdatedOn)
                setCashTransactionLastUpdatedOn(res.data.cashTransactionLastUpdatedOn)
                setErr("")
            }
            else
                setErr("backend error")

        } catch (err) {
            //console.log("first")
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }

    }

    const updateInsertOrder = async (newValue) => {
        try {

            const res = await axios.post(config.BL_API_URL + "/app/v1/updateControlPanel", { "enableInsertOrders": newValue, "enableCashTransaction": isChecked, "enableInsertOrdersStatusUpdate": isOrderUpdateStatusAllowed });
            if (res.status === 200) {
                setIsInsertOrder(newValue);
                setErr("")
                getAppControlPanelState();
            }
            else
                setErr("backend error")

        } catch (err) {
            //console.log("first")
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }

    };
    const updateOrderStatusUpdate = async (newValue) => {
        try {

            const res = await axios.post(config.BL_API_URL + "/app/v1/updateControlPanel", { "enableInsertOrders": isInsertOrder, "enableCashTransaction": isChecked, "enableInsertOrdersStatusUpdate": newValue });
            if (res.status === 200) {
                setIsOrderUpdateStatusAllowed(newValue);
                setErr("")
                getAppControlPanelState();
            }
            else
                setErr("backend error")

        } catch (err) {
            //console.log("first")
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }
    };
    const updateCashTransaction = async (newValue) => {
        try {

            const res = await axios.post(config.BL_API_URL + "/app/v1/updateControlPanel", { "enableInsertOrders": isInsertOrder, "enableCashTransaction": newValue, "enableInsertOrdersStatusUpdate": isOrderUpdateStatusAllowed });
            if (res.status === 200) {
                setIsChecked(newValue);
                setErr("")
                getAppControlPanelState();
            }
            else
                setErr("backend error")

        } catch (err) {
            //console.log("first")
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }
    };

    function formatDate(value) {
        if (value == null || value === undefined || value === "") {
            return;
        }
        const options = {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        };
        const date = new Date(value);
        return new Intl.DateTimeFormat('en-IN', options).format(date);
    };

    return (
        <div className=" my-0 md:px-20 max-w-screen-lg mx-auto h-50 ">
            <div className="py-14 px-1 flex justify-center items-center">
                <span className="text-gray-500 text-xl font-bold">App Control Panel</span>
            </div>
            <div className="inline-grid grid-cols-3 grid-rows-3 gap-2 ">
                <div className="col-span-3 text-center text-xs "><p>By chaging state of following items; sending orders to exchange or receiving cash for investment / additional investment / withdrawal / partial withdrawal from client can be controlled</p></div>
                {/*!--Row-->*/}
                <div className="font-bold text-right">Integration Type</div>
                <div className="font-bold  justify-self-center">State</div>
                <div className="font-bold w-*">Last State Changed to ON DateTime</div>
                {/*!--Row-->*/}
                <div className="text-right">Insert orders</div>
                <div className="   justify-self-center">
                    <label class="flex items-center relative w-min cursor-pointer select-none">
                        <input type="checkbox" className={`appearance-none transition-colors cursor-pointer w-14 h-7 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-black focus:ring-blue-500 ${isInsertOrder ? 'bg-green-500' : 'bg-red-500'}`} checked={isInsertOrder}
                            onChange={(e) => updateInsertOrder(e.target.checked)} />
                        <span class="absolute font-medium text-xs uppercase right-1 text-white"> OFF </span>
                        <span class="absolute font-medium text-xs uppercase right-8 text-white"> ON </span>
                        <span class={`w-7 h-7 ${isInsertOrder ? 'right-0' : 'right-7'}  absolute rounded-full transform transition-transform bg-gray-200`} />
                    </label>
                </div>
                <div className=" ">{formatDate(insertLastUpdatedOn)}</div>

                <div className="text-right">Cash Transactions</div>
                <div className="   justify-self-center">
                    <label class="flex items-center relative w-min cursor-pointer select-none">
                        <input type="checkbox" className={`appearance-none transition-colors cursor-pointer w-14 h-7 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-black focus:ring-blue-500 ${isChecked ? 'bg-green-500' : 'bg-red-500'}`} checked={isChecked}
                            onChange={(e) => updateCashTransaction(e.target.checked)} />
                        <span class="absolute font-medium text-xs uppercase right-1 text-white"> OFF </span>
                        <span class="absolute font-medium text-xs uppercase right-8 text-white"> ON </span>
                        <span class={`w-7 h-7 ${isChecked ? 'right-0' : 'right-7'}  absolute rounded-full transform transition-transform bg-gray-200`} />
                    </label>
                </div>
                <div className="text-left">{formatDate(cashTransactionLastUpdatedOn)}</div>
                <div className="text-right">Order Status Update</div>
                <div className="   justify-self-center">
                    <label class="flex items-center relative w-min cursor-pointer select-none">
                        <input type="checkbox" className={`appearance-none transition-colors cursor-pointer w-14 h-7 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-black focus:ring-blue-500 ${isOrderUpdateStatusAllowed ? 'bg-green-500' : 'bg-red-500'}`} checked={isOrderUpdateStatusAllowed}
                            onChange={(e) => updateOrderStatusUpdate(e.target.checked)} />
                        <span class="absolute font-medium text-xs uppercase right-1 text-white"> OFF </span>
                        <span class="absolute font-medium text-xs uppercase right-8 text-white"> ON </span>
                        <span class={`w-7 h-7 ${isOrderUpdateStatusAllowed ? 'right-0' : 'right-7'}  absolute rounded-full transform transition-transform bg-gray-200`} />
                    </label>
                </div>
                <div className="text-left">{formatDate(orderStatusLastUpdatedOn)}</div>
            </div>
            <div className="grid grid-cols-2 gap-2">
                <div className="col-span-2 text-center text-xs">
                    <p className="text-red-400 mt-8">
                        {err}
                    </p>
                </div>
            </div>
        </div>
    )
}
export default ControlPanel