import React, { useState } from "react";
import { BiSolidRightArrow } from "react-icons/bi";
import { BiSolidDownArrow } from "react-icons/bi";
import axios from "axios";
import config from "../config";
const PendingActionDetails = ({basketId, keyName, basket, status } ) => {
    const [accOpen, setAccopen] = useState(false)

    const [errorBasket, setErrorBasket] = useState(basket);
    const [lists, setLists] = useState(null);

    const handleOpen = async (basketId, status) => {

        //let basketId = e.currentTarget.getAttribute("id");
        if(basketId != null && !accOpen){
            try {
                const res = await axios.get(config.BL_API_URL+"/transaction/v1/getTranscationErrorDetails", {params: {basketId, status}});
                //console.log(res.data)
          
                if (res.data)
                  setLists(res.data)
                //else
                  //setErr("backend error")
          
              } catch (err) {

                //if (err.response.status !== "Success")
                    //setErr("Server error...");
              }

        }
        setAccopen(!accOpen)
    }
    
    return (
        <div className="py-1">
            <button id={basketId} onClick={e => handleOpen(basketId, status)} className="p-2 flex justify-between items-center w-full text-center bg-btnSecondary text-white rounded-lg">
                <span className="w-full text-center ">{keyName} total {status} {basket.errorCount}</span>
                {accOpen ? <BiSolidDownArrow className="text-yellow-300" /> : <BiSolidRightArrow className="text-yellow-200" />}
            </button>
            <div className={`mx-2 grid overflow-hidden transition-all duration-300 ease-in-out text-slate-600 text-sm ${ accOpen ? 'grid-rows-[1fr] opacity-100' : 'grid-rows-[0fr] opacity-0'
                }`}>
                <div className="overflow-hidden">
                    <div className="flex flex-col">
                        <div className="-m-1.5 overflow-x-auto">
                            <div className="p-1.5 min-w-full inline-block align-middle">
                                <div className="border rounded-lg divide-y divide-gray-200 ">
                                    <table className="min-w-full divide-y divide-gray-400 w-full flex flex-col items-center justify-between"> {/* overflow-y-scroll"> */}
                                        <thead className="bg-tblPrimary text-white w-full">
                                            <tr className="flex w-full">
                                                <th scope="col" className="px-6 py-1 w-1/6 text-start font-medium border">Client Code</th>
                                                <th scope="col" className="px-6 py-1 w-1/2 text-start font-medium border">Script Name</th>
                                                <th scope="col" className="px-6 py-1 w-1/5 text-start font-medium border">Order Type</th>
                                                <th scope="col" className="px-6 py-1 w-1/4 text-start font-medium border">Quantity</th>
                                                <th scope="col" className="px-6 py-1 w-1/3 text-start font-medium border">Order Status</th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 w-full">
                                            {lists &&
                                                lists.map((list, index) => (
                                                    <tr key={list.basketName} className={`flex items-center w-full
                                                                ${ index % 2 === 0 ? '' : 'bg-blue-100' }
                                                            `}>
                                                        <td className="px-6  w-1/6  text-gray-800  border">{list.clientCode}</td>
                                                        <td className="px-6 text-wrap  w-1/2 whitespace-nowrap text-gray-800 border">{list.scripName}</td>
                                                        <td className="px-6  w-1/5   text-gray-800 border ">{list.orderType}</td>
                                                        <td className="px-6  w-1/4   text-gray-800 border">{list.quantity}</td>
                                                        <td className="px-6  w-1/3 whitespace-nowrap  text-gray-800 border">{list.status}</td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PendingActionDetails
