import axios from "axios";
import { useEffect, useState } from "react";
import { format } from 'date-fns';
import config from "../../config";
import { BiSolidRightArrow } from "react-icons/bi";
import { BiSolidDownArrow } from "react-icons/bi";
import { Link } from "react-router-dom";
import { BsFiletypePdf } from "react-icons/bs";
import { BsFiletypeCsv } from "react-icons/bs";

const BasketSubscriber = () => {

    const [stockHoldingList, setStockHoldingList] = useState([]);
    const [closedTransactionsList, setClosedTransactionsList] = useState([]);
    const [brokerList, setBrokerList] = useState([]);
    const [subsdbList, setsubsdbList] = useState([]);
    const [broker, setBroker] = useState("");
    const [err, setErr] = useState(null);
    const [accOpen, setAccOpen] = useState({})
    const [accTranOpen, setAccTranOpen] = useState({})
    const [basketId, setBasketId] = useState(null);

    const [basketList, setBasketList] = useState([]);
    const [subscriberList, setSubscriberList] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [basketName, setBasketName] = useState("")
    const [selectedClientId, setSelectedClientId] = useState(null);
    const [selectedBasketId, setSelectedBasketId] = useState(null);
    const [selectedBroker, setSelectedBroker] = useState('');
    const [isActiveClient, setIsActiveClient] = useState(true);

    //console.log(broker)
    useEffect(() => {
        if (brokerList && brokerList.length > 0) {
            setSelectedBroker(brokerList[1].id); // Set the first broker as default
            getTableOneData({ target: { value: brokerList[1].id } }, isActiveClient);
        }
    }, [brokerList]);

    useEffect(() => {
        // get Ingredients list --
        const getBrokerList = async (e) => {
            setErr("")
            try {
                const res = await axios.get(config.BL_API_URL + "/brokers/v1/getBrokers")
                //console.log(res.data)

                if (res)
                    setBrokerList(res.data)
                else
                    setErr("backend error")

            } catch (err) {
                console.log("error 333 ", err)
                if (err.response.status == 500)
                    setErr("Server error...");
                else
                    setErr(err.response.data);
            }
        }
        getBrokerList()
    }, [])



    const getTableOneData = (e, activeClient) => {
        setSubscriberList(null);
        //setIsActiveClient(activeClient);
        setBroker(e.target.value);
        getBasketList(e.target.value, activeClient);
        setSelectedBasketId(null);
        setSelectedClientId(null);
        setSelectedRow(null);
        setBasketName(null);
    }

    async function getNoOfClients1(basketId) {
        return await getNoOfClients(basketId);
    }

    const getNoOfClients = async (basketId) => {
        let brokerId = basketId;
        let noOfClients = 0;
        const resCount = await axios.get(config.BL_API_URL + "/BrokerClientMap/v1/findClientCountByBrokerId", { params: { brokerId } })

        if (resCount) {
            noOfClients = resCount.data;
        }
        return noOfClients;
    }

    const getBasketList = async (value, activeClient) => {
        setErr("")
        try {
            let brokerId = value;


            let res = null;
            if (activeClient) {
                res = await axios.get(config.BL_API_URL + "/BrokerClientMap/v1/getlist", { params: { brokerId } })

            } else {
                res = await axios.get(config.BL_API_URL + "/BrokerClientMap/v1/getInactiveList", { params: { brokerId } })
            }

            if (res && res != undefined && res.data != null) {
                setBasketList(res.data);
            }

            /*  if (res) {
                 let dataWithClients = await Promise.all(res.data.map(async (item) => {
                     let noOfClients = await getNoOfClients(item.basketId);
                     return { ...item, noOfClients };
                 }));
                 setBasketList(dataWithClients)
             } */
            /* else
                setErr("backend error") */

        } catch (err) {
            console.log("error 333 ", err)
            /* if (!err.response.data)
                setErr("Server error...");
            else
                setErr(err.response.data); */
        }
    }

    const getBasketSubscriberList = async (value, basketName, activeClient) => {
        setErr("")
        setBasketName(basketName);
        setSelectedBasketId(value);
        setSelectedClientId(null);
        setSelectedRow(null);
        try {
            //setAccOpen(false);
            let basketId = value;
            setBasketId(basketId);
            let res = null;
            if (activeClient) {
                res = await axios.get(config.BL_API_URL + "/BasketClientMap/v1/getlist", { params: { basketId } })
            } else {
                res = await axios.get(config.BL_API_URL + "/BasketClientMap/v1/getListInActiveClient", { params: { basketId } })
            }
            //console.log(res.data)

            if (res) {
                setSubscriberList(res.data)
            }
            else
                setErr("backend error")

        } catch (err) {
            console.log("error 333 ", err)
            if (!err.response.data)
                setErr("Server error...");
            else
                setErr(err.response.data);
        }
    }

    const getNumOfSubscriber = (recipe_id) => {
        const tot = subsdbList.filter(item => item.recipe_id === recipe_id)
        return tot.length
    }

    function formatNumber(value) {
        return new Intl.NumberFormat('en-IN').format(value);
    }

    const openPopup = () => {
        const width = 400;
        const height = 400;
        const left = (window.screen.width - width) / 2;
        const top = (window.screen.height - height) / 2;

        const newWindow = window.open('/BasketSubscriberReport', '_blank', 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=no,width=400,height=400,modal=yes,top=${top},left=${left}`');
        if (newWindow) newWindow.opener = null;
    }

    const closeAll = () => {
        setAccOpen(prevState => {
            const newState = { ...prevState };
            for (let key in newState) {
                newState[key] = false;
            }
            return newState;
        });
    };

    const closeAllAccTran = () => {
        setAccTranOpen(prevState => {
            const newState = { ...prevState };
            for (let key in newState) {
                newState[key] = false;
            }
            return newState;
        });
    };

    const handleOpenClosedTransactions = async (basketId, clientCode) => {
        try {
            //setAccopen(!accOpen)
            closeAllAccTran()
            setAccTranOpen(prevState => ({ ...prevState, [`${basketId}-${clientCode}`]: !prevState[`${basketId}-${clientCode}`] }));

            const res = await axios.get(config.BL_API_URL + "/transaction/v1/getClosedTransactions", { params: { clientCode, basketId } });

            if (res.status === 200 && res.data)
                setClosedTransactionsList(res.data)
            else
                setErr("backend error while getting closed transactions")

        } catch (err) {
        }
    }

    const handleOpen = async (basketId, clientCode) => {
        try {
            //setAccopen(!accOpen)
            closeAll()
            setAccOpen(prevState => ({ ...prevState, [`${basketId}-${clientCode}`]: !prevState[`${basketId}-${clientCode}`] }));

            const res = await axios.get(config.BL_API_URL + "/BasketClientMap/v1/getBaskeClientStocktHolding", { params: { basketId, clientCode } });

            if (res.status === 200 && res.data)
                setStockHoldingList(res.data)
            else
                setErr("backend error")

        } catch (err) {


            setErr(err.message);
        }
    }

    const openHoldingReport = (clientCode, basketId) => {
        const width = 400;
        const height = 400;
        const left = (window.screen.width - width) / 2;
        const top = (window.screen.height - height) / 2;
        const axiosConfig = {
            responseType: 'blob',
            headers: {
                Accept: 'Application/csv'
            }
        }
        const url = `${config.BL_API_URL}/csvReport/v1/getHolding?clientCode=${encodeURIComponent(clientCode)}&basketId=${encodeURIComponent(basketId)}`;

        axios.get(url, axiosConfig).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'file.csv');
            document.body.appendChild(link);
            link.click();
        }).catch((error) => {
            console.log("error", error)
        }
        );
    }

    const openTransactionClosedReport = (clientCode, basketId) => {
        const width = 400;
        const height = 400;
        const left = (window.screen.width - width) / 2;
        const top = (window.screen.height - height) / 2;
        const axiosConfig = {
            responseType: 'blob',
            headers: {
                Accept: 'Application/csv'
            }
        }
        const url = `${config.BL_API_URL}/csvReport/v1/getClosedTransactions?clientCode=${encodeURIComponent(clientCode)}&basketId=${encodeURIComponent(basketId)}`;

        axios.get(url, axiosConfig).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'file.csv');
            document.body.appendChild(link);
            link.click();
        }).catch((error) => {
            console.log("error", error)
        }
        );
    }

    function PRNG(seed, modulo) {
        let str = `${(2 ** 31 - 1 & Math.imul(48271, seed)) / 2 ** 31}`
            .split('')
            .slice(-10)
            .join('') % modulo

        return str
    }

    const onClientRowSelected = (e) => {
        setSelectedRow(e.currentTarget.cells[0].innerText);
        setSelectedClientId(e.currentTarget.cells[1].innerText);
    }

    const downloadAllClients = (basketId) => {
        const width = 400;
        const height = 400;
        const left = (window.screen.width - width) / 2;
        const top = (window.screen.height - height) / 2;
        const axiosConfig = {
            responseType: 'blob',
            headers: {
                Accept: 'Application/csv'
            }
        }
        const url = `${config.BL_API_URL}/csvReport/v1/getAllClientsByBasketId?basketId=${encodeURIComponent(basketId)}`;

        axios.get(url, axiosConfig).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'file.csv');
            document.body.appendChild(link);
            link.click();
        }).catch((error) => {
            console.log("error", error)
        }
        );
    }
    const downloadAdditionalInvestmentCalculations = (clientCode, basketId) => {
        const width = 400;
        const height = 400;
        const left = (window.screen.width - width) / 2;
        const top = (window.screen.height - height) / 2;
        const axiosConfig = {
            responseType: 'blob',
            headers: {
                Accept: 'Application/csv'
            }
        }
        const url = `${config.BL_API_URL}/csvReport/v1/getAdditionalInvestmentCalculations?clientCode=${encodeURIComponent(clientCode)}&basketId=${encodeURIComponent(basketId)}`;

        axios.get(url, axiosConfig).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'allocation_' + clientCode + '.csv');
            document.body.appendChild(link);
            link.click();
        }).catch((error) => {
            console.log("error", error)
        }
        );
    }

    const openReport = (clientCode, basketId) => {
        const width = 400;
        const height = 400;
        const left = (window.screen.width - width) / 2;
        const top = (window.screen.height - height) / 2;
        const axiosConfig = {
            responseType: 'arraybuffer',
            headers: {
                Accept: 'Application/pdf'
            }
        }
        const url = `${config.BL_API_URL}/report/v1/performanceReport?clientCode=${encodeURIComponent(clientCode)}&basketId=${encodeURIComponent(basketId)}`;

        axios.get(url, axiosConfig).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'file.pdf');
            document.body.appendChild(link);
            link.click();
        }).catch((error) => {
            console.log("error", error)
        }
        );
    };

    const changeClientStatus = async (newValue) => {
        try {

            setIsActiveClient(newValue);
            getBasketList(broker, newValue)

            setSubscriberList(null);
            //changeClientStatus(true);
            setSelectedBasketId(null);
            setSelectedClientId(null);
            setSelectedRow(null);
            setBasketName(null);
            setErr("")

        } catch (err) {
            //console.log("first")
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }

    };
    return (
        <div className=" w-full transform ">
            <div className="z-0 relative  w-full  flex justify-center items-start  ">
                <div className="b order  relative  w-full ">
                    <div className="p-0 md:p-0 w-full">
                        <div className=" w-full">
                            <div className="grid sm:grid-cols-12 gap-0 sm:gap-0 w-full">
                                {/* Table 1 ---- */}
                                <div className="sm:col-span-3">
                                    <div className="flex items-start flex-column flex-wrap md:flex-row justify-left pt-4" aria-label="Table navigation">
                                        <div className="px-0">
                                            <form className="">
                                                <select value={selectedBroker} onChange={(e) => getTableOneData(e, isActiveClient)}
                                                    id="broker" name="broker" className="min-w-[100px] mb-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                    {brokerList &&
                                                        brokerList.map((broker, index) => (
                                                            <option key={index} value={broker.id}>{broker.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </form>
                                        </div>
                                        <div className="px-2">
                                            <label class="flex items-center relative w-max cursor-pointer select-none ">
                                                <input type="checkbox" className={`appearance-none transition-colors cursor-pointer w-24 h-7 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-black focus:ring-blue-500 ${isActiveClient ? 'bg-green-500' : 'bg-red-500'}`} checked={isActiveClient}
                                                    onChange={(e) => changeClientStatus(e.target.checked)} />
                                                {isActiveClient &&
                                                    <span class={`absolute font-small text-xs uppercase right-11 text-white `}> Active </span>
                                                }
                                                {!isActiveClient &&
                                                    <span class={`absolute font-small text-xs uppercase right-1 text-white`}> In-Active </span>
                                                }
                                                <span class={`w-9 h-7 ${isActiveClient ? 'right-0' : 'right-15'}  absolute rounded-full transform transition-transform bg-gray-200`} />
                                            </label>
                                        </div>
                                        <div className="px-0"><label className="">Clients</label></div>
                                    </div>
                                    <div className="relative overflow-x-auto ">
                                        <table className=" text-xs text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                            <thead className=" text-xs text-white uppercase bg-tblPrimary dark:bg-gray-700 dark:text-gray-400">
                                                <tr className="">
                                                    <th scope="col" className="px-1 py-3">
                                                        Bakset Name
                                                    </th>
                                                    <th scope="col" className="px-1 py-3">
                                                        No of Clients
                                                    </th>
                                                    <th scope="col" className="px-1 py-3">
                                                        Investment AUM
                                                    </th>
                                                    <th scope="col" className="px-1 py-3">
                                                        Current Value of AUM
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {basketList &&
                                                    basketList.map((item, index) => (
                                                        <tr onClick={e => getBasketSubscriberList(item.basketId, item.basketName, isActiveClient)} key={index} className="cursor-pointer bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">

                                                            <td className="px-1 py-2 font-medium text-gray-900 whitespace-wrap dark:text-white">
                                                                {item.basketName}
                                                            </td>
                                                            <td className="px-1 py-2">
                                                                {item.noOfClients}
                                                            </td>
                                                            <td className="px-1 py-2 max-wid" displayType="text">
                                                                {/* <NumericFormat displayType="text" value={item.investmentValue} allowLeadingZeros thousandSeparator={true} thousandsGroupStyle="lakh" className="max-w-fit" /> */}
                                                                {formatNumber(item.investmentValue)}
                                                            </td>
                                                            <td className="px-1 py-2">
                                                                {/* <NumericFormat  value={item.currentValue} allowLeadingZeros thousandSeparator={true} thousandsGroupStyle="lakh" /> */}
                                                                {formatNumber(item.currentValue)}
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="sm:col-span-9">
                                    <div className="flex items-top flex-column flex-nowrap sm:flex-row justify-between pt-4 overflow-x-auto" style={{ height: '40px' }} aria-label="Table navigation">
                                        <div className="sm:col-span-1 w-1/4  text-wrap ">
                                            <span >Selected Basket: {basketName === null ? 'None' : basketName} </span>
                                        </div>
                                        <div className="sm:col-span-1 w-1/2  text-wrap">
                                            <span>Selected Client:
                                                {selectedRow === null ? 'None' : selectedRow} </span>
                                        </div>
                                        <div className="sm:col-span-1 align-top"><Link title="Download all basket clients"><span className="" onClick={e => downloadAllClients(selectedBasketId)} ><BsFiletypeCsv size={20} /></span></Link></div>
                                        <div className="sm:col-span-1 align-top"><Link title="Download additional investment calculations"><span className="" onClick={e => downloadAdditionalInvestmentCalculations(selectedClientId, selectedBasketId)} ><BsFiletypeCsv size={20} /></span></Link></div>
                                        <div className="sm:col-span-1 align-top"><Link title="Download selected client's performance report"><span onClick={e => openReport(selectedClientId, selectedBasketId)}><BsFiletypePdf size={20} /></span></Link></div>
                                        <div className="sm:col-span-1 align-top"><Link title="Download holding report for selected client"><span className="" onClick={e => openHoldingReport(selectedClientId, selectedBasketId)} ><BsFiletypeCsv size={20} /></span></Link></div>
                                        <div className="sm:col-span-1 align-top"><Link title="Download transaction closed report for selected client"><span className="" onClick={e => openTransactionClosedReport(selectedClientId, selectedBasketId)} ><BsFiletypeCsv size={20} /></span></Link></div>
                                    </div>

                                    {/* Table 2 ---- */}
                                    <div className="relative overflow-x-auto flex justify-left">
                                        <div className="flex justify-left sm:mt-6 ">
                                            <table className="w-100 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                                <div className="max-h-20 overflow-auto">
                                                    <thead className="sticky top-0 text-xs text-white uppercase bg-tblPrimary dark:bg-gray-700 dark:text-gray-400 block" style={{ display: 'table-*', tableLayout: 'fixed', paddingRight: '0px' }}>
                                                        <tr style={{ display: 'table', tableLayout: 'fixed' }}>
                                                            {/*                                                         <th scope="col" className="px-2 py-3 w-1/8 text-wrap border-r">
                                                            Fund Name
                                                        </th>
 */}                                                        <th scope="col" className="px-2 py-3 w-1/5 text-wrap bg-fuchsia-950 border-r">
                                                                Client Name
                                                            </th>
                                                            <th scope="col" className="px-2 py-1 w-1/12 text-wrap border-r">
                                                                Client Code
                                                            </th>
                                                            <th scope="col" className="px-2 py-3 w-28 text-wrap border-r">
                                                                Subscription Date
                                                            </th>
                                                            <th scope="col" className="px-2 py-3 w-24 text-wrap border-r">
                                                                Initial Investment
                                                            </th>
                                                            <th scope="col" className="px-2 py-3 w-24 text-wrap border-r">
                                                                Additional Investment
                                                            </th>
                                                            <th scope="col" className="px-2 py-3 w-28 text-wrap border-r">
                                                                Withdrawals
                                                            </th>
                                                            <th scope="col" className="px-2 py-3 w-24 text-wrap border-r">
                                                                Initial Stocks Bought Value
                                                            </th>
                                                            <th scope="col" className="px-2 py-3 w-24 text-wrap border-r">
                                                                Current Value
                                                            </th>
                                                            {/*                                                         <th scope="col" className="px-2 py-3 w-20 text-wrap border-r">
                                                            PnL
                                                        </th>
 */}                                                        <th scope="col" className="px-2 py-3 w-10 text-wrap border-r">
                                                                %
                                                            </th>
                                                            <th scope="col" className="px-2 py-3 w-24 text-wrap border-r">
                                                                Cash Balance
                                                            </th>
                                                            {/* <th>Performance Report</th>
                                                    <th>Holding Report</th> */}
                                                        </tr>
                                                    </thead>
                                                </div>
                                                <div className="max-h-90 overflow-auto">
                                                    <tbody class="block overflow-auto" style={{ maxHeight: '500px' }}>
                                                        {subscriberList &&
                                                            subscriberList.map((subscriber, index) => (
                                                                <>
                                                                    <tr
                                                                        key={index}
                                                                        data={subscriber.clientCode}
                                                                        onClick={e => onClientRowSelected(e)}
                                                                        className={`border-b border dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600`}
                                                                        style={{ display: 'table', width: '100%', tableLayout: 'fixed', height: '10px' }}
                                                                    >

                                                                        {/*                                                                     <td className="px-2 py-2 col-span-1 font-small  border-r text-gray-900 whitespace-wrap dark:text-white">
                                                                        {subscriber.basketName}
                                                                    </td>
 */}                                                                    <td className="px-2 py-2 w-1/5 font-small border-r text-gray-900 whitespace-wrap dark:text-white overflow-hidden capitalize ">
                                                                            {subscriber.clientName}
                                                                        </td>
                                                                        <td className="px-2 py-2 w-1/12 font-small border-r text-gray-900 whitespace-nowrap dark:text-white">
                                                                            {subscriber.clientCode}
                                                                        </td>
                                                                        <td className="px-2 py-2 w-28 font-small border-r text-gray-900 whitespace-nowrap dark:text-white">
                                                                            {format(subscriber.subscriptionDate, 'dd/MMM/yyyy')}
                                                                        </td>
                                                                        <td className="px-2 py-2 w-24 font-small border-r text-gray-900 whitespace-nowrap dark:text-white">
                                                                            {formatNumber(subscriber.initialInvestment)}
                                                                        </td>
                                                                        <td className="px-2 py-2 w-24 font-small border-r text-gray-900 whitespace-nowrap dark:text-white">
                                                                            {formatNumber(subscriber.additionalInvestment)}
                                                                        </td>
                                                                        <td className="px-2 py-2 w-28 font-small border-r text-gray-900 whitespace-nowrap dark:text-white">
                                                                            {formatNumber(subscriber.withdrawals)}
                                                                        </td>
                                                                        <td className="px-1 py-2 w-24 font-small border-r text-gray-900 whitespace-wrap dark:text-white text-right max-w-40 ">
                                                                            {/*                                                                 <NumericFormat className="w-1/10"  value={subscriber.investmentValue} allowLeadingZeros thousandSeparator={true} thousandsGroupStyle="lakh" /> */}
                                                                            {formatNumber(subscriber.investmentValue)}
                                                                        </td>
                                                                        <td className="px-1 py-2 w-24 font-small border-r text-gray-900 whitespace-wrap dark:text-white text-right">
                                                                            {/* <NumericFormat className="w-1/10"  value={subscriber.currentValue} allowLeadingZeros thousandSeparator={true} thousandsGroupStyle="lakh" /> */}
                                                                            {formatNumber(subscriber.currentValue)}
                                                                        </td>
                                                                        {/*                                                                     <td className="px-1 py-2 w-20 font-small border-r text-gray-900 whitespace-wrap dark:text-white text-right">
                                                                        {formatNumber(subscriber.pnL)}
                                                                    </td> */}
                                                                        <td className="px-1 py-2 w-10 font-small border-r text-gray-900 whitespace-wrap dark:text-white text-center">
                                                                            {Math.round(subscriber.returns, 2)}
                                                                        </td>
                                                                        <td className="px-1 py-2 w-24 font-small border-r text-gray-900 whitespace-wrap dark:text-white text-right">
                                                                            {/* <NumericFormat className="w-1/10"  value={subscriber.cashBalance} allowLeadingZeros thousandSeparator={true} thousandsGroupStyle="lakh" /> */}
                                                                            {formatNumber(subscriber.cashBalance)}
                                                                        </td>

                                                                        {/* <td><Link><span onClick={e => openReport(subscriber.clientCode, basketId)}><BsFiletypePdf size={30} /></span></Link></td>
                                                                <td><div className="py-1"><Link><span className="px-1 py-1" onClick={e => openTransactionClosedReport(subscriber.clientCode, basketId)} ><BsFiletypeCsv size={30} /></span></Link></div></td> */}
                                                                    </tr>
                                                                    {/*      {<tr>
                                                                <td colSpan={13}>

                                                                    <div className="py-0">
                                                                        <button id={subscriber.clientCode} onClick={e => handleOpen(subscriber.basketId, subscriber.clientCode)} className="p-2 flex justify-between items-center w-full text-center bg-btnSecondary text-white rounded-lg">
                                                                            <span className="w-full text-center ">Stock Holding</span>
                                                                            {accOpen[`${subscriber.basketId}-${subscriber.clientCode}`] ? <BiSolidDownArrow className="text-yellow-300" /> : <BiSolidRightArrow className="text-yellow-200" />}
                                                                        </button>
                                                                        <></>
                                                                        <div className={`mx-2 grid overflow-hidden transition-all duration-300 ease-in-out text-slate-600 text-sm ${accOpen[`${subscriber.basketId}-${subscriber.clientCode}`] ? 'grid-rows-[1fr] opacity-100' : 'grid-rows-[0fr] opacity-0'
                                                                            }`}>
                                                                            <div className="overflow-hidden">
                                                                                <div className="flex flex-col">
                                                                                    <table>
                                                                                        <thead className="text-xs text-white uppercase bg-tblPrimary dark:bg-gray-700 dark:text-gray-400">
                                                                                            <tr>
                                                                                                <th scope="col" className="px-2 w-1/2 text-wrap border-r text-center">Stock Name</th>
                                                                                                <th scope="col" className=" w-12/12 text-nowrap border-r text-center">Quantity</th>
                                                                                                <th scope="col" className=" w-3/12 text-nowrap border-r text-center">Buy Price</th>
                                                                                                <th scope="col" className=" w-1/4 text-nowrap border-r text-center">Buy Value</th>
                                                                                                <th scope="col" className=" w-1/4 text-nowrap border-r text-center">Current Price</th>
                                                                                                <th scope="col" className=" w-1/4 text-nowrap border-r text-center">Current Value</th>
                                                                                                <th scope="col" className=" w-1/4 text-nowrap border-r text-center">Holding Return %</th>

                                                                                            </tr>
                                                                                        </thead>
                                                                                        {stockHoldingList &&
                                                                                            stockHoldingList.map((stockHolding, index) => (
                                                                                                <tbody>
                                                                                                        <tr id={PRNG()} className="bg-white border-b border w-1/10 dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                                                                                        <td className="px-4 w-1/2 font-small border-r text-gray-900 whitespace-wrap dark:text-white text-center">{stockHolding.scripName}</td>
                                                                                                        <td className=" w-12/12 font-small border-r text-gray-900 whitespace-wrap dark:text-white text-center">{formatNumber(stockHolding.qty)}</td>
                                                                                                        <td className=" w-3/12 font-small border-r text-gray-900 whitespace-wrap dark:text-white text-center">{formatNumber(stockHolding.boughtPrice)}</td>
                                                                                                        <td className=" w-1/4 font-small border-r text-gray-900 whitespace-wrap dark:text-white text-center">{formatNumber(stockHolding.boughtValue)}</td>
                                                                                                        <td className=" w-1/4 font-small border-r text-gray-900 whitespace-wrap dark:text-white text-center">{formatNumber(stockHolding.currentPrice)}</td>
                                                                                                        <td className=" w-1/4 font-small border-r text-gray-900 whitespace-wrap dark:text-white text-center">{formatNumber(stockHolding.currentValue)}</td>
                                                                                                        <td className=" w-1/4 font-small border-r text-gray-900 whitespace-wrap dark:text-white text-center">{stockHolding.holdingReturns}</td>
                                                                                                    </tr>

                                                                                                </tbody>
                                                                                            ))
                                                                                        }
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td colSpan={1}>
                                                                    <div className="py-0"><Link><span className="px-1 py-1" onClick={e => openHoldingReport(subscriber.clientCode, basketId)} ><BsFiletypeCsv size={30} /></span></Link></div>
                                                                </td>
                                                            </tr>} */}
                                                                    {/*  {<tr>
                                                                <td colSpan={13}>
                                                                    <div className="py-0">
                                                                        <button id={PRNG()} onClick={e => handleOpenClosedTransactions(subscriber.basketId, subscriber.clientCode)} className="p-2 flex justify-between items-center w-full text-center bg-btnSecondary text-white rounded-lg">
                                                                            <span className="w-full text-center ">Closed Transactions</span>
                                                                            {accTranOpen[`${subscriber.basketId}-${subscriber.clientCode}`] ? <BiSolidDownArrow className="text-yellow-300" /> : <BiSolidRightArrow className="text-yellow-200" />}
                                                                        </button>

                                                                        <div className={`mx-2 grid overflow-hidden transition-all duration-300 ease-in-out text-slate-600 text-sm ${accTranOpen[`${subscriber.basketId}-${subscriber.clientCode}`] ? 'grid-rows-[1fr] opacity-100' : 'grid-rows-[0fr] opacity-0'
                                                                            }`}>
                                                                            <div className="overflow-hidden">
                                                                                <div className="flex flex-col">
                                                                                    <table>
                                                                                        <thead className="text-xs text-white uppercase bg-tblPrimary dark:bg-gray-700 dark:text-gray-400">
                                                                                            <tr>
                                                                                                <th scope="col" className="px-2 w-1/2 text-wrap border-r text-center">Stock Name</th>
                                                                                                <th scope="col" className=" w-1/4 text-nowrap border-r text-center">Bought Date</th>
                                                                                                <th scope="col" className=" w-1/4 text-nowrap border-r text-center">Bought Quantity</th>
                                                                                                <th scope="col" className=" w-1/4 text-nowrap border-r text-center">Bought Price</th>
                                                                                                <th scope="col" className=" w-1/4 text-nowrap border-r text-center">Sold Date</th>
                                                                                                <th scope="col" className=" w-1/4 text-nowrap border-r text-center">Sold Quantity</th>
                                                                                                <th scope="col" className=" w-1/4 text-nowrap border-r text-center">Sold Price</th>
                                                                                                <th scope="col" className=" w-1/4 text-nowrap border-r text-center">Realized Profit</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        {closedTransactionsList &&
                                                                                            closedTransactionsList.map((closedTransaction, index) => (
                                                                                                <tbody>
                                                                                                    <tr id={PRNG()} className="bg-white border-b border w-1/10 dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                                                                                        <td className="px-4 w-1/2 font-small border-r text-gray-900 whitespace-wrap dark:text-white text-center">{closedTransaction.scripName}</td>
                                                                                                        <td className=" w-1/4 font-small border-r text-gray-900 whitespace-wrap dark:text-white text-center">{closedTransaction.boughtDate}</td>
                                                                                                        <td className=" w-1/4 font-small border-r text-gray-900 whitespace-wrap dark:text-white text-center">{formatNumber(closedTransaction.buyQuantity)}</td>
                                                                                                        <td className=" w-1/4 font-small border-r text-gray-900 whitespace-wrap dark:text-white text-center">{formatNumber(closedTransaction.buyRate)}</td>
                                                                                                        <td className=" w-1/4 font-small border-r text-gray-900 whitespace-wrap dark:text-white text-center">{closedTransaction.soldDate}</td>
                                                                                                        <td className=" w-1/4 font-small border-r text-gray-900 whitespace-wrap dark:text-white text-center">{formatNumber(closedTransaction.sellQuantity)}</td>
                                                                                                        <td className=" w-1/4 font-small border-r text-gray-900 whitespace-wrap dark:text-white text-center">{formatNumber(closedTransaction.sellRate)}</td>
                                                                                                        <td className=" w-1/4 font-small border-r text-gray-900 whitespace-wrap dark:text-white text-center">{formatNumber(closedTransaction.realizedProfit)}</td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            ))
                                                                                        }
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td colSpan={1}>
                                                                    <div className="py-1"><Link><span className="px-1 py-1" onClick={e => openTransactionClosedReport(subscriber.clientCode, basketId)} ><BsFiletypeCsv size={30} /></span></Link></div>
                                                                </td>
                                                            </tr>} */}

                                                                </>
                                                            ))
                                                        }


                                                    </tbody>
                                                </div>

                                            </table>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default BasketSubscriber