import { useEffect, useState } from "react";
import { IoSearch } from "react-icons/io5";

const AutoComplete = ({ data, setInputs, inputs, editOn }) => {

  const [suggestions, setSuggestions] = useState([]);
  const [suggestionIndex, setSuggestionIndex] = useState(0);
  const [suggestionsActive, setSuggestionsActive] = useState(false);
  const [value, setValue] = useState("");
  const [key, setKey] = useState("");

  useEffect(() => {
    //setInputs((prev) => ({ ...prev, 'stockName': value }));
  }, [setInputs, value])

  useEffect(() => {
    //setInputs((prev) => ({ ...prev, 'stockId': key }));
  }, [key, setInputs])

  useEffect(() => {

    if (inputs !== undefined && "stockName" in inputs) {
      setValue(inputs.stockName);
      setKey(inputs.stockId);
    }
  }, [inputs])

  const handleChange = (e) => {
    if (e == null || e.target == null || e.target.value == null)
      return;


    const query = e.target.value.toLowerCase();
    setValue(query);
    if (query.length > 0) {
      const filterSuggestions = data.filter(
        (suggestion) => {
          return suggestion.stockName.toLowerCase().includes(query);
        }
      );
      setSuggestions(filterSuggestions);
      setSuggestionsActive(true);
    } else {
      setSuggestionsActive(false);
    }
  };

  const handleClick = (e) => {
    setSuggestions([]);
    setValue(e.target.innerText);
    setKey(e.target.value);
    setInputs((prev) => ({ ...prev, 'stockName': suggestions[suggestionIndex].scriptCode, 'stockId': suggestions[suggestionIndex].stockId }))
    setSuggestionsActive(false);
  };

  const handleKeyDown = (e) => {
    // UP ARROW
    if (e.keyCode === 38) {
      if (suggestionIndex === 0) {
        return;
      }
      setSuggestionIndex(suggestionIndex - 1);
    }
    // DOWN ARROW
    else if (e.keyCode === 40) {
      if (suggestionIndex - 1 === suggestions.length) {
        return;
      }
      setSuggestionIndex(suggestionIndex + 1);
    }
    // ENTER
    else if (e.keyCode === 13) {
      setValue(suggestions[suggestionIndex].scriptCode);
      setKey(suggestions[suggestionIndex].stockId);
      setInputs((prev) => ({ ...prev, 'stockName': suggestions[suggestionIndex].scriptCode, 'stockId': suggestions[suggestionIndex].stockId }))
      setSuggestionIndex(0);
      setSuggestionsActive(false);
    }
  };

  const Suggestions = () => {
    return (
      <ul className="absolute bg-gray-200 border suggestions py-2 px-3 block w-[300px] border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none ">
        {suggestions.slice(0, 15).map((suggestion, index) => {
          return (
            <li
              className={index === suggestionIndex ? "bg-gray-300" : ""}
              key={suggestion.stockId}
              value={suggestion.stockId}
              onClick={handleClick}

            >
              <div className="container mx-auto p-0">
                <div className="grid grid-cols-2 gap-0">
                  <div className="p-0">{suggestion.scriptCode}</div>
                  <div className=" p-0">{suggestion.isin}</div>
                </div>
              </div>

              {/* {suggestion.scriptCode + " " + suggestion.isin} */}
            </li>
          );
        })}
      </ul>
    );
  };



  return (
    <div className="autocomplete ">
      {editOn &&
        <input
          disabled
          type="text"
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder="Search..........🔍"
          className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none "
        />
      }
      {!editOn &&
        <input
          id="searchStockName"
          type="text"
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder="Search..........🔍"
          className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none "
        />
      }

      {suggestionsActive && <Suggestions />}
    </div>
  );

};

export default AutoComplete;