import AddStocks from "./AddStocks";
import axios from "axios";
import { useEffect, useState } from "react";
import { FaRegFile } from "react-icons/fa";
import { FaRegTrashAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link, json, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import config from "../../config";

const EditBasket = () => {
    const params = useParams();
    const [basketId, setBasketId] = useState(params.id)

    const email = useSelector((state) => state.user.email);
    const [productTypes, setProductTypes] = useState([])
    const [err, setErr] = useState("")
    const [file, setFile] = useState(null);
    const [files, setFiles] = useState("")
    const [stocks, setStocks] = useState([])
        // the alert is displayed by default
    const [alert, setAlert] = useState(true);
    const [editOn, setEditOn] = useState(false);
    const companyId = useSelector((state) => state.user.companyId);
    

    useEffect(() => {
        // when the component is mounted, the alert is displayed for 3 seconds
        setTimeout(() => {
          setAlert(false);
        }, 10000);
      }, []);

    const [ingredientsModel, setIngredientsModel] = useState(false)

    const navigate = useNavigate();

    const [inputs, setInputs] = useState({
        basketId: "",
        companyId: "",
        basketName: "",
        description: "",
        productType: "",
        productTypeId: "",
        minimumInvestment: "",
        createdby: email,
        productCode: "",
        displayName: "",
        availableFrom: "",
        files: "",
        stocks: []
    })
    

    
    // get category list --
    useEffect(() => {
        const getProductTypes = async (e) => {
            setErr("Initialized!!")
            try {
                const res = await axios.get(config.BL_API_URL+"/basket/v1/getProductTypes")
                //console.log(res.data)

                if (res)
                    setProductTypes(res.data)
                else
                    setErr("backend error")

            } catch (err) {
                console.log(err)
                if (err.response)
                    setErr(err.response.data);
                else
                    setErr("Server error while getting productTypes...");
            }
        }
        getProductTypes()
    }, [inputs])


    // get recipe --
    useEffect(() => {
        const getBasket = async (e) => {
            setErr("")

            try {
                if(basketId != null)
                {
                    setEditOn(true);
                }

                const res = await axios.get(config.BL_API_URL+"/basket/v1/getbasket", {params: {basketId} });
                const resStocks = await axios.get(config.BL_API_URL+"/basketstock/v1/getbasketstockbyid", { params: {basketId} });
                //console.log(res.data)

                if (res != null && res.data != null) {
                    setInputs({
                        basketId: res.data.basketId,
                        companyId: res.data.companyId,
                        basketName: res.data.name,
                        description: res.data.description,
                        displayName: res.data.displayName,
                        availableFrom: res.data.availableFrom,
                        minimumInvestment: res.data.minimumInvestment,
                        createdby: email,
                        productCode: res.data.productCode,
                        productTypeId: res.data.productTypeId,                
                        /*files: res.data.data[0],
                        stocks: JSON.parse(res.data.stocks)*/
                    })
                    if(resStocks.data != null){
                        setStocks(resStocks.data);
                    }
                    /*if(resStocks.data.length > 0)
                        setStocks(resStocks.data)*/
                    setFiles(res.data.files)
                }
                else
                    setErr("backend error")

            } catch (err) {
                console.log(err)
                if (err.response)
                    setErr(err.response.data);
                else
                    setErr("Server error......");
            }
        }
        getBasket()
    }, [])



    const [errBasketName, setErrBasketName] = useState("")
    const [errDisplayName, setErrDisplayName] = useState("")
    const [errMinimumInvestment, setErrMinimumInvestment] = useState("")
    const [errAvailableFrom, setErrAvailableFrom] = useState("")
    const [errProductType, setErrProductType] = useState("")
    const [errProductCode, setErrProductCode] = useState("")
    const [errDescription, seterrDescription] = useState("")

    const handleChange = (e) => {
        setInputs(prev => ({ ...prev, [e.target.name]: e.target.value }))
    }

    const handleUpdateBasket = async (e) => {
        e.preventDefault()

        // validatation 
        setErrBasketName("")
        setErrDisplayName("")
        setErrMinimumInvestment("")
        setErrAvailableFrom("")
        setErrProductType("")
        setErrProductCode("")
        seterrDescription("")
        inputs.files = files;
        inputs.stocks = stocks;
        inputs.companyId = companyId;

        var errCount = 0;

        if (inputs.basketName == null || inputs.basketName.length < 3 || inputs.basketName.length > 40) {
            setErrBasketName("* recipe name must be 3 to 40 in length! ")
            errCount++;
        }

        if (inputs.displayName != null && (inputs.displayName.length < 3 || inputs.displayName.length > 40)) {
            setErrDisplayName("* display name must be 3 to 40 in length! ")
            errCount++;
        }

        if (inputs.minimumInvestment === null || inputs.minimumInvestment < 1) {
            setErrMinimumInvestment("* minimum quantity 1 ")
            errCount++;
        }
        if (inputs.availableFrom === null || inputs.availableFrom === "") {
            setErrAvailableFrom("* enter date! ")
            errCount++;
        }
        if (inputs.productType === "-" || inputs.productType === "") {
            setErrProductType("* select product Type! ")
            errCount++;
        }
        if (inputs.productCode != null && (inputs.productCode.length < 2 || inputs.productCode.length > 5)) {
            setErrProductCode("* product code, must be 2 to 5 in length! ")
            errCount++;
        }

        if (inputs.description != null && (inputs.description.length < 10 || inputs.description.length > 900)) {
            seterrDescription("* description must be 10 to 900 in length! ")
            errCount++;
        }
        // validation end
        if (errCount === 0) {
            if(stocks.status != null && stocks.status === 200)
                inputs.stocks = stocks.data;
            else
                inputs.stocks = stocks;

            try {
                const res = await axios.post(config.BL_API_URL+"/basket/v1/updateBasket", inputs)
                
                // console.log(res.data)
                if (res.status == 200 || res.data === "Success")
                    navigate("/dashboard")
                else
                    setErr("backend error")

            } catch (err) {
                if (err.response)
                    setErr(err.response.data);
                else
                    setErr("Server error...");
                console.log("error", err)
                //console.log(error.response.data)
            }
        }

    }

    // upload file using api
    const upload = async () => {
        try {
            const formData = new FormData();
            formData.append("file", file);
            const res = await axios.post(config.BL_API_URL+"/api/upload", formData);
            return res.data;
        } catch (err) {
            console.log(err);
        }
    };

    // handle click on Upload Image 
    const handleClick = async (e) => {
        e.preventDefault();
        let imgUrl = "";
        if (file && file !== null) {
            imgUrl = await upload();
            imgUrl.replace("@", "a");
            //console.log("img " + imgUrl)
            let flist = files
            if (flist === "")
                flist = imgUrl
            else
                flist += "@" + imgUrl
            setFiles(flist)
        }
        //console.log("files ", files)
        // mutation.mutate({ img: imgUrl });        
        setFile(null);
    };

    const handleImageDelete = (index, image) => {

        var filelist = files
        var newlist = []
        newlist = filelist.split("@")
        newlist.splice(index, 1)

        setFiles(newlist.join("@"))

        //console.log(image, index, newlist)
    }

    const toggleStocksModal = () => {
        setIngredientsModel(!ingredientsModel)
    }

    return (
        <div className="my-24 md:px-14 max-w-screen-2xl mx-auto ">

            <div className="max-w-4xl px-4 sm:px-6 lg:px-8 lg:py-2 mx-auto">

                <div className="bg-formbackground rounded-xl shadow p-4 sm:p-7 ">
                    <div className="mb-8">
                        <h2 className="text-xl font-bold text-gray-800 ">
                            Edit Basket
                        </h2>
                    </div>

                    <form>
                        <div className="grid sm:grid-cols-12 gap-2 sm:gap-6">

                            {/* Recipe Name ---- */}
                            <div className="sm:col-span-2">
                                <label htmlFor="af-account-full-name" className="inline-block text-sm text-gray-800 mt-2.5 ">
                                    <span className={`${errBasketName ? "text-red-500 " : ""}`}>Basket Name </span>
                                </label>
                            </div>
                            <div className="sm:col-span-4">
                                <div className="sm:flex">
                                    <input
                                        disabled={editOn}
                                        onChange={handleChange} value={inputs.basketName}
                                        type="text" name="basketName" id="basketName" placeholder=""
                                        className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " />
                                </div>
                            </div>
                            {/* Display Name ---- */}
                            <div className="sm:col-span-2">
                                <label htmlFor="af-account-full-name" className="inline-block text-sm text-gray-800 mt-2.5 ">
                                    <span className={`${errDisplayName ? "text-red-500 " : ""}`}>Display Name</span>
                                </label>
                            </div>
                            <div className="sm:col-span-4">
                                <div className="sm:flex">
                                    <input
                                        onChange={handleChange} value={inputs.displayName}
                                        type="text" name="displayName" id="displayName" placeholder=""
                                        className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " />
                                </div>
                            </div>


                            {/* Description ---- */}
                            <div className="sm:col-span-2">
                                <label htmlFor="af-account-full-name" className="inline-block text-sm text-gray-800 mt-2.5 ">
                                    <span className={`${errMinimumInvestment ? "text-red-500 " : ""}`}>Minimum Investment</span>
                                </label>
                            </div>
                            <div className="sm:col-span-4">
                                <div className="sm:flex">
                                    <input
                                        onChange={handleChange} value={inputs.minimumInvestment}
                                        type="number" name="minimumInvestment" id="minimumInvestment" placeholder=""
                                        className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " />
                                </div>
                            </div>
                            {/* Available from ---- */}
                            <div className="sm:col-span-2">
                                <label htmlFor="af-account-full-name" className="inline-block text-sm text-gray-800 mt-2.5 ">
                                    <span className={`${errAvailableFrom ? "text-red-500 " : ""}`}>Available from</span>
                                </label>
                            </div>
                            <div className="sm:col-span-4">
                                <div className="sm:flex">
                                    <input
                                        onChange={handleChange} value={inputs.availableFrom}
                                        type="date" name="availableFrom" id="availableFrom" placeholder=""
                                        className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " />
                                </div>
                            </div>


                            {/* Category ---- */}
                            <div className="sm:col-span-2">
                                <label htmlFor="productType" className="inline-block text-sm text-gray-800 mt-2.5 ">
                                    <span className={`${errProductType ? "text-red-500 " : ""}`}>Product Type</span>
                                </label>
                            </div>
                            <div className="sm:col-span-4 min-w-full">

                                <select
                                    onChange={handleChange}
                                    defaultValue={{ value: inputs.productTypeId}}
                                    name="productType" id="productTypeId"
                                    className="py-2 px-3 pe-9 block min-w-full w-full sm:w-auto border-gray-200 shadow-sm -mt-px -ms-px rounded-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none ">
                                    <option value="-">- Select -</option>
                                    {productTypes && (
                                        productTypes.map((productType, index) => <option key={index} value={productType.id} selected={productType.id === inputs.productTypeId ? true : false}>{productType.name}</option>)
                                    )}


                                </select>

                            </div>

                            {/* Who can cook ? ---- */}
                            <div className="sm:col-span-2">
                                <label htmlFor="af-account-full-name" className="inline-block text-sm text-gray-800 mt-2.5 ">
                                    <span className={`${errProductCode ? "text-red-500 " : ""}`}> Product Code</span>
                                </label>
                            </div>
                            <div className="sm:col-span-4">
                                <div className="sm:flex">
                                    <input
                                        onChange={handleChange} value={inputs.productCode}
                                        type="text" name="productCode" id="productCode" placeholder=""
                                        className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " />
                                </div>
                            </div>



                            {/* Description ---- */}
                            <div className="sm:col-span-2">
                                <label htmlFor="af-account-bio" className="inline-block text-sm text-gray-800 mt-2.5">
                                    <span className={`${errDescription ? "text-red-500 " : ""}`}>Description</span>
                                </label>
                            </div>
                            <div className="sm:col-span-10">
                                <textarea
                                    onChange={handleChange} value={inputs.description}
                                    name="description" id="description" placeholder="Enter Description ..." rows="4"
                                    className="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " ></textarea>
                            </div>

                            {/* Files ---- */}
                            <div className="sm:col-span-2">
                                <label className="inline-block text-sm text-gray-800 mt-2.5 ">
                                    Files
                                </label>
                            </div>
                            <div className="sm:col-span-8">


                                <div className="flex items-center gap-5">

                                    <div className="flex gap-x-2">
                                        <div>
                                            <button
                                                onClick={handleClick}
                                                type="button" className="mr-2 py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none ">
                                                <svg className="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" /><polyline points="17 8 12 3 7 8" /><line x1="12" x2="12" y1="3" y2="15" /></svg>
                                                Upload file
                                            </button>
                                            <input
                                                type="file"
                                                id="file"
                                                name="file"

                                                onChange={(e) => setFile(e.target.files[0])}
                                            />


                                        </div>

                                    </div>

                                </div>

                                <div className="flex flex-col my-2">
                                    {files &&
                                        files.split("@").map((image, index) => (
                                            <span key={index} className="text-blue-500 flex items-center gap-2 cursor-pointer">
                                                <FaRegFile />{image.slice(13)}

                                                {/*<img src={`http://localhost:3000/upload/${image}`} className="w-[50px] max-h-[50px] border absolute" />*/}
                                                <p onClick={e => handleImageDelete(index, image)} className=" ml-2 mt-2 font-bold text-red-500 cursor-pointer bg-white"><FaRegTrashAlt /></p>

                                            </span>
                                        ))
                                    }
                                </div>
                            </div>

{/*                             <div className="sm:col-span-2">
                                <span type="button" onClick={toggleStocksModal} className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-gray-600 text-white cursor-pointer hover:bg-gray-700 disabled:opacity-50 disabled:pointer-events-none ">
                                    BasketStocks
                                </span>
                            </div>
 */}
                        </div>


                        <div className="flex justify-end gap-x-2 mt-2">
                            <Link to="/dashboard" type="button" className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none ">
                                Cancel / Back
                            </Link>
                            <button type="button" onClick={handleUpdateBasket} className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none ">
                                Save changes
                            </button>
                        </div>
                        {alert && 
                            <p className="text-red-400 mt-8">
                                {errBasketName}
                                {errDisplayName}
                                {errMinimumInvestment}
                                {errAvailableFrom}
                                {errProductType}
                                {errProductCode}
                                {errDescription}
                                {err}
                            </p>
                        }
                    </form>
                </div>
            </div>

            {/* Ingredients Model */}
{/*             {ingredientsModel &&

                <AddStocks
                    toggleStocksModal={toggleStocksModal}
                    stocks={stocks}
                    setStocks={setStocks} 
                    currentBasketId={basketId} 
                    currentCompanyId={companyId}
                    />

            } */}
        </div>

    )
}

export default EditBasket;