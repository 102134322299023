import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import { useSelector } from "react-redux";
import config from '../../config';
import { useNavigate } from "react-router-dom";
import AutoComplete from "../Dashboard/AutoComplete";

const AddCorporateAction = () => {
    const navigate = useNavigate();
    const companyId = useSelector((state) => state.user.companyId);

    const [categories, setCategories] = useState([])
    const [err, setErr] = useState("")
    const [stocksList, setStocksList] = useState([]);
    const [message, setMessage] = useState(null);


    const [inputs, setInputs] = useState({
        stockId: -1,
        comment: "",
        corporateActionTypeId: -1,
        exDate: "",
        recordDate: "",
        companyId: -1,
        quantity: "",
        stockName: " "
    })

    useEffect(() => {
        const getStocksList = async (e) => {
            setMessage("")
            try {
                /**Stocks look up list */
                const res = await axios.get(config.BL_API_URL + "/stock/v1");
                if (res && res.data)
                    setStocksList(res.data)
                else
                    setMessage("backend message or while fetching stock lookup");

            } catch (message) {

                if (message.response)
                    setMessage(message.response.data);
                else
                    setMessage("Server error...");
            }
        }
        getStocksList();
        //setInputs(inputs => ({ ...inputs, companyId: companyId }));
        getCategories();
    }, [inputs])


    const getCategories = async (e) => {
        try {
            const res = await axios.get(config.BL_API_URL + "/corporateAction/v1/getAllCorporateActionTypes");

            if (res.data)
                setCategories(res.data)
            else
                setErr("backend error")

        } catch (err) {
            //console.log("first")
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }
    }




    const handleAddCorporateAction = async (e) => {
        e.preventDefault();
console.log("setting" + inputs);
        if(companyId !== undefined)
            inputs.companyId = companyId;

        try {
            const jsonString = JSON.stringify(inputs);
            console.log(inputs);
            const res = await axios.post(config.BL_API_URL + "/corporateAction/v1/addCorporateAction", inputs);

            if (res.status === 200) {
                navigate("/corporateActions");
                //window.location.href = "/cashregister";
            }
            else
                setErr(res.data.message || "backend error");

        } catch (err) {
            //console.log("first")
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }
    }

    const handleChange = (e) => {
        console.log("Handle change...");
        if (e.target.name === "corporateActionTypeId" || e.target.name === "stockId") {
            setInputs(prev => ({ ...prev, [e.target.id]: Number(e.target.value) }))
        } else {
            setInputs(prev => ({ ...prev, [e.target.id]: e.target.value }))
        }
    }

    return (
        <div>
            <div className="my-24 md:px-14 max-w-screen-2xl mx-auto ">
                <div className="max-w-4xl px-4 sm:px-6 lg:px-8 lg:py-2 mx-auto">
                    <div className="bg-formbackground rounded-xl shadow p-4 sm:p-7 ">
                        <div className="mb-8">
                            <h2 className="text-xl font-bold text-gray-800 ">
                                Add New Corporate Action
                            </h2>
                        </div>
                        <form>
                            <div className="grid sm:grid-cols-12 gap-2 sm:gap-6">

                                {/* Basket Name ---- */}
                                <div className="sm:col-span-2">
                                    <label htmlFor="basketName" className="inline-block text-sm text-gray-800 mt-2.5 ">
                                        <span >Scrip Name </span>
                                    </label>
                                </div>
                                <div className="sm:col-span-4">
                                    <div className="sm:flex">
                                        <AutoComplete
                                            setInputs={setInputs}
                                            data={stocksList}
                                            inputs={inputs}
                                            editOn={false}
                                        />
                                    </div>
                                </div>
                                {/* Category ---- */}
                                <div className="sm:col-span-2">
                                    <label htmlFor="corporateActionType" className="inline-block text-sm text-gray-800 mt-2.5 ">
                                        <span>Corporate Action Type</span>
                                    </label>
                                </div>
                                <div className="sm:col-span-4">
                                    <div className="sm:flex">
                                        <select
                                            onChange={handleChange}
                                            name="corporateActionType" id="corporateActionTypeId"
                                            className="py-2 px-3 pe-9 block min-w-full w-full sm:w-auto border-gray-200 shadow-sm -mt-px -ms-px rounded-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none ">
                                            <option value="-">- Select -</option>
                                            {categories && (
                                                categories.map((category, index) => <option key={index} value={category.id}>{category.name}</option>)
                                            )}
                                        </select>
                                    </div>
                                </div>


                                {/* Category ---- */}

                                <div className="sm:col-span-2">
                                    <label htmlFor="exDate" className="inline-block text-sm text-gray-800 mt-2.5 ">
                                        <span >Ex Date</span>
                                    </label>
                                </div>
                                <div className="sm:col-span-4">
                                    <div className="sm:flex">
                                        <input
                                            onChange={handleChange}
                                            type="date" name="exDate" id="exDate" placeholder=""
                                            className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " />
                                    </div>
                                </div>

                                {/* date from ---- */}
                                <div className="sm:col-span-2">
                                    <label htmlFor="addedOn" className="inline-block text-sm text-gray-800 mt-2.5 ">
                                        <span >Record Date</span>
                                    </label>
                                </div>
                                <div className="sm:col-span-4">
                                    <div className="sm:flex">
                                        <input
                                            onChange={handleChange}
                                            type="date" name="recordDate" id="recordDate" placeholder=""
                                            className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " />
                                    </div>
                                </div>




                                {/* Amount ---- */}
                                <div className="sm:col-span-2">
                                    <label htmlFor="amount" className="inline-block text-sm text-gray-800 mt-2.5 ">
                                        <span > Amount / Ratio </span>
                                    </label>
                                </div>
                                <div className="sm:col-span-4">
                                    <div className="sm:flex">
                                        <input
                                            onChange={handleChange}
                                            type="string" name="quantity" id="quantity" placeholder=""
                                            className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " />
                                    </div>
                                </div>

                                {/* comment ---- */}
                                <div className="sm:col-span-2">
                                    <label htmlFor="comment" className="inline-block text-sm text-gray-800 mt-2.5">
                                        <span >Comment</span>
                                    </label>
                                </div>
                                <div className="sm:col-span-4">
                                    <input
                                        onChange={handleChange}
                                        name="comment" id="comment"
                                        className="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " ></input>
                                </div>

                                {/* Files ---- */}
                                <div className="sm:col-span-2">
                                </div>
                            </div>


                            <div className="flex justify-end gap-x-2 mt-2">

                                <Link to="/corporateActions" type="button" className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none ">
                                    Cancel
                                </Link>
                                <button type="button" onClick={handleAddCorporateAction} className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none ">
                                    Save changes
                                </button>
                            </div>
                            <p className="text-red-400 mt-8">
                                {err}
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default AddCorporateAction;