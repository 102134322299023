import Banner from "../components/Banner";
import Features from "../components/Features";

const Home = () => {
    return (
        <>
            <div className="md:px-12 p-4 max-w-screen-2xl mx-auto mt-20">
                <Banner />
            </div>
            
        </>
    )
}

export default Home