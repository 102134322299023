import banner from "../assets/banner.svg";
import TWB from "../assets/TWB_2.svg";

const Banner = () => {
    return (

        <div className="flex flex-col md:flex justify-between items-center ">
            {/* banner Image */}
            <img src={TWB} alt="" className="xl:h-[500px] rounded-2xl rounded-br-[80px]" />
            {/* banner content */}
            <div >
            </div>

        </div>

    )
}

export default Banner