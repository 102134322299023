console.log('************process.env.REACT_APP_API_URL***************', process.env.REACT_APP_API_URL)
const config = {
  API_URL: process.env.REACT_APP_API_URL,
  BL_API_URL: process.env.REACT_APP_BL_API_URL,
  };
  
  export default config;

  /* API_URL: 'http://10.53.251.211:8801',
    BL_API_URL: 'http://10.53.251.211:8800', 
        API_URL: 'http://192.168.29.115:8801',
    BL_API_URL: 'http://192.168.29.115:8800',  
*/