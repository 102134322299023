import DelRecipe from "./DelRecipe";
import ShowStocks from "./ShowStocks";
import axios from "axios";
import featureImg from "../../assets/features1.svg";
import { useCallback, useEffect, useState } from "react";
import { FaRegTrashAlt } from "react-icons/fa";
import { FaRegFile } from "react-icons/fa";
import { FaThList } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { CgPerformance } from "react-icons/cg";
import BasketStockPerformance from "../Reports/BasketStocksPerformance";
import config from "../../config";

const Dashboard = () => {
    const email1 = useSelector((state) => state.user.email);
    const role = useSelector((state) => state.user.role);    
    const companyId = useSelector((state) => state.user.companyId);
    const [basket, setBasket] = useState([])
    const [err, setErr] = useState("")
    const [selectedId, setSelectedId] = useState(null)
    const [isDeleteOpen, setIsDeleteOpen] = useState(false)
    const [file, setFile] = useState(null);
    const [files, setFiles] = useState("")

    const [stocksModel, setStocksModel] = useState(false)
    const [stocks, setStocks] = useState([])
    const [selBasket, setSelBasket] = useState({})
    const [currentBasketId, setCurrentBasketId] = useState("");
    const [currentCompanyId, setCurrentCompanyId] = useState(1);
    const [basketName, setBasketName] = useState("");
    const [isRebalanceAllowed, setIsRebalanceAllowed] = useState(false);

    const [performanceReport, setPerformanceReport] = useState(false);
    const togglePerformanceReport = () => {
        setPerformanceReport(!performanceReport)
    }

    const toggleStocksModal = () => {
        setStocksModel(!stocksModel)
    }
    const showReport = async (basket, basketId) => {
        setCurrentBasketId(basketId);
        setPerformanceReport(!performanceReport)
    }

    const showStocks = async (basket, basketId) => {

        setCurrentBasketId(basketId);
        setCurrentCompanyId(basket.companyId);
        //console.log(currentBasketId)
        //console.log(currentCompanyId)
        const resStocks = await axios.get(config.BL_API_URL+"/basketstock/v1/getbasketstockbyid", { params: {basketId} });
        setSelBasket(basket)
        setBasketName(basket.displayName)
        //console.log(JSON.parse(ingrediantsList))
        //setStocks(JSON.parse(ingrediantsList))
        if(resStocks.data != null){
            setStocks(resStocks.data);
        }
        setStocksModel(!stocksModel)
    }

    const updateRecipe = () => {
        const result = selBasket
        result.stocks = stocks
        //result.stocks = JSON.stringify(stocks)
        //console.log(selBasket)
        setSelBasket(result)
        console.log('result ', result)
        handleUpdateRecipe(result)
    }
    function formatNumber(value) {
        return new Intl.NumberFormat('en-IN').format(value);
    }


    //------- update after change ingrediants
    const handleUpdateRecipe = async (result) => {

        try {
            const res = await axios.post(config.BL_API_URL+"/basket/v1/updateBasket", result)
            // console.log(res.data)
            // if (res.data == "Recipe has been updated.")
            //     navigate("/dashboard")
            // else
            //     setErr("backend error")

        } catch (err) {
            console.log("error 333 ", err)
            if (err.response.status == 500)
                setErr("Server error...");
            else
                setErr(err.response.data);
        }


    }

    // get basket list --
    useEffect(() => {
        getBaskets(companyId)
        if(role === 2 || role === 1){
            setIsRebalanceAllowed(true)
        }
        else{
            setIsRebalanceAllowed(false)
        }
    }, [role])

    // get basket list function
    const getBaskets = async (e) => {

        try {
           

            //const res = await axios.post(config.BL_API_URL+"/api/baskets/getBaskets", inputs)
            //const res = await axios.get(config.BL_API_URL+"/basket/v1");
            const res = await axios.get(config.BL_API_URL+"/basket/v1/getBasketByCompanyId/" + companyId );
            //console.log(res.data)

            if (res.data)
                setBasket(res.data) 
            else
                setErr("backend error")

        } catch (err) {
            //console.log("first")
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }
    }

    const toggleDeleteModal = () => {
        setIsDeleteOpen(!isDeleteOpen)
    }

    const enableDeleteModel = useCallback((selected) => {
        setSelectedId(selected);
        setIsDeleteOpen(true)
    }, [])

    // handle Delete Recipe
    const handleDelete = async () => {
        setIsDeleteOpen(!isDeleteOpen)
        console.log(selectedId)
        if (selectedId !== null) {
            try {
               

                const inputs = { basketId: selectedId };
                const res = await axios.post(config.BL_API_URL+"/api/baskets/deleterecipe", inputs)
                //console.log(res.data)
                getBaskets()
                // if (res !== "") {
                //     //setFormStep("enterpassword")

                // } else {
                //     setErr("backend error");
                // }
                // //setCurrentUser(res.data)
            } catch (err) {
                console.log("first")
                if (err.response)
                    setErr(err.response.data);
                else
                    setErr("Server error...");
            }
        }
        setSelectedId(null)
    };

    return (
        <div className="my-24 md:px-14 p-4 max-w-screen-2xl mx-auto ">
            <div className="flex flex-col">
                <div className="-m-1.5 overflow-x-auto">
                    <div className="p-1.5 min-w-full inline-block align-middle">
                        <div className="border rounded-lg divide-y divide-gray-200 ">
                        <div className="py-1 px-1 flex justify-center items-center">
            <span className="text-gray-500 text-2xl font-bold">Basket List</span>
            </div>
                            <div className="py-3 px-4 flex justify-end items-center">
                                {/* <div className="font-semibold">Recipe List</div>
                                {/* <div className="relative max-w-xs">
                                    <label className="sr-only">Search</label>
                                    <input type="text" name="hs-table-with-pagination-search" id="hs-table-with-pagination-search" className="py-2 px-3 ps-9 block w-full border-gray-200 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " placeholder="Search for items" />
                                    <div className="absolute inset-y-0 start-0 flex items-center pointer-events-none ps-3">
                                        <svg className="h-4 w-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="11" cy="11" r="8" /><path d="m21 21-4.3-4.3" /></svg>
                                    </div>
                                </div> */}
                                <Link to="/addbasket" type="button" className="py-2 px-4 inline-flex right-0 gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-btnSecondary text-white hover:bg-btnPrimary disabled:opacity-50 disabled:pointer-events-none " data-hs-overlay="#hs-basic-modal">
                                    Add New Basket
                                </Link>
                            </div>
                            {basket.length > 0 &&
                                <div className="overflow-hidden">
                                    <table className="min-w-full divide-y divide-gray-200  ">
                                        <thead className="bg-gray-50 ">
                                            <tr>
                                                {/*<th scope="col" className="py-3 px-4 pe-0">
                                                <div className="flex items-center h-5">
                                                    <input id="hs-table-pagination-checkbox-all" type="checkbox" className="border-gray-200 rounded text-blue-600 focus:ring-blue-500 " />
                                                    <label htmlFor="hs-table-pagination-checkbox-all" className="sr-only">Checkbox</label>
                                                </div>
                                        </th>*/}
                                                <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase">Basket Name</th>
                                                <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase">Display Name</th>
                                                <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase">Available From</th>
                                                <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase">Category</th>
                                                <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase">Min Investment</th>
                                                <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase">Files</th>
                                                <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase">Basket Owner</th>
                                                <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase">Product Code</th>
                                                <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">Actions</th>
                                            </tr>
                                        </thead>

                                        <tbody className="divide-y divide-gray-200 ">

                                            {basket &&

                                                basket.map((basket) => (

                                                    <tr key={basket.basketId} className="hover:bg-gray-100">
                                                        {/*<td className="py-3 ps-4">
                                                <div className="flex items-center h-5">
                                                    <input id="hs-table-pagination-checkbox-1" type="checkbox" className="border-gray-200 rounded text-blue-600 focus:ring-blue-500 " />
                                                    <label htmlFor="hs-table-pagination-checkbox-1" className="sr-only">Checkbox</label>
                                                </div>
                                        </td>*/}
                                                        {/*<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800  font-medium  group cursor-pointer relative inline-block border-gray-400 w-28 text-center">{basket.recipename}
                                                             <div class="opacity-0 w-max-[500px] bg-secondary text-white text-center text-xs rounded-lg py-2 absolute z-10 group-hover:opacity-100 bottom-full -left-1/2 ml-14 px-3 pointer-events-none">
                                                            {basket.description}
                                                        </div>
                                                        
                                                        </td>*/}
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm  text-gray-800 ">{basket.name}</td>

                                                        <td className="px-6 py-4 whitespace-nowrap text-sm  text-gray-800 ">{basket.displayName}</td>

                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">{basket.availableFrom}</td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">{basket.category}</td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">{formatNumber(basket.minimumInvestment)}</td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">
                                                            <div className="flex flex-wrap w-[120px] gap-1 items-center ">
                                                                {basket.files &&
                                                                    basket.files.split("@").map((image, index) => (
                                                                        <a download={image} href={`http://localhost:3000/upload/${image}`} key={index} className="border-2 hover:border-blue-400 hover:shadow-md">
                                                                            {/* <FaRegFile />{image.slice(13)} */}
                                                                            {image.match(/\.(jpg|jpeg|png|gif|tiff|bmp|jfif)$/) ?
                                                                                <img src={`http://localhost:3000/upload/${image}`} className="max-h-[32px] min-h-[32px]" />
                                                                                :
                                                                                <img src={`http://localhost:3000/upload/doc.jpg`} className="max-h-[32px] min-h-[32px]" />

                                                                            }

                                                                        </a>
                                                                    ))
                                                                }
                                                            </div>
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 "></td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">{basket.productCode}</td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-end text-sm font-medium">
                                                            <Link to={`/EditBasket/${basket.basketId}`} type="button" className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-400 hover:text-blue-600 disabled:opacity-50 disabled:pointer-events-none px-4 py-2  " title="Edit Basket"><FiEdit size={20} /></Link>
                                                            <Link ><span onClick={e => { 
                                                                if(isRebalanceAllowed){
                                                                showStocks(basket, basket.basketId)
                                                                }
                                                                else{
                                                                    alert("You've no access to rebalance screen. Please contact admin.")
                                                                }
                                                            
                                                            }} type="button" className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-300 hover:text-blue-500 disabled:opacity-50 disabled:pointer-events-none ps-4 py-2  " title="Rebalance Basket Stocks"><FaThList size={20} /></span></Link>
                                                            <Link><span onClick={e => showReport(basket, basket.basketId)} type="button" className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-300 hover:text-blue-500 disabled:opacity-50 disabled:pointer-events-none ps-4 py-2  " title="Show Basket Performance"><CgPerformance size={25}  /></span></Link>
                                                            {/* <button
                                                                onClick={() => {
                                                                    setSelectedId(basket.id)
                                                                    setIsDeleteOpen(!isDeleteOpen)
                                                                }
                                                                }
                                                                type="button" className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-red-400 hover:text-red-600 disabled:opacity-50 disabled:pointer-events-none  "><FaRegTrashAlt /></button> */}
                                                        </td>
                                                    </tr>

                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            }
                            {stocksModel &&

                                <ShowStocks
                                    toggleStocksModal={toggleStocksModal}
                                    // selBasket={selBasket}
                                    // setSelBasket={setSelBasket}
                                    updateRecipe={updateRecipe}
                                    stocks={stocks}
                                    setStocks={setStocks} 
                                    currentBasketId={currentBasketId}
                                    currentCompanyId={currentCompanyId}
                                    basketName={basketName}
                                    />
                            }

                            {performanceReport &&

                            <BasketStockPerformance
                                togglePerformanceReport={togglePerformanceReport}
                                basketId={currentBasketId}
                                />
                            }

                            {basket.length === 0 &&

                                <span className="m-4 min-w-full   ">You've no basket in dashboard.</span>
                            }
                            {/*<div className="py-1 px-4">
                                <nav className="flex items-center space-x-1">
                                    <button type="button" className="p-2.5 inline-flex items-center gap-x-2 text-sm rounded-full text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none  ">
                                        <span aria-hidden="true">«</span>
                                        <span className="sr-only">Previous</span>
                                    </button>
                                    <button type="button" className="min-w-[40px] flex justify-center items-center text-gray-800 hover:bg-gray-100 py-2.5 text-sm rounded-full disabled:opacity-50 disabled:pointer-events-none " aria-current="page">1</button>
                                    <button type="button" className="min-w-[40px] flex justify-center items-center text-gray-800 hover:bg-gray-100 py-2.5 text-sm rounded-full disabled:opacity-50 disabled:pointer-events-none ">2</button>
                                    <button type="button" className="min-w-[40px] flex justify-center items-center text-gray-800 hover:bg-gray-100 py-2.5 text-sm rounded-full disabled:opacity-50 disabled:pointer-events-none ">3</button>
                                    <button type="button" className="p-2.5 inline-flex items-center gap-x-2 text-sm rounded-full text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none  ">
                                        <span className="sr-only">Next</span>
                                        <span aria-hidden="true">»</span>
                                    </button>
                                </nav>
                            </div>
                                */}
                        </div>
                    </div>
                </div>
            </div>

            {/* Register Model */}
            {isDeleteOpen &&

                <DelRecipe handleDelete={handleDelete} toggleDeleteModal={toggleDeleteModal} />
            }
        </div>

    )
}

export default Dashboard