import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import config from './../config.js';

//import { useDispatch } from "react-redux";

//import { remove, update } from "../redux/userSlice";

const LostPassword = ({ toggleLoginModal, toggleRegisterModal, toggleLostPasswordModal }) => {
    const navigate = useNavigate();
    //const dispatch = useDispatch();
    const [inputs, setInputs] = useState({
        email: "",
        dateofbirth: "",
        password: "",
        confirmpassword: "",
    });

    const [err, setErr] = useState(null);

    const [erremail, seterremail] = useState("")
    const [errdateofbirth, seterrdateofbirth] = useState("")
    const [formstep, setFormStep] = useState("")
    const [errpassword, seterrpassword] = useState("")
    const [errconfirmpassword, seterrconfirmpassword] = useState("")


    const handleChange = (e) => {
        setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    // handle Email & Date form
    const handleEmailForm = async (e) => {
        e.preventDefault();

        seterremail("")
        seterrdateofbirth("")
        var errCount = 0;

        if (validateEmail(inputs.email) && inputs.email.length > 0) {
            seterremail("")
        } else {
            seterremail("enter email ")
            errCount++;
        }
        if (inputs.dateofbirth == null || inputs.dateofbirth == "") {
            seterrdateofbirth("enter date ")
            errCount++;
        }

        if (errCount === 0) {
            try {
                const passdata = CryptoJS.AES.encrypt(
                    JSON.stringify(inputs.password),
                    "XkhZG4fW2t2W"
                ).toString();
                inputs.password = passdata;
                const res = await axios.post(config.API_URL+"/api/auth/lostpassword", inputs)
                
                if (res !== "") {
                    setFormStep("enterpassword")

                } else {
                    setErr("backend error");
                }
                //setCurrentUser(res.data)
            } catch (err) {
                console.log("first")
                if (err.response)
                    setErr(err.response.data);
                else
                    setErr("Server error...");
            }

        }
    };

    // handle Update Password form
    const handleUpdatePassword = async (e) => {
        e.preventDefault()
        // validatation 

        seterrpassword("")
        seterrconfirmpassword("")
        seterrdateofbirth("")

        var errCount = 0;


        if (inputs.password.length < 8 || inputs.password.length > 40) {
            seterrpassword("must be 8 to 40 in length!")
            errCount++;
        }
        if (inputs.password !== inputs.confirmpassword) {
            seterrconfirmpassword("password not match!")
            errCount++;
        }
        console.log(inputs)
        if (errCount === 0) {
            try {

                await axios.post(config.API_URL+"/api/auth/updatepassword", inputs)
                console.log("password has been updated.")
                //toggleLoginModal()
                setFormStep("finish")
            } catch (err) {
                if (err.response)
                    setErr(err.response.data);
                else
                    setErr("Server error...");
                console.log("error")
                //console.log(error.response.data)
            }
        }
    }


    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };
    return (
        <div id="authentication-modal" tabIndex="-1" className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full shadow-2xl ">
            <div className="relative p-4 h-[90%] w-full  flex justify-center items-start  ">

                <div className="border w-[350px] relative mt-16 bg-white rounded-lg shadow-2xl ">

                    <div className=" flex items-center justify-between p-4 md:p-5 border-b rounded-t ">
                        <h3 className="text-xl font-semibold text-gray-900 ">
                            Lost password ?
                        </h3>
                        <button onClick={toggleLostPasswordModal} type="button" className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center " data-modal-hide="authentication-modal">
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    <div className="p-4 md:p-5">
                        {/* form for Email & Date  */}
                        {!formstep &&
                            <form className="space-y-4" action="#">
                                <div>
                                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 ">Your email <span className="text-red-300">{erremail}</span></label>
                                    <input
                                        onChange={handleChange}
                                        type="email" name="email" id="email"
                                        className={`bg-gray-50 border  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 
                                                ${erremail ? 'border-red-300' : 'border-gray-300'}  
                                                `}
                                        placeholder="name@company.com" required />
                                </div>
                                <div>
                                    <label htmlFor="dateofbirth" className="block mb-2 text-sm font-medium text-gray-900 ">Date of birth <span className="text-red-300">{errdateofbirth}</span></label>
                                    <input
                                        onChange={handleChange}
                                        type="date" name="dateofbirth" id="dateofbirth"
                                        className={`bg-gray-50 border  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 
                                    ${errdateofbirth ? 'border-red-300' : 'border-gray-300'}  
                                    `}
                                        placeholder="" required />
                                </div>

                                <button onClick={handleEmailForm} className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ">Check account</button>
                                <p className="text-red-400 mt-2">{err && err}</p>
                                <div className="text-sm font-medium text-gray-500 ">
                                    Not registered? <span onClick={toggleRegisterModal} className="text-blue-700 hover:underline  cursor-pointer">Create account</span>
                                </div>
                            </form>
                        }


                        {/* form for Password */}

                        {formstep == "enterpassword" &&
                            <form className="space-y-4" action="#">
                                <div>
                                    <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 ">New password <span className="text-red-400">{errpassword}</span></label>
                                    <input
                                        onChange={handleChange}
                                        type="password" name="password" id="password" placeholder="••••••••"
                                        className={`bg-gray-50 border  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 
                            ${errpassword ? 'border-red-300' : 'border-gray-300'}  
                            `}
                                        required />
                                </div>

                                <div>
                                    <label htmlFor="confirmpassword" className="block mb-2 text-sm font-medium text-gray-900 ">Confirm password <span className="text-red-400">{errconfirmpassword}</span></label>
                                    <input
                                        onChange={handleChange}
                                        type="password" name="confirmpassword" id="confirmpassword" placeholder="••••••••"
                                        className={`bg-gray-50 border  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 
                            ${errconfirmpassword ? 'border-red-300' : 'border-gray-300'}  
                            `}
                                        required />
                                </div>

                                <button onClick={handleUpdatePassword} className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ">Update password</button>
                                <p className="text-red-400 mt-2">{err && err}</p>
                                <div className="text-sm font-medium text-gray-500 ">
                                    Not registered? <span onClick={toggleRegisterModal} className="text-blue-700 hover:underline  cursor-pointer">Create account</span>
                                </div>
                            </form>
                        }
                        {formstep == "finish" &&
                            <form className="space-y-4" action="#">

                                <div>
                                    <label htmlFor="confirmpassword" className="block mb-2 text-sm font-medium text-gray-900 ">Password has been updated. <span className="text-red-400">{errconfirmpassword}</span></label>
                                </div>

                                <button onClick={toggleLoginModal} className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ">Login now</button>

                            </form>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LostPassword