
export function calculateAllocation(lists, inputs, setTotalAllocation){
        
    let alloc = 0;
    if(inputs != null)
    {
        if((inputs.newAllocation != null && inputs.newAllocation != "" && inputs.newAllocation >= 0) ){//&&  inputs.newAllocation.trim() != ""
            alloc = Number(inputs.newAllocation);            
        }
        else{
            alloc = (inputs.allocationPercent == null || inputs.allocationPercent == 0) ? 0 : Number(inputs.allocationPercent);            
        }
        
    }

    lists.forEach(element => {
        if((inputs != null && inputs.stockId !== element.stockId)){//to avoid double counting
           
            alloc += (element.newAllocation == null || element.newAllocation === 0 ) ? Number(element.allocationPercent) : Number(element.newAllocation);
            //alloc += (element.newAllocation == null || element.newAllocation.trim() === "") ? Number(element.allocationPercent) : Number(element.newAllocation);
        }
    });
    return alloc;
    //setTotalAllocation(alloc);
}