import CryptoJS from "crypto-js";
import axios from "axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { remove, update } from "../redux/userSlice";
import config from "../config";

const Login = ({ toggleLoginModal, toggleRegisterModal, toggleLostPasswordModal }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [inputs, setInputs] = useState({
        email: "",
        password: ""
    });

    const [err, setErr] = useState(null);

    const [erremail, seterremail] = useState("")
    const [errpassword, seterrpassword] = useState("")


    const handleChange = (e) => {
        setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };


    const handleLogin = async (e) => {
        e.preventDefault();

        seterremail("")
        seterrpassword("")
        var errCount = 0;

        if (validateEmail(inputs.email) && inputs.email.length > 0) {
            seterremail("")
        } else {
            seterremail(" enter email ")
            errCount++;
        }
        if (inputs.password.length == 0) {
            seterrpassword(" enter password")
            errCount++;
        }

        if (errCount === 0) {
            try {
                //await login(inputs);
                //("/")
                const passdata = CryptoJS.AES.encrypt(
                    JSON.stringify(inputs.password),
                    "XkhZG4fW2t2W"
                ).toString();

                inputs.password = passdata;
                const res = await axios.post(config.API_URL+"/api/auth/login", inputs)
                if (res && res.data.email !== "") {
                    
                    localStorage.setItem("user", JSON.stringify(res.data));
                    toggleLoginModal();
                   
                    dispatch(update({ name: res.data.firstName, email: res.data.email, companyId: res.data.companyId, companyName: res.data.companyName, role: res.data.role_id}));
                    navigate("/")
                    console.log(res.data)
                }else{
                    setErr("backend error");

                }
                //setCurrentUser(res.data)
            } catch (err) {
                console.log("first")
                if (err.response)
                    setErr(err.response.data);
                else
                    setErr("Server error..." + err);
            }

        }
    };
    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };
    return (
        <div id="authentication-modal" tabIndex="-1" className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full shadow-2xl ">
            <div className="relative p-4 h-[90%] w-full  flex justify-center items-start  ">

                <div className="border w-[350px] relative mt-16 bg-white rounded-lg shadow-2xl ">

                    <div className=" flex items-center justify-between p-4 md:p-5 border-b rounded-t ">
                        <h3 className="text-xl font-semibold text-gray-900 ">
                            Sign in to platform
                        </h3>
                        <button onClick={toggleLoginModal} type="button" className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center " data-modal-hide="authentication-modal">
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    <div className="p-4 md:p-5">
                        <form className="space-y-4" action="#">
                            <div>
                                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 ">Your email <span className="text-red-300">{erremail}</span></label>
                                <input
                                    onChange={handleChange}
                                    type="email" name="email" id="email"
                                    className={`bg-gray-50 border  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 
                                            ${erremail ? 'border-red-300' : 'border-gray-300'}  
                                            `}
                                    placeholder="name@company.com" required />
                            </div>
                            <div>
                                <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 ">Your password <span className="text-red-400">{errpassword}</span></label>
                                <input
                                    onChange={handleChange}
                                    type="password" name="password" id="password" placeholder="••••••••"
                                    className={`bg-gray-50 border  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 
                                            ${errpassword ? 'border-red-300' : 'border-gray-300'}  
                                            `}
                                    required />
                            </div>
                            <div className="flex justify-between">
                                <div className="flex items-start">
                                    <div className="flex items-center h-5">
                                        <input id="remember" type="checkbox" value="" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 " />
                                    </div>
                                    <label htmlFor="remember" className="ms-2 text-sm font-medium text-gray-900 ">Remember me</label>
                                </div>
                                <span onClick={toggleLostPasswordModal} className="text-sm text-blue-700 hover:underline cursor-pointer">Lost Password?</span>
                            </div>
                            <button onClick={handleLogin} className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ">Login to your account</button>
                            <p className="text-red-400 mt-2">{err && err}</p>
                            <div className="text-sm font-medium text-gray-500 ">
                                Not registered? <span onClick={toggleRegisterModal} className="text-blue-700 hover:underline  cursor-pointer">Create account</span>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login