import axios from "axios";
import { useState } from "react";
import { FaRegTrashAlt } from "react-icons/fa";

const DelRecipe = ({ handleDelete, toggleDeleteModal }) => {
   
  
    return (
        <div id="authentication-modal" tabIndex="-1" className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full shadow-2xl ">
            <div className="relative p-4 h-[90%] w-full  flex justify-center items-start  ">

                <div className="border w-[350px] relative mt-16 bg-white rounded-lg shadow-2xl ">

                    <div className=" flex items-center justify-between p-4 md:p-5 border-b rounded-t ">
                        <h3 className="text-xl font-semibold text-gray-900 ">
                            Delete Recipe ?
                        </h3>
                        <button onClick={toggleDeleteModal} type="button" className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center " data-modal-hide="authentication-modal">
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    <div className="p-4 md:p-5">
                       
                        {/* Delete notice form  */}                        
                        
                            <form className="space-y-4" action="#">

                                <div>
                                    <label htmlFor="confirmpassword" className="block mb-2 text-sm font-medium text-gray-900 ">Do you want to delete recipe? <span className="text-red-400"></span></label>
                                </div>

                                <button onClick={handleDelete}  className="flex justify-center items-center w-full text-white bg-btnWarnSecondary hover:bg-btnWarnPrimary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "><FaRegTrashAlt />&nbsp;Confirm Delete!</button>

                            </form>
                       
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DelRecipe